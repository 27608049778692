import React, { useState } from 'react';
import { useContext } from 'react';
import { AssessmentContext } from './assessment.context';

export interface AnalystRequest {
  assessmentBaseId: string;
  id: string;
  company: {
    id: string;
    name: string;
  };
  instrument: {
    id: string;
    name: string;
  };
  pageIndex: number;
}

// Define the shape of the context value
export interface AssessmentContextProps {
  analystRequest: AnalystRequest | null;
  setAnalystRequest: (analystRequest: AnalystRequest | null) => void;
  candidate: any;
  setCandidate: (candidate: any) => void;
  pageIndex: number;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
}

// Custom hook to use the AssessmentContext
export const useAssessment = () => {
  return useContext(AssessmentContext);
};

export const AssessmentProvider: React.FC = ({ children }) => {
  const [pageIndex, setPageIndex] = useState(0);

  function handleNextPage() {
    setPageIndex(pageIndex + 1);
  }

  function handlePreviousPage() {
    setPageIndex(pageIndex - 1);
  }

  const [analystRequest, setAnalystRequest] = useState<AnalystRequest | null>(
    null,
  );
  const [candidate, setCandidate] = useState(null);

  return (
    <AssessmentContext.Provider
      value={{
        analystRequest,
        setAnalystRequest,
        candidate,
        setCandidate,
        pageIndex,
        handleNextPage,
        handlePreviousPage,
      }}
    >
      {children}
    </AssessmentContext.Provider>
  );
};
