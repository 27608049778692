import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import logo from 'lib/assets/images/logo-ms.png';

export const BaseAppBar = styled(AppBar)`
  h3 a {
    text-decoration: none;
    color: #fff;
  }

  .filesButton {
    padding: 6px 16px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .filesButton:hover {
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  .userTopInformations {
    display: flex;
    gap: 10px;
    width: 275px;

    p {
      color: #fff;
      font-size: 10pt;
    }
  }
`;

export const Logo = styled.div`
  width: 133px;
  height: 60px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 15px 55px;
`;
