import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import { Stimulus } from 'modules/assessment-execution/components/Stimulus';
import { StimulusContainer } from 'modules/assessment-execution/components/StimulusContainer';
import SquareIcon from '@mui/icons-material/Square';
import { Icon } from '@mui/material';
import Playback from 'modules/assessment-execution/components/Playback';
import DemoAudio from '../assets/audios/demo-audio.mp3';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from '../context/assessment.consumer';
import { useStimulus } from './useStimulus';

export const TutorialPage = () => {
  const { handleNextPage } = useAssessment();

  const {
    handleStimulusSelect,
    handleTestSubmit,
    selectedStimuli,
    singleStimulusSrc,
    stimulusItems,
    testResults,
    isSubmitted,
    resetTest,
  } = useStimulus({
    kind: 'tutorial',
    count: 21,
  });

  return (
    <DefaultPage
      style={{
        overscrollBehavior: 'contain',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h1" component="h1">
          {'Prova de atenção e concentração: Instruções'}
        </Typography>
        <Playback audioFileUrl={DemoAudio} />
        <Typography variant="body1" component="div">
          <p>
            Agora, você realizará uma prova que irá testar como está sua atenção
            e concentração.
          </p>
          <p>
            <strong>Nossa prova não é um teste psicológico</strong>, mas medirá
            de forma empírica, validada estatisticamente e assertiva, sua
            habilidade de se concentrar e focar sua atenção.
          </p>
          <p>
            Esta tela não é o teste ainda, apenas um exemplo para que você
            consiga entender o que deve ser feito.
          </p>
          <p>Preste muita atenção às instruções e exemplos.</p>
          <p>
            Veja que temos um quadrado abaixo que está pintado em três locais e,
            abaixo deste quadrado, temos algumas linhas com vários quadrados.
          </p>
          <p>
            Sua tarefa é bem simples:{' '}
            <strong>
              buscar nas linhas abaixo todas as figuras idênticas à figura acima
              e clicar sobre ela.
            </strong>
          </p>
          <p>Você deverá começar sempre da esquerda para a direita.</p>
          <p>
            Quando chegar ao final da linha, passe para a linha de baixo e
            comece novamente da esquerda para a direita.
          </p>
          <p>
            Se você clicar em alguma figura e perceber que errou, basta clicar
            novamente sobre a mesma e ela não será contada como erro.
          </p>
          <p>Complete o exemplo abaixo e clique no botão verificar:</p>
        </Typography>
        <StimulusContainer backgroundColor="#F4F1F1" width="100%">
          <Stimulus src={singleStimulusSrc} alt="Estímulo Alvo" />
        </StimulusContainer>
        <StimulusContainer backgroundColor="#F4F1F1" width="90vw">
          {stimulusItems.map((item) => {
            return (
              <Stimulus
                key={item.key}
                src={item.src}
                alt={`Estímulo ${item.key}`}
                onClick={() => handleStimulusSelect(item.key)}
                selected={selectedStimuli.includes(item.key)} // pass selected prop
                status={item.status}
                isSubmitted={isSubmitted}
              />
            );
          })}
        </StimulusContainer>
        {isSubmitted && (
          <Stack
            direction="row"
            spacing={{
              xs: 1,
              sm: 2,
            }}
            alignItems="center"
            justifyContent="center"
            width={'100%'}
            bgcolor="#CDF0F2"
            p={{
              xs: 1,
              sm: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={{
                xs: 0.4,
                sm: 2,
              }}
              alignItems="center"
              justifyContent="center"
              whiteSpace="nowrap"
            >
              <Icon>
                <SquareIcon color="success" height="14px" width="14px" />
              </Icon>
              <Typography variant="subtitle1" component="span">
                Acertos: {testResults.hits}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={{
                xs: 0.4,
                sm: 2,
              }}
              alignItems="center"
              justifyContent="center"
              whiteSpace="nowrap"
            >
              <Icon>
                <SquareIcon color="warning" height="14px" width="14px" />
              </Icon>
              <Typography variant="subtitle1" component="span">
                Omissões: {testResults.omissions}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={{
                xs: 0.4,
                sm: 2,
              }}
              alignItems="center"
              justifyContent="center"
              whiteSpace="nowrap"
            >
              <Icon>
                <SquareIcon color="error" />
              </Icon>
              <Typography variant="subtitle1" component="span">
                Erros: {testResults.errors}
              </Typography>
            </Stack>
          </Stack>
        )}
        <ButtonMain
          onClick={() => (isSubmitted ? resetTest() : handleTestSubmit())}
          text={isSubmitted ? 'Recomeçar' : 'Verificar'}
        />
        {isSubmitted && (
          <Stack
            spacing={2}
            bgcolor="#CDF0F2"
            p={{
              xs: 1,
              sm: 2,
            }}
          >
            <Typography>Veja acima como foi este seu exemplo.</Typography>
            <ul>
              <li>
                <Typography
                  style={{
                    color: 'green',
                    marginLeft: '10px',
                  }}
                >
                  Acertos: isso mesmo, eram estas que você deveria ter clicado.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: 'orange',
                    marginLeft: '10px',
                  }}
                >
                  Omissões: são os quadrados que se parecem com o exemplo e você
                  deveria ter clicado, mas não clicou.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    color: 'red',
                    marginLeft: '10px',
                  }}
                >
                  Erros: são os quadrados que não se parecem com o exemplo e
                  você clicou.
                </Typography>
              </li>
            </ul>
            <Typography>
              Caso queira refazer este exemplo, clique no botão acima RECOMEÇAR.
            </Typography>
            <Typography>
              Se você já entendeu a tarefa, clique no botão CONTINUAR e inicie
              sua prova.
            </Typography>
          </Stack>
        )}
        <ButtonMain
          disabled={!isSubmitted}
          onClick={handleNextPage}
          text={'Continuar'}
        ></ButtonMain>
      </Stack>
    </DefaultPage>
  );
};
