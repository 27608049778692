import axios, {AxiosInstance, AxiosRequestHeaders} from 'axios';
import type {InternalAxiosRequestConfig} from 'axios';
import {getAuth} from 'firebase/auth';

export const getBaseURL = () => {
    const url = window.location.href;
    if (url.includes('localhost:'))
        return 'http://localhost:3000/v1';
    else if (url.includes('https://ms-frontend-dev.areadoclientems.com.br'))
        return 'https://ms-backend-dev.areadoclientems.com.br/v1';
    else
        return 'https://ms-backend-prd.areadoclientems.com.br/v1';

    // if (process.env.REACT_APP_STAGE) {
    //     switch (process.env.REACT_APP_STAGE) {
    //         case 'dev':
    //             if (url.includes('localhost:'))
    //                 return 'http://localhost:3000/v1';
    //             return 'https://ms-backend-dev.areadoclientems.com.br/v1';
    //         case 'prod':
    //             return 'https://ms-backend-prd.areadoclientems.com.br/v1';
    //         default:
    //             return 'https://ms-backend-dev.areadoclientems.com.br/v1';
    //     }
    // }
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: getBaseURL()
});

axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        const token = await getAuth().currentUser?.getIdToken(true);
        const customHeaders: AxiosRequestHeaders = config.headers || {};
        customHeaders.Authorization = 'Bearer ' + token;
        customHeaders['Access-Control-Allow-Origin'] = '*';
        config.headers = customHeaders;
        return config;
    },
);

export default axiosInstance;
