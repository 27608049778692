import React from 'react';
import { Container, Stack } from '@mui/material';

export const TestPage: React.FC = ({ children }) => {
  const bodyStyle = {
    background:
      'linear-gradient(180deg, #F4F1F1 64.93%, rgba(244, 241, 241, 0.00) 100%)',
    minHeight: '100vh',
    overscrollBehavior: 'contain',
  };

  return (
    <div style={bodyStyle}>
      <Container>
        <Stack spacing={3} p={2}>
          {children}
        </Stack>
      </Container>
    </div>
  );
};
