import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { BaseTableContainer } from './style';
import {useUser} from "../../../auth/context/auth.consumer";
import {RoleType} from "../../../auth/common/role";

export interface ITableBody {
  company: string;
  candidateName: string;
  requestDate: string | undefined;
  status: JSX.Element;
  result?: JSX.Element;
  actions: JSX.Element;
  whatsapp?: JSX.Element;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTable(props: {
  tableHead: string[];
  tableBody: ITableBody[];
}) {
  const user = useUser();

  return (
    <BaseTableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.tableHead.map((item, index) => (
              <StyledTableCell key={index} align="center">
                {item === 'Mensagens' && user?.role === RoleType.ADMIN ? (item)
                    : (item === 'Mensagens' && (user?.role === RoleType.CONSULTOR || user?.role === RoleType.ANALISTA)) ? ('Resultado')
                    : (item)}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableBody.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{item.company}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.candidateName}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {item.requestDate}
                </StyledTableCell>
                <StyledTableCell align="center">{item.status}</StyledTableCell>
                {
                  item.whatsapp && user?.role === RoleType.ADMIN && (
                        <StyledTableCell align="center">
                          {item.whatsapp}
                        </StyledTableCell>
                    )
                }
                {item.result && (user?.role === RoleType.ANALISTA || user?.role === RoleType.CONSULTOR) && (
                  <StyledTableCell className="result" align="center">
                    {item.result}
                  </StyledTableCell>
                )}
                <StyledTableCell align="right">{item.actions}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </BaseTableContainer>
  );
}
