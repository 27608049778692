import { addHours, format } from 'date-fns';

export const cpfMask = (value: any) => {
  if (value === '' || value === undefined) return '';
  else {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
};

export const phoneMask = (value: any, type: any) => {
  if (value) {
    if (type === 'celular' || !type) {
      return value
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1'); // captura 4 numeros seguidos de um traço e não deixa ser digitado mais nada
    } else if (type === 'residencial') {
      return value
        .toString()
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1'); // captura 4 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
  }
};

export const cepMask = (value: any) => {
  if (value === '' || value === undefined) return '';
  else {
    try {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
    } catch {
      return '';
    }
  }
};

export function dateComingFromDb(date: any) {
  if (date) {
    const newDate = format(addHours(new Date(date), 3), 'dd-MM-yyyy');
    return newDate;
  }
}
