export interface IFiles {
  fileUrl: string;
  fileName: string;
  azureFileName: string;
}

interface IElements {
  id?: string;
  name: string;
}

export interface CreateFileDto {
  companyId: string;
  sectorsIds: string[];
  files: IFiles[];
  requestId?: string;
}

export interface FileDto {
  id?: string;
  fileName: string;
  fileUrl: string;
  azureFileName: string;
  user?: IElements;
  company: IElements;
  sectors: IElements[];
  requestId: string;
}

export interface UpdateFileDto {
  companyId?: string;
  sectorsIds?: string[];
  fileName?: string;
}

export enum ReportType {
  'ASSESSMENT_REPORT' = 'ASSESSMENT-REPORT',
  'CONSULTANT_REPORT' = 'CONSULTANT_REPORT',
}

export interface GetReportUrl {
  azureFileName: string;
  reportType: ReportType;
}
