import CardBox from 'lib/components/CardBox';
import { useAuth } from 'modules/auth/context/auth.consumer';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useState } from 'react';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { Box, Container, ErrorMsg } from './style';
import { Button } from '@mui/material';
import { ISnackBarSettings } from 'lib/helpers/snackbar-interface';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';

export const NewPasswordPage = () => {
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });

  const navigate = useNavigate();
  const { confirmPasswordRecovery } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const code = params.get('oobCode');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const errorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/invalid-action-code).':
        return 'Link para resetar a senha expirado.';
      default:
        return 'Erro desconhecido.';
    }
  };

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
      navigate('/login');
    }, 4000);
  };

  if (!code) {
    return <Navigate to="/esqueci-senha" replace />;
  }

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Campo obrigatório')
      .min(8, 'A senha deve conter ao menos 8 caracteres')
      .matches(/[a-z]/, 'A senha deve conter ao menos uma letra minúscula')
      .matches(/[A-Z]/, 'A senha deve conter ao menos uma letra maiúscula')
      .matches(/[0-9]/, 'A senha deve conter ao menos um número')
      .matches(/[\W|_]/, 'A senha deve conter ao menos um caractere especial'),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      password: '',
    },
    onSubmit: async () => {
      try {
        await confirmPasswordRecovery(code, formik.values.password);
        setSnackBarSettings({
          show: true,
          message: 'Senha atualizada com sucesso',
          severity: 'success',
        });
        resetSnackBar();
      } catch (e: any) {
        setSnackBarSettings({
          show: true,
          message: errorMessage(e.message),
          severity: 'error',
        });
        resetSnackBar();
      }
    },
  });
  return (
    <>
      <Container>
        <div className="leftSide">
          <h1>MS AVALIAÇÃO</h1>
        </div>
        <div className="rightSide">
          <CardBox width="400px">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <h3>Nova senha</h3>
                  <p>Insira sua nova senha no campo abaixo:</p>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Nova Senha
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    />
                    {formik.touched.password && (
                      <ErrorMsg>{formik.errors.password}</ErrorMsg>
                    )}
                  </FormControl>
                  {snackBarSettings.show && (
                    <CustomizedSnackbars
                      message={snackBarSettings.message}
                      severity={snackBarSettings.severity}
                    />
                  )}
                  <Button type="submit" variant="contained">
                    ENVIAR
                  </Button>
                </Box>
              </form>
            </FormikProvider>
          </CardBox>
        </div>
      </Container>
    </>
  );
};
