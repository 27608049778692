import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { AssessmentApi } from 'lib/api/assessment';
import React, { useState } from 'react';
import { CompanyApi } from 'lib/api/company';
import { ICompany } from 'modules/companies/components/CompanyForm/models';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Visibility, WhatsApp } from '@mui/icons-material';
import CustomizedTable from './assesstment-list-table';
import { useSnackbar } from 'notistack';
import { InstrumentApi } from 'lib/api/instrument';
import { InstrumentDTO } from 'lib/api/instrument.protocols';
import CardBox from 'lib/components/CardBox';
import {
  AssessmentListType,
  ITableBody,
  OpenViewDetailsPropTypes,
} from './types';
import { FileApi } from 'lib/api/file';
import { MessagingApi, messageBodyProps } from 'lib/api/messaging';
import {useUser} from "../../auth/context/auth.consumer";
import {RoleType} from "../../auth/common/role";
import {BaseExportExcelContainer} from "../../sectors/pages/list/style";
import Tooltip from "@mui/material/Tooltip";
import {cpfMask} from "../../../lib/helpers/masks";
dayjs.locale('pt-br');

export const AssessmentListPage = ({
  onViewTest,
}: {
  onViewTest: ({
    assessmentResponse,
    userInfo,
    company,
    instrument,
    region,
    costCenter,
    status,
  }: OpenViewDetailsPropTypes) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [instruments, setInstruments] = useState<InstrumentDTO[]>([]);
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const tableHead = ['Empresa', 'Candidato', 'Status', 'Cobrança', ''];

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [excelLoading, setExcelLoading] = useState(false);
  const userInfo = useUser();

  const candidateName = searchParams.get('candidateName') || '';
  const companyName = searchParams.get('companyName') || '';
  const requestDate = searchParams.get('requestDate') || '';
  const instrumentName = searchParams.get('instrumentName') || '';
  const candidateCpf = searchParams.get('candidateCpf') || '';
  const queryString = `assessment=true&candidateName=${encodeURIComponent(
    candidateName,
  )}&companyName=${
    encodeURIComponent(companyName) === 'todas'
      ? ''
      : encodeURIComponent(companyName)
  }&requestDate=${encodeURIComponent(
    requestDate,
  )}&instrumentName=${
      encodeURIComponent(instrumentName || '')
  }&candidateCpf=${encodeURIComponent(candidateCpf)}`;

  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const updateSearchParams = (param: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (param === 'candidateCpf') {
        newSearchParams.set(param, cpfMask(value));
    }
    else {
        newSearchParams.set(param, value);
    }
    setSearchParams(newSearchParams);
  };

  const updateOnlyCandidateCpfParam = () => {
     const newSearchParams = new URLSearchParams(searchParams);

      if (searchParamsObject['candidateCpf'] && searchParamsObject['candidateCpf'].length > 0){
          newSearchParams.set('candidateCpf', cpfMask(searchParamsObject['candidateCpf']));
          setSearchParams(newSearchParams);
      }
  }

  const fetchData = async (page: number) => {
    try {
      const [assesmentList, companiesList, instruments] = await Promise.all([
        AssessmentApi.getSearchedAssessments(queryString, page),
        CompanyApi.getCompanies(),
        InstrumentApi.getAssessmentInstruments(),
      ]);
      setPageCount(assesmentList.meta.pageCount);
      setCompanies(companiesList);
      setInstruments(instruments);
      mountTableBody(assesmentList.entities as AssessmentListType['entities']);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao buscar as provas', { variant: 'error' });
    }
  };

  const getExportRequestsLink = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportAssessments();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar as provas', { variant: 'error' });
    } finally {
      setExcelLoading(false);
    }
  };

  const handleWhatsappMsgSender = async (
    messageBodyProps: messageBodyProps,
  ) => {
    try {
      await MessagingApi.sendMessage(messageBodyProps);
      enqueueSnackbar('Mensagem enviada!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Erro ao enviar mensagem.', { variant: 'error' });
    }
  };

  const mountTableBody = (assessmentList: AssessmentListType['entities']) => {
    const body: ITableBody[] = [];
    assessmentList?.forEach((item) => {
      body.push({
        company: item.company.name,
        candidateName: item.candidate.name,
        status: item.status,
        whatsappButton: (
          <Button
            onClick={() =>
              handleWhatsappMsgSender({
                analystRequestId: item.id,
                candidateName: item.candidate.name,
                candidateWhatsapp: item.candidate.whatsapp,
                companyName: item.company.name,
              })
            }
            style={{
              display: 'flex',
              gap: '0.25rem',
            }}
            disabled={item.status === 'FINALIZADO' ? true : false}
          >
            <WhatsApp
              style={
                item.status === 'FINALIZADO'
                  ? {
                      color: '#66717F',
                    }
                  : {
                      color: '#1976D2',
                    }
              }
            />
            <Typography
              style={
                item.status === 'FINALIZADO'
                  ? {
                      color: '#66717F',
                      textTransform: 'none',
                    }
                  : {
                      color: '#1976D2',
                      textTransform: 'none',
                    }
              }
            >
              Reenviar Whatsapp
            </Typography>
          </Button>
        ),
        actions: (
          <div
            onClick={() =>
              onViewTest({
                assessmentResponse: item.assessmentResponse,
                userInfo: item.candidate,
                company: item.company,
                instrument: item.instrument,
                region: item.region,
                costCenter: item.costCenter,
                status: item.status,
                analyst: item.analyst,
                id: item.id,
                assessmentInfo: item.assessmentInfo,
                additionalNote: item.additionalNote,
              })
            }
          >
            <Visibility />
          </div>
        ),
      });
    });
    setTableBody(body);
  };

  React.useEffect(() => {
    updateOnlyCandidateCpfParam();
    fetchData(page);
  }, [queryString, page]);

  return (
    <Container>
      <CardBox
        style={{
          margin: '60px 0',
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb="60px"
        >
          <Typography variant="h3" color="#66717f">
            Provas MS
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '2rem',
          }}
        >
          <TextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              updateSearchParams('candidateName', event.target.value);
            }}
            label="Nome do candidato"
            variant="outlined"
            value={searchParamsObject.candidateName}
          />

          <FormControl fullWidth>
            <InputLabel id="company-select-label">Buscar Empresa</InputLabel>
            <Select
              labelId="companies-select-label"
              id="companies-simple-select"
              label="Companies"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams(
                  'companyName',
                  event.target.value === 'todas' ? '' : event.target.value,
                );
              }}
              value={searchParamsObject.companyName || 'todas'}
            >
              <MenuItem value="todas">Todas</MenuItem>
              {companies?.map((item) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD/MM/YYYY"
              label="Buscar Data"
              value={
                searchParamsObject?.requestDate?.length > 0
                  ? dayjs(requestDate)
                  : undefined
              }
              onChange={(newValue) => {
                let requestDate = '';
                if (!newValue || !dayjs(newValue).isValid()) {
                  requestDate = '';
                } else {
                  requestDate = dayjs(newValue).format('YYYY-MM-DD');
                }
                page !== 1 && setPage(1);
                updateSearchParams('requestDate', requestDate);
              }}
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel id="instrument-select-label">
              Buscar Instrumento
            </InputLabel>
            <Select
              labelId="instrument-select-label"
              id="instrument-simple-select"
              label="Instrument"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams(
                  'instrumentName',
                  event.target.value === 'todas' ? '' : event.target.value,
                );
              }}
              value={searchParamsObject.instrumentName || 'todas'}
            >
              <MenuItem value="todas">Todas</MenuItem>
              {instruments?.map((item) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
              onChange={(event) => {
                  page !== 1 && setPage(1);
                  updateSearchParams('candidateCpf', event.target.value);
              }}
              label="CPF do candidato"
              variant="outlined"
              value={cpfMask(searchParamsObject.candidateCpf)}
          />
        </Stack>
      </CardBox>

      <BaseExportExcelContainer>
          <div>
              <Tooltip title="Exportar em excel todos os registros das provas">
                  <Button
                      variant="contained"
                      startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                      }
                      onClick={() => getExportRequestsLink()}
                      disabled={excelLoading}
                  >
                      Exportar em excel
                  </Button>
              </Tooltip>
          </div>
      </BaseExportExcelContainer>

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};
