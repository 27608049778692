import { Routes, Route } from 'react-router-dom';
import { IndexPage } from 'modules/assessment-execution';

export const AssessmentExecutionRoutes = () => (
  <Routes>
    <Route path={'/:analystRequestId/*'} element={<IndexPage />} />
  </Routes>
);

export default AssessmentExecutionRoutes;
