import axiosInstance from './api';
import {FileDto} from './file.protocols';

type mailBodyProps = {
  candidateName: string;
  candidateEmail: string;
  analystRequestId: string;
  companyName: string;
};

export type messageBodyProps = {
  candidateName: string;
  candidateWhatsapp: string;
  analystRequestId: string;
  companyName: string;
};

export type whatsappMessageForced = {
  analystName: string;
  companyName: string;
  candidateName: string;
  candidateWhatsapp: string;
  candidateEmail: string;
  dateLinkSent: string | null;
  analystRequestId: string;
}

class MessagingApi {
  static async sendMessage(body: messageBodyProps): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .post(`/messaging/send-assessment-link`, body)
      .then((response) => response.data);

    return response;
  }

  static async sendWhatsAppMessageForced(body: whatsappMessageForced, typeMessage: string): Promise<string | undefined> {
    return await axiosInstance
        .post(`/messaging/send-whatsapp-message-forced?typeMessage=${typeMessage}`, body)
        .then((response) => response.data);
  }

  static async sendMail(body: mailBodyProps): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .post(`/mail/send-assessment-link`, body)
      .then((response) => response.data);
    return response;
  }
}

export { MessagingApi };
