import React, { useEffect, useRef, useState } from 'react';
import { UserApi } from 'lib/api/user';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomizedTable from 'modules/users/components/Table';
import EditButton from 'lib/components/EditButton';
import DeleteButton from 'lib/components/DeleteButton';
import Container from '@mui/material/Container';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { UserForm } from 'modules/users/components/UserForm';
import { SectorApi } from 'lib/api/sector';
import { CompanyApi } from 'lib/api/company';
import IosShareIcon from '@mui/icons-material/IosShare';

import Pagination from '@mui/material/Pagination';
import {
  IUser,
  ITableBody,
  ISnackBarSettings,
  IElements,
  ISearch,
} from './models';
import {
  BaseCardBox,
  Header,
  BaseTextField,
  BaseDialog,
  FieldRow,
} from './style';
import { ICompany } from 'modules/companies/pages/list/models';
import { IUserReceived } from 'modules/users/components/UserForm/models';
import axiosInstance from 'lib/api/api';
import { FileApi } from 'lib/api/file';
import { useSnackbar } from 'notistack';
import {RoleType} from "../../../auth/common/role";
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import Tooltip from "@mui/material/Tooltip";
import {useUser} from "../../../auth/context/auth.consumer";

const UsersPage = () => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [users, setUsers] = useState<IUser[]>();
  const [user, setUser] = useState<IUserReceived>();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [sectors, setSectors] = useState<IElements[]>();
  const [roles, setRoles] = useState<string[]>([]);
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [open, setOpen] = useState(false);
  const [currentId, setcurrentId] = useState<string>('');
  const [isEdit, setIsEdit] = useState(false);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [search, setSearch] = useState<ISearch>({
    name: '',
    role: '',
    status: '',
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [excelLoading, setExcelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useUser();

  const tableHead = ['Status', 'Nome', 'Categoria', 'E-mail', 'Ações'];

  const formRef = useRef<HTMLDivElement | null>(null);

  setTimeout(() => {
    if (formRef.current !== null) {
      formRef.current.children[2].children[0].scrollTo(0, 0);
    }
  }, 500);

  const getUsersPaginated = async () => {
    setTableBody([]);

    let url = `page=${page}&`;

    if (search.name) url = url + `name=${search.name}&`;

    if (search.role !== 'todos' && search.role !== '')
      url = url + `role=${search.role}&`;

    if (search.status !== 'todos' && search.status !== '')
      url = url + `status=${search.status}`;

    const { entities, meta } = await UserApi.searchUsers(url);

    setUsers(entities);
    setPageCount(meta.pageCount);
  };

  const getUser = async (id: string | undefined) => {
    if (id) {
      const response = await UserApi.getUser(id);
      setUser(response);
    }
  };

  const getCompanies = async () => {
    setTableBody([]);
    const response = await CompanyApi.getCompanies();
    setCompanies(response);
  };

  const getSectors = async () => {
    const response = await SectorApi.getSectors();
    setSectors(response);
  };

  const getRoles = async () => {
    const response = await UserApi.getRoles();
    setRoles(response);
  };

  useEffect(() => {
    getCompanies();
    getSectors();
    getRoles();
  }, []);

  useEffect(() => {
    getUsersPaginated();
  }, [page, search]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleDelete = async (id: string) => {
    try {
      await UserApi.deleteUser(id);
      setSnackBarSettings({
        show: true,
        message: 'Usuário excluido com sucesso!',
        severity: 'success',
      });
      getUsersPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.response.data.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const snackBar = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info' | undefined,
  ) => {
    setSnackBarSettings({
      show: true,
      message: message,
      severity: severity,
    });
    resetSnackBar();
  };

  function editCallback(isOpen: boolean) {
    setOpen(isOpen);
    setIsEdit(true);
  }

  const handleImage = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      const formData = new FormData();

      formData.append('file', e.target.files[0]);

      try {
        axiosInstance
          .post('/file-upload/upload/image', formData)
          .then((response) => setImageUrl(response.data.link));
      } catch (err: any) {
        setSnackBarSettings({
          show: true,
          message: err.message,
          severity: 'error',
        });
        resetSnackBar();
      }
    }
  };

  const changeStatus = async (status: string, id: string) => {
    if (status === 'ATIVO') {
      await UserApi.editUserStatus(id, { status: 'INATIVO' });
      setSnackBarSettings({
        show: true,
        message: 'Usuário inativado',
        severity: 'error',
      });
      getUsersPaginated();
      resetSnackBar();
    } else {
      await UserApi.editUserStatus(id, { status: 'ATIVO' });
      setSnackBarSettings({
        show: true,
        message: 'Usuário ativado',
        severity: 'success',
      });
      getUsersPaginated();
      resetSnackBar();
    }
  };

  const handleStatus = (status: string, id: string) => {
    if (status === 'ATIVO') {
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                onChange={() => changeStatus(status, id)}
              />
            }
            label="ativo"
          />
        </FormGroup>
      );
    } else {
      return (
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={() => changeStatus(status, id)} />}
            label="inativo"
          />
        </FormGroup>
      );
    }
  };

  const mountTableBody = () => {
    users?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          status: handleStatus(item.status, item.id!),
          name: item.name,
          role: item.role,
          email: item.email,
          actions: (
            <div style={{ width: '68px' }}>
              <EditButton
                item={item}
                currentItem={setcurrentId}
                getUser={getUser}
                callback={editCallback}
              />
              <DeleteButton id={item.id} callback={handleDelete} />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [users]);

  const handleCloseModalForm = () => {
    setOpen(false);
  };

  const getExportUsersLink = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportUsers();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar os usuários', { variant: 'error' });
    } finally {
      setExcelLoading(false);
    }
  };

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Cadastro de Usuários</h3>
          <div>
            <Button
              onClick={() => {
                setIsEdit(false);
                setOpen(true);
              }}
              variant="contained"
              startIcon={<AddBoxIcon />}
            >
              Novo Cadastro
            </Button>
          </div>
        </Header>

        <FieldRow>
          <BaseTextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              setSearch((prev: any) => ({ ...prev, name: event.target.value }));
            }}
            label="Nome"
            variant="outlined"
            value={search.name}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Categoria"
              onChange={(event) => {
                page !== 1 && setPage(1);
                setSearch((prev: any) => ({
                  ...prev,
                  role: event.target.value,
                }));
              }}
              defaultValue={''}
            >
              <MenuItem value="todos">Todos</MenuItem>
              {roles?.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={(event) => {
                page !== 1 && setPage(1);
                setSearch((prev: any) => ({
                  ...prev,
                  status: event.target.value,
                }));
              }}
              defaultValue={''}
            >
              <MenuItem value="todos">Todos</MenuItem>
              <MenuItem value="ativo">Ativo</MenuItem>
              <MenuItem value="inativo">Inativo</MenuItem>
            </Select>
          </FormControl>
        </FieldRow>
      </BaseCardBox>

      {
          userInfo && userInfo.role === RoleType.ADMIN && (
              <BaseExportExcelContainer>
                <div>
                  <Tooltip title="Exportar em excel todos os registros de usuários">
                    <Button
                        variant="contained"
                        startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                        }
                        onClick={() => getExportUsersLink()}
                        disabled={excelLoading}
                    >
                      Exportar em excel
                    </Button>
                  </Tooltip>
                </div>
              </BaseExportExcelContainer>
          )
      }

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />
      {isEdit && user !== undefined && (
        <BaseDialog
          ref={formRef}
          open={open}
          onClose={handleCloseModalForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <UserForm
            isEdit={isEdit}
            setOpen={setOpen}
            handleImage={handleImage}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            users={users}
            user={user}
            companies={companies}
            sectors={sectors}
            currentId={currentId}
            roles={roles}
            snackBar={snackBar}
            getUsersPaginated={getUsersPaginated}
            handleCloseModalForm={handleCloseModalForm}
          />
        </BaseDialog>
      )}
      {!isEdit && (
        <BaseDialog
          ref={formRef}
          open={open}
          onClose={handleCloseModalForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <UserForm
            isEdit={isEdit}
            setOpen={setOpen}
            handleImage={handleImage}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            users={users}
            user={user}
            companies={companies}
            sectors={sectors}
            currentId={currentId}
            roles={roles}
            snackBar={snackBar}
            getUsersPaginated={getUsersPaginated}
            handleCloseModalForm={handleCloseModalForm}
          />
        </BaseDialog>
      )}
      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { UsersPage };
