import styled from 'styled-components';
import CardBox from 'lib/components/CardBox';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import GetAppIcon from '@mui/icons-material/GetApp';

export const BaseGetAppIcon = styled(GetAppIcon)`
  color: #1976d2;

  :hover {
    cursor: pointer;
    color: #1565c0;
  }
`;

export const BaseCardBox = styled(CardBox)`
  margin: 50px 0;

  h3 {
    color: #66717f;
    margin: 0;
    font-size: 25px;
  }

  .searchButtonBox {
    width: 100%;
    display: flex;
    justify-content: end;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 25px;

  div {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  input[type='file'] {
    display: none;
  }

  label {
    color: #fff;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 6px 16px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
  label:hover {
    cursor: pointer;
    background-color: #1976d2;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

export const BaseTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 25px !important;
`;

export const BaseDialog = styled(Dialog)`
  form {
    margin: 30px 25px;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin-top: 25px;
  }

  h2 {
    padding: 0;
    margin-bottom: 15px;
  }

  input {
    width: 400px;
  }
`;
