import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import AssessmentMock from '../mock/assessment.mock.json';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from '../context/assessment.consumer';

export const PreamblePage = () => {
  const { handleNextPage } = useAssessment();
  return (
    <DefaultPage>
      <Stack spacing={2}>
        <Typography variant="h1">
          {'Prova de Competência: Atenção e Concentração'}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: AssessmentMock.preamble,
          }}
        ></Typography>
        <ButtonMain
          onClick={() => handleNextPage()}
          text={'Continuar'}
        ></ButtonMain>
      </Stack>
    </DefaultPage>
  );
};
