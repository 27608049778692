import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import DownloadIcon from '@mui/icons-material/Download';
import { useAuth } from 'modules/auth/context/auth.consumer';
import CustomizedTable from './components/Table';
import { BaseCardBox, Header, BaseTextField, FieldRow } from './style';
import { FileApi } from 'lib/api/file';
import { FileDto, ReportType } from 'lib/api/file.protocols';
import { Stack, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';

interface IElements {
  id?: string;
  name: string;
}

interface ITableBody {
  fileName: string;
  sectors: IElements[];
  actions: JSX.Element;
}

const AnalystReports = () => {
  const { user } = useAuth();
  const [reports, setReports] = useState<FileDto[]>([]);
  const [search, setSearch] = useState('');
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const tableHead = ['Nome do arquivo', 'Setores', 'Ações'];
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const getRequestsPaginated = async () => {
    // eslint-disable-next-line prefer-const
    const response = await FileApi.searchByCompanyAndSectors(page, search);
    setReports(response.entities);
    setPageCount(response.meta.pageCount);
  };

  useEffect(() => {
    if (user) {
      getRequestsPaginated();
    }
  }, [user, search, page]);

  const getReportUrl = async (
    azureFileName: string,
    reportUser: IElements | undefined,
  ) => {
    try {
      const reportType = reportUser
        ? ReportType.CONSULTANT_REPORT
        : ReportType.ASSESSMENT_REPORT;

      const url = await FileApi.getReportUrl({ azureFileName, reportType });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Erro ao gerar url do arquivo', { variant: 'error' });
    } finally {
    }
  };

  const mountTableBody = () => {
    reports?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          fileName: item.fileName,
          sectors: item.sectors,
          actions: (
            <div
              className="actions"
              onClick={() => getReportUrl(item.azureFileName, item.user)}
            >
              <DownloadIcon />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [reports]);

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Relatórios</h3>
        </Header>

        <FieldRow>
          <BaseTextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              setSearch(event.target.value);
            }}
            label="Realizar busca"
            variant="outlined"
          />
        </FieldRow>
      </BaseCardBox>

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { AnalystReports };
