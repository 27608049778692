import Container from '@mui/material/Container';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SnackbarOrigin,
  Stack,
} from '@mui/material';

import CustomizedTable from 'modules/requests/components/Table';
import React, {useEffect, useState} from 'react';
import {RequestApi} from 'lib/api/requests';
import {useUser} from 'modules/auth/context/auth.consumer';
import IosShareIcon from '@mui/icons-material/IosShare';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {BaseCardBox, BaseTextField, Button, FakeButton, FieldRow, Header,} from './style';
import {FileApi} from 'lib/api/file';
import {useSnackbar} from 'notistack';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {DatePicker} from '@mui/x-date-pickers';
import {ITableBody, OpenViewDetailsPropTypes, RequestListType,} from '../../types';
import {Visibility} from '@mui/icons-material';
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import Tooltip from "@mui/material/Tooltip";
import {cpfMask} from "../../../../lib/helpers/masks";

interface State extends SnackbarOrigin {
  open: boolean;
}

const MyRequestsListPage = ({
  onViewDetails,
}: {
  onViewDetails: ({
    assessmentResponse,
    candidate,
    company,
    instrument,
    region,
    costCenter,
    status,
  }: OpenViewDetailsPropTypes) => void;
}) => {
  const user = useUser();

  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [excelLoading, setExcelLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
    const userInfo = useUser();

  const candidateName = searchParams.get('candidateName') || '';
  const requestDate = searchParams.get('requestDate') || '';
  const status = searchParams.get('status') || '';
    const candidateCpf = searchParams.get('candidateCpf') || '';
  const queryString = `&analystId=${
    user?.id
  }&candidateName=${encodeURIComponent(
    candidateName,
  )}&requestDate=${encodeURIComponent(requestDate)}&status=${encodeURIComponent(
    status || '',
  )}&candidateCpf=${
      encodeURIComponent(candidateCpf)
  }`;

  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const updateSearchParams = (param: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (param === 'candidateCpf') {
        newSearchParams.set(param, cpfMask(value));
    }
    else {
        newSearchParams.set(param, value);
    }
    setSearchParams(newSearchParams);
  };

  const updateOnlyCandidateCpfParam = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (searchParamsObject['candidateCpf'] && searchParamsObject['candidateCpf'].length > 0){
        newSearchParams.set('candidateCpf', cpfMask(searchParamsObject['candidateCpf']));
        setSearchParams(newSearchParams);
    }
  }

  const fetchData = async (page: number) => {
    try {
      const requestList = await RequestApi.searchRequests(queryString, page);

      setPageCount(requestList.meta.pageCount);
      mountTableBody(requestList.entities as RequestListType['entities']);
    } catch (error: any) {
      console.error(error);
      if (error.code === 'ERR_NETWORK') {
        return enqueueSnackbar('Servidor instável, aguarde alguns segundos e recarregue a página por favor', { variant: 'error' });
      }
      enqueueSnackbar('Erro ao buscar as provas', { variant: 'error' });
    }
  };

  const getExportRequestsLink = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportAnalystRequests(user?.id);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar as solicitações', { variant: 'error' });
    } finally {
      setExcelLoading(false);
    }
  };

  useEffect(() => {
    updateOnlyCandidateCpfParam();
    fetchData(page);
  }, [queryString, page]);

  const fakeButtonBbColor = (variant: string | undefined) => {
    switch (variant) {
      case 'em andamento':
        return '#f9f158';
      case 'cancelado':
        return '#ed4c40';
      case 'correção':
        return '#f1a262';
      case 'congelado':
        return '#cecece';
      case 'finalizado':
        return '#81c163';
      case 'reanálise':
        return '#6495ED';
    }
  };

  const tableHead = [
    'Empresa',
    'Nome do candidato',
    'Solicitação',
    'Status',
    'Resultado',
    '',
  ];

  const mountTableBody = (requestList: RequestListType['entities']) => {
    const body: ITableBody[] = [];
    requestList?.forEach((item) => {
      body.push({
        company: item.company.name,
        candidateName: item.candidate.name,
        requestDate: dayjs(item.requestDate).format('DD/MM/YYYY'),
        status: (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <FakeButton variant={fakeButtonBbColor(item.status.toLowerCase())}>
                {item.status.toLowerCase()}
              </FakeButton>
            </div>
        ),
        result: item.assessmentResponse?.classification ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <p>
                {item.assessmentResponse.classification
                  ? item.assessmentResponse.classification.toLowerCase()
                  : '...'}
              </p>
            </div>
        ) : (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <p>
                {item.requestManagement.result
                  ? item.requestManagement.result.toLowerCase()
                  : '...'}
              </p>
            </div>
        ),
        actions: (
          <div
            onClick={() =>
              onViewDetails({
                assessmentResponse: item.assessmentResponse,
                candidate: item.candidate,
                company: item.company,
                instrument: item.instrument,
                region: item.region,
                costCenter: item.costCenter,
                status: item.status,
                analyst: item.analyst,
                id: item.id,
                assessmentInfo: item.assessmentInfo,
                requestManagement: item.requestManagement,
                requestOrigin: item.requestOrigin,
                jobTitle: item.jobTitle,
                additionalNote: item.additionalNote,
                requestDate: item.requestDate,
                sector: item.sector,
              })
            }
          >
            <Visibility />
          </div>
        ),
      });
    });
    setTableBody(body);
  };

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Minhas solicitações</h3>
        </Header>

        <FieldRow>
          <BaseTextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              updateSearchParams('candidateName', event.target.value);
            }}
            label="Nome do candidato"
            variant="outlined"
            value={searchParamsObject.candidateName}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams(
                  'status',
                  event.target.value === 'todos' ? '' : event.target.value,
                );
              }}
              value={searchParamsObject.status || 'todos'}
            >
              <MenuItem value="todos" key={'todos'}>
                Todos
              </MenuItem>
              <MenuItem value="em andamento">Em andamento</MenuItem>
              <MenuItem value="finalizado">Finalizado</MenuItem>
              <MenuItem value="cancelado">Cancelado</MenuItem>
              <MenuItem value="congelado">Congelado</MenuItem>
              <MenuItem value="correção">Correção</MenuItem>
            </Select>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <BaseTextField
            type="text"
            onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams('candidateCpf', event.target.value);
            }}
            label="CPF do candidato"
            variant="outlined"
            value={cpfMask(searchParamsObject.candidateCpf)}
          />

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data da requisição"
                format="DD/MM/YYYY"
                value={
                  searchParamsObject.requestDate
                    ? dayjs(requestDate)
                    : undefined
                }
                onChange={(newValue: any) => {
                  let requestDate = '';
                  if (!newValue || !dayjs(newValue).isValid()) {
                    requestDate = '';
                  } else {
                    requestDate = dayjs(newValue).format('YYYY-MM-DD');
                  }
                  page !== 1 && setPage(1);
                  updateSearchParams('requestDate', requestDate);
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </FieldRow>
      </BaseCardBox>

      <BaseExportExcelContainer>
          <div>
              <Tooltip title="Exportar em excel todos os registros de solicitações">
                  <Button
                      variant="contained"
                      startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                      }
                      onClick={() => getExportRequestsLink()}
                      disabled={excelLoading}
                  >
                      Exportar em excel
                  </Button>
              </Tooltip>
          </div>
      </BaseExportExcelContainer>

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { MyRequestsListPage as RequestListPage };
