import { Button } from '@mui/material';
import { Container, Box } from './style';
import TextField from '@mui/material/TextField';
import CardBox from 'lib/components/CardBox';
import { useAuth } from 'modules/auth/context/auth.consumer';
import { useState } from 'react';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { ISnackBarSettings } from 'lib/helpers/snackbar-interface';

export const ForgotPasswordPage = () => {
  const { sendPasswordRecoveryEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });

  const errorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/wrong-password).':
      case 'Firebase: Error (auth/invalid-email).':
        return 'Senha ou email incorretos.';
      case 'Firebase: Error (auth/user-not-found).':
        return 'Usuário não encontrado.';
      default:
        return 'Erro desconhecido.';
    }
  };

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 4000);
  };

  return (
    <>
      <Container>
        <div className="leftSide">
          <h1>MS AVALIAÇÃO</h1>
        </div>
        <div className="rightSide">
          <CardBox width="400px">
            <form
              onSubmit={async (e: any) => {
                e.preventDefault();
                try {
                  await sendPasswordRecoveryEmail(email);
                  setSnackBarSettings({
                    show: true,
                    message:
                      'Um link para a redefinição de senha será enviado caso o e-mail inserido seja cadastrado.',
                    severity: 'success',
                  });
                  resetSnackBar();
                } catch (e: any) {
                  setSnackBarSettings({
                    show: true,
                    message: errorMessage(e.message),
                    severity: 'error',
                  });
                  resetSnackBar();
                }
              }}
            >
              <Box>
                <h3>Recuperar sua senha</h3>
                <p>Insira seu email abaixo para recuperar sua senha:</p>
                <TextField
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  label="E-mail"
                  variant="outlined"
                  type={'email'}
                />
                {snackBarSettings.show && (
                  <CustomizedSnackbars
                    message={snackBarSettings.message}
                    severity={snackBarSettings.severity}
                  />
                )}
                <Button type="submit" variant="contained">
                  ENVIAR
                </Button>
              </Box>
            </form>
          </CardBox>
        </div>
      </Container>
    </>
  );
};
