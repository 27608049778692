import { Card, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { AssessmentFeedback } from '../components/AssessmentFeedback';

export const FinishPage = () => {
  return (
    <DefaultPage>
      <Stack spacing={4}>
        <Typography variant="h1" component="h1">
          Prova finalizada
        </Typography>
        <Typography variant="body1">
          Você finalizou com sucesso sua prova de atenção e concentração.
        </Typography>
        <Typography variant="body1">
          Você já pode sair desta tela ou fechar este site.
        </Typography>
        <Divider sx={{ width: '100%', my: 4 }} />
        <Typography variant="h2" component="h2">
          Feedback
        </Typography>
        <Typography variant="body1">
          Gostaria de deixar uma avaliação ou comentário? Sua opinião é
          importante para nós.
        </Typography>
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            width: '100%',
            maxWidth: '512px', // Adjusted to match Figma spec
            padding: '24px', // Set exact padding
            backgroundColor: '#F4F1F1',
            border: '1px solid #238FEF', // Figma border color
            boxShadow: '2px 2px 0px 0px #238FEF', // Figma box shadow
            alignSelf: 'center',
          }}
        >
          <AssessmentFeedback />
        </Card>
      </Stack>
    </DefaultPage>
  );
};

export default FinishPage;
