import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from 'modules/assessment-execution/context/assessment.provider';
import StyledBox from '../components/StyledBox';
import {
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { AssessmentApi, EducationEnum, GenderEnum } from 'lib/api/assessment';
import { useSnackbar } from 'notistack';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  fullName: yup.string().required('Full name is required'),
  cpf: yup.string().required('CPF is required'),
  phone: yup.string().required('Phone is required'),
  age: yup
    .number()
    .min(0, 'Age cannot be negative')
    .max(110, 'Age cannot be more than 110')
    .required('Age is required'),
  gender: yup.string().required('Gender is required'),
  educationLevel: yup.string().required('Education is required'),
});

export const DetailedIdentificationPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { candidate, handleNextPage, analystRequest } = useAssessment();
  const formik = useFormik({
    initialValues: {
      email: candidate?.email || '',
      fullName: candidate?.name || '',
      cpf: candidate?.cpf || '',
      phone: candidate?.whatsapp || '',
      age: candidate?.age || '',
      gender: candidate?.gender || ('' as GenderEnum),
      educationLevel: candidate?.educationLevel || ('' as EducationEnum),
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: () => {
      return;
    },
  });

  const TypographyCustom = {
    padding: 0,
    fontWeight: '600',
  };

  const handleSubmit = async () => {
    if (!analystRequest) {
      enqueueSnackbar('Erro ao carregar a solicitação.', { variant: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      await AssessmentApi.patchCandidate(analystRequest.id, {
        age: Number(formik.values.age),
        educationLevel: formik.values.educationLevel,
        gender: formik.values.gender,
      });
      handleNextPage();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao submeter o formuário. Tente novamente.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultPage>
      <Stack spacing={2}>
        <Typography variant="h1">{'Dados Pessoais'}</Typography>
        <Typography variant="body1">
          Para prosseguirmos, precisamos que você preencha algumas informações:{' '}
        </Typography>
        <StyledBox>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <Typography variant="body1" sx={TypographyCustom}>
                Nome Completo
              </Typography>
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderBottom: '1px solid #797472',
                  background: '#DBD7D6',
                }}
                fullWidth
                id="fullname"
                name="fullName"
                disabled
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
              <Typography variant="body1" sx={TypographyCustom}>
                CPF
              </Typography>
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderBottom: '1px solid #797472',
                  background: '#DBD7D6',
                }}
                fullWidth
                id="cpf"
                name="cpf"
                disabled
                value={formik.values.cpf}
                onChange={formik.handleChange}
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                helperText={formik.touched.cpf && formik.errors.cpf}
              />
              <Typography variant="body1" sx={TypographyCustom}>
                Email
              </Typography>
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderBottom: '1px solid #797472',
                  background: '#DBD7D6',
                }}
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                disabled
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Typography variant="body1" sx={TypographyCustom}>
                Telefone
              </Typography>
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderBottom: '1px solid #797472',
                  background: '#DBD7D6',
                }}
                fullWidth
                id="phone"
                name="phone"
                disabled
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <Typography variant="body1" sx={TypographyCustom}>
                Idade
              </Typography>
              <TextField
                fullWidth
                id="age"
                name="age"
                type="number"
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
                inputProps={{ min: 0, max: 110 }}
              ></TextField>
              <Typography variant="body1" sx={TypographyCustom}>
                Sexo
              </Typography>
              <FormControl>
                <InputLabel id="gender-label">Selecione</InputLabel>
                <Select
                  fullWidth
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  label="Selecione"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                >
                  <MenuItem value={GenderEnum.MASCULINO}>Masculino</MenuItem>
                  <MenuItem value={GenderEnum.FEMININO}>Feminino</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body1" sx={TypographyCustom}>
                Escolaridade
              </Typography>
              <FormControl>
                <InputLabel id="educationLevel-label">Selecione</InputLabel>
                <Select
                  labelId="educationLevel-label"
                  fullWidth
                  id="educationLevel"
                  name="educationLevel"
                  label="Educação"
                  value={formik.values.educationLevel}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.educationLevel &&
                    Boolean(formik.errors.educationLevel)
                  }
                >
                  <MenuItem value={EducationEnum.ENSINO_FUNDAMENTAL_INCOMPLETO}>
                    Ensino Fundamental Incompleto
                  </MenuItem>
                  <MenuItem value={EducationEnum.ENSINO_FUNDAMENTAL_COMPLETO}>
                    Ensino Fundamental Completo
                  </MenuItem>
                  <MenuItem value={EducationEnum.ENSINO_MÉDIO_INCOMPLETO}>
                    Ensino Médio Incompleto
                  </MenuItem>
                  <MenuItem value={EducationEnum.ENSINO_MÉDIO_COMPLETO}>
                    Ensino Médio Completo
                  </MenuItem>
                  <MenuItem value={EducationEnum.SUPERIOR_INCOMPLETO}>
                    Ensino Superior Incompleto
                  </MenuItem>
                  <MenuItem value={EducationEnum.SUPERIOR_COMPLETO}>
                    Ensino Superior Completo
                  </MenuItem>
                  <MenuItem value={EducationEnum.PÓS_GRADUAÇÃO}>
                    Pós-Graduação
                  </MenuItem>
                </Select>
              </FormControl>
              <ButtonMain
                onClick={handleSubmit}
                text={'Continuar'}
                disabled={!formik.isValid || isLoading}
              ></ButtonMain>
            </Stack>
          </form>
        </StyledBox>
      </Stack>
    </DefaultPage>
  );
};
