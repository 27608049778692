import { styled as styledMui } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export interface ITableBody {
  company: string;
  candidateName: string;
  whatsappButton: JSX.Element;
  status: string;
  actions: JSX.Element;
}

const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCellEducation = styledMui(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

const fakeButtonBgColor = (variant: string) => {
  switch (variant) {
    case 'em andamento' || 'apto com restrição':
      return '#f9f158';
    case 'finalizado' || 'apto':
      return '#81c163';
  }
};

export const FakeButton = styled.div<{ variant: string | undefined }>`
  background-color: ${({ variant }) => variant};
  text-align: center;
  padding: 5px 0px;
  width: 110px;
  text-transform: lowercase;
  border-radius: 4px;
`;

export default function CustomizedTable(props: {
  tableHead: string[];
  tableBody: ITableBody[];
}) {
  return (
    <BaseTableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.tableHead.map((item, index) => (
              <StyledTableCell key={index} align="center">
                {item}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableBody.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">{item.company}</StyledTableCell>
                <StyledTableCell align="center">
                  {item.candidateName}
                </StyledTableCell>
                <StyledTableCellEducation align="center">
                  {item.status ? (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FakeButton
                            variant={fakeButtonBgColor(item.status.toLowerCase())}
                        >
                          {item.status}
                        </FakeButton>
                      </div>
                  ) : (
                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}> ...</div>
                  )}
                </StyledTableCellEducation>
                <StyledTableCell align="center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {item.whatsappButton}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">{item.actions}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </BaseTableContainer>
  );
}

export const BaseTableContainer = styled(TableContainer)`
  margin-bottom: 50px;

  table {
    border: 1px solid #dbdbdb;
  }

  table thead tr th {
    background-color: #d1e8ff !important;
    color: #555 !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 5px;
  }

  table thead tr th:first-child {
    padding-left: 16px;
  }

  table tbody td {
    padding: 10px 5px;
  }

  table tbody td:first-child {
    padding-left: 16px;
  }

  table tbody td:last-child {
    padding-right: 16px;
  }

  table tbody td svg {
    font-size: 14pt;
    color: #7d7d7d;
  }

  table tbody td svg:hover {
    cursor: pointer;
    color: #373636;
  }

  table tbody td img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  table tbody td p {
    font-size: 11pt;
    line-height: 0.2;
  }

  table tbody tr .result p {
    line-height: 1;
    align-items: center;
  }
`;
