import Container from '@mui/material/Container';
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Pagination, Select, Stack,} from '@mui/material';
import CustomizedTable from 'modules/requests/components/Table';
import React, {useEffect, useState} from 'react';
import {RequestApi} from 'lib/api/requests';
import {CompanyApi} from 'lib/api/company';
import IosShareIcon from '@mui/icons-material/IosShare';
import {BaseCardBox, BaseTextField, BaseTextFieldCPF, FakeButton, FieldRow, Header,} from './style';
import {FileApi} from 'lib/api/file';
import {useSearchParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {ICompany, IRequestResponseDTO, ITableBody,} from 'modules/requests/types';
import {Visibility} from '@mui/icons-material';
import dayjs from 'dayjs';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Tooltip from "@mui/material/Tooltip";
import {MessagingApi, whatsappMessageForced} from "../../../../lib/api/messaging";
import {useUser} from "../../../auth/context/auth.consumer";
import {RoleType} from "../../../auth/common/role";
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import {cpfMask} from "../../../../lib/helpers/masks";

const tableHead = [
  'Empresa',
  'Nome do candidato',
  'Solicitação',
  'Status',
  'Mensagens',
  '',
];

const ConsultantRequestListPage = ({
  onViewDetails,
}: {
  onViewDetails: (id: string) => void;
}) => {
  const user = useUser();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [excelLoading, setExcelLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useUser();

  const candidateName = searchParams.get('candidateName') || '';
  const consultantName = searchParams.get('consultantName') || '';
  const companyName = searchParams.get('companyName') || '';
  const status = searchParams.get('status') || '';
  const candidateCpf = searchParams.get('candidateCpf') || '';
  const queryString = `assessment=false&candidateName=${
    encodeURIComponent(candidateName,)
  }&companyName=${
    encodeURIComponent(companyName) === 'todas' ? '' : encodeURIComponent(companyName)
  }&consultantName=${
    encodeURIComponent(consultantName,)
  }&status=${
    encodeURIComponent(status || '')
  }&candidateCpf=${
    encodeURIComponent(candidateCpf)
  }`;

  const handleWhatsappMsgSender = async (
      messageBodyProps: whatsappMessageForced,
      typeMessage: string
  ) => {
    try {
      if (user?.role === RoleType.ANALISTA || user?.role === RoleType.CONSULTOR) {
        return enqueueSnackbar(`Perfil ANALISTA & CONSULTOR não tem permissão para enviar mensagem WhatsApp ao candidato!`, {
          variant: 'error',
          autoHideDuration: 10000
        });
      }

      enqueueSnackbar(`Enviando mensagem...`, {
        variant: 'info',
        autoHideDuration: 3000
      });

      MessagingApi.sendWhatsAppMessageForced(messageBodyProps, typeMessage)
          .then((response) => {
            switch (response) {
              case 'SENT':
                enqueueSnackbar(`Mensagem enviada ao WhatsApp ${messageBodyProps.candidateWhatsapp} do candidato!`, {
                  variant: 'success',
                  autoHideDuration: 10000
                });
                break;
              case 'FAILED':
                enqueueSnackbar(`Erro ao enviar WhatsApp ${messageBodyProps.candidateWhatsapp}, confira o número!`, {
                  variant: 'error',
                  autoHideDuration: 10000
                });
                break;
              case 'DELIVERED':
                enqueueSnackbar(`Mensagem entregue ao WhatsApp ${messageBodyProps.candidateWhatsapp} do candidato!`, {
                  variant: 'success',
                  autoHideDuration: 10000
                });
                break;
              case 'QUEUED':
                enqueueSnackbar(`Mensagem sendo processado para envio ao WhatsApp ${messageBodyProps.candidateWhatsapp} do candidato!`, {
                  variant: 'success',
                  autoHideDuration: 10000
                });
                break;
              case 'UNDELIVERED':
                enqueueSnackbar(`Erro ao entregar a mensagem WhatsApp ${messageBodyProps.candidateWhatsapp}, confira o número!`, {
                  variant: 'error',
                  autoHideDuration: 10000
                });
                break;

              default:
                enqueueSnackbar(`Erro genérico ao tentar envia mensagem WhatsApp ao candidato!`, {
                  variant: 'error',
                  autoHideDuration: 10000
                });
                break;
            }
          })
          .catch((error: any) => {
            console.log('error api: ', error);
            if (error.response.data.statusCode === 403) {
              return enqueueSnackbar(`Perfil ANALISTA não tem permissão para enviar mensagem WhatsApp ao candidato!`, {
                variant: 'error',
                autoHideDuration: 10000
              });
            }
            enqueueSnackbar(error.response.data.message, {
              variant: 'error',
              autoHideDuration: 10000
            });
          });
    } catch (err: any) {
      enqueueSnackbar(err.response.data.message, { variant: 'error' });
    }
  };

  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const updateSearchParams = (param: string, value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (param === 'candidateCpf') {
      newSearchParams.set(param, cpfMask(value));
    }
    else {
      newSearchParams.set(param, value);
    }
    setSearchParams(newSearchParams);
  };

  const updateOnlyCandidateCpfParam = () => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (searchParamsObject['candidateCpf'] && searchParamsObject['candidateCpf'].length > 0){
      newSearchParams.set('candidateCpf', cpfMask(searchParamsObject['candidateCpf']));
      setSearchParams(newSearchParams);
    }
  }

  const fetchData = async (page: number) => {
    try {
      const [requestList, companiesList] = await Promise.all([
        RequestApi.searchRequestsWithoutAssessment(queryString, page),
        CompanyApi.getCompanies(),
      ]);

      setPageCount(requestList.meta.pageCount);
      setCompanies(companiesList);
      mountTableBody(requestList.entities as IRequestResponseDTO['entities']);
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
        return enqueueSnackbar('Servidor instável, aguarde alguns segundos e recarregue a página por favor', { variant: 'error' });
      }
      console.error(error);
    }
  };

  const getExportRequestsLink = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportRequests(queryString);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar as solicitações', { variant: 'error' });
    } finally {
      setExcelLoading(false);
    }
  };

  useEffect(() => {
    updateOnlyCandidateCpfParam();
    fetchData(page);
  }, [queryString, page]);

  const fakeButtonBgColor = (variant: string) => {
    switch (variant) {
      case 'em andamento' || 'apto com restrição':
        return '#f9f158';
      case 'cancelado' || 'inapto':
        return '#ed4c40';
      case 'correção':
        return '#f1a262';
      case 'congelado' || 'excede':
        return '#cecece';
      case 'finalizado' || 'apto':
        return '#81c163';
      case 'reanálise':
        return '#6495ED';
    }
  };

  const mountTableBody = (requestList: IRequestResponseDTO['entities']) => {
    const body: ITableBody[] = [];
    requestList?.forEach((item) => {
      body.push({
        company: item.company.name,
        candidateName: item.candidate.name,
        requestDate: dayjs(item.requestDate).format('DD/MM/YYYY'),
        status: (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <FakeButton variant={fakeButtonBgColor(item && item.status && item.status.toLowerCase())}>
                {item.status}
              </FakeButton>
            </div>
        ),
        result: (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <FakeButton
                variant={fakeButtonBgColor(
                  item.requestManagement.result
                    ? item.requestManagement.result.toLowerCase()
                    : '',
                )}
              >
                <p>
                  {item.requestManagement.result
                    ? item.requestManagement.result.toLowerCase()
                    : '...'}
                </p>
              </FakeButton>
            </div>
        ),
        actions: (
          <div onClick={() => onViewDetails(item.id)}>
            <Visibility />
          </div>
        ),
        whatsapp: (
            <div style={{display: 'flex', justifyContent: 'center', flexFlow: 'row', gap: '10px'}}>
              <Tooltip title="Enviar aviso de solicitação">
                <WhatsAppIcon onClick={() => handleWhatsappMsgSender({
                  analystName: item.analyst.name,
                  companyName: item.company.name,
                  candidateName: item.candidate.name,
                  candidateEmail: item.candidate.email,
                  candidateWhatsapp: item.candidate.whatsapp,
                  analystRequestId: item.analyst.id,
                  dateLinkSent: null,
                }, 'aviso')} style={{width: '40px', height: '40px', background: 'rgb(57, 174, 65)', borderRadius: '20%', color: 'rgb(255,255,255)', border: '1px solid' }}/>
              </Tooltip>
              <Tooltip title="Enviar aviso de cobrança">
                <WhatsAppIcon onClick={() => handleWhatsappMsgSender({
                  analystName: item.analyst.name,
                  companyName: item.company.name,
                  candidateName: item.candidate.name,
                  candidateEmail: item.candidate.email,
                  candidateWhatsapp: item.candidate.whatsapp,
                  analystRequestId: item.analyst.id,
                  dateLinkSent: item.requestDate,
                }, 'cobrar')}
                              style={{width: '40px', height: '40px', background: 'rgb(255,203,0)', borderRadius: '20%', color: 'rgb(43 47 42)', border: '1px solid' }}/>
              </Tooltip>
            </div>
        )
      });
    });
    setTableBody(body);
  };

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Solicitações</h3>
        </Header>

        <FieldRow>
          <BaseTextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              updateSearchParams('candidateName', event.target.value);
            }}
            label="Nome do candidato"
            variant="outlined"
            value={searchParamsObject.candidateName}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Empresa</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Company"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams(
                  'companyName',
                  event.target.value === 'todas' ? '' : event.target.value,
                );
              }}
              value={searchParamsObject.companyName || 'todas'}
            >
              <MenuItem value="todas">Todas</MenuItem>
              {companies?.map((item) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <BaseTextField
            onChange={(event) => {
              page !== 1 && setPage(1);
              updateSearchParams('consultantName', event.target.value);
            }}
            label="Nome do consultor"
            variant="outlined"
            value={searchParamsObject.consultantName}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams(
                  'status',
                  event.target.value === 'todos' ? '' : event.target.value,
                );
              }}
              value={searchParamsObject.status || 'todos'}
            >
              <MenuItem value="todos">Todos</MenuItem>
              <MenuItem value="em andamento">Em andamento</MenuItem>
              <MenuItem value="finalizado">Finalizado</MenuItem>
              <MenuItem value="cancelado">Cancelado</MenuItem>
              <MenuItem value="congelado">Congelado</MenuItem>
              <MenuItem value="correção">Correção</MenuItem>
              <MenuItem value="reanálise">Reanálise</MenuItem>
            </Select>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <BaseTextFieldCPF
              type="text"
              onChange={(event) => {
                page !== 1 && setPage(1);
                updateSearchParams('candidateCpf', event.target.value);
              }}
              label="CPF do candidato"
              variant="outlined"
              value={cpfMask(searchParamsObject.candidateCpf)}
          />
        </FieldRow>
      </BaseCardBox>

      <BaseExportExcelContainer>
        <div>
          <Tooltip title="Exportar em excel todos os registros de solicitações">
            <Button
                variant="contained"
                startIcon={
                  excelLoading ? (
                      <CircularProgress color="inherit" size={20} />
                  ) : (
                      <IosShareIcon />
                  )
                }
                onClick={() => getExportRequestsLink()}
                disabled={excelLoading}
            >
              Exportar em excel
            </Button>
          </Tooltip>
        </div>
      </BaseExportExcelContainer>

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { ConsultantRequestListPage };
