import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';

export const BaseTableContainer = styled(TableContainer)`
  margin-bottom: 50px;

  table {
    border: 1px solid #dbdbdb;
  }

  table thead tr th {
    background-color: #d1e8ff !important;
    color: #555 !important;
    text-transform: uppercase;
    font-weight: 600;
  }

  table thead tr th:last-child {
    text-align: right;
  }

  table tbody td {
    padding: 10px 16px;
  }

  table tbody td .actions {
    display: flex;
    gap: 15px;
    justify-content: end;
  }

  table tbody td .actions svg:first-child {
    font-size: 16pt;
  }

  table tbody td .actions svg {
    font-size: 14pt;
    margin-right: 0 !important;
    color: #999;
  }

  table tbody td .actions svg:hover {
    color: #555;
  }

  table tbody td img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  table tbody td p {
    line-height: 0.2;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }
`;
