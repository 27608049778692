import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';

export const BaseEditIcon = styled(EditIcon)`
  color: #999;
  margin-right: 15px;

  :hover {
    cursor: pointer;
    color: #555;
  }
`;
