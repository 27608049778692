import axiosInstance from './api';
import { AddressDTO } from './global.protocols';

class GlobalApi {
  static async getAddress(cep: string): Promise<AddressDTO> {
    const response: AddressDTO = await axiosInstance
      .post(`/user-address`, { cep: cep })
      .then((response) => response.data);

    return response;
  }
}

export { GlobalApi };
