import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import AssessmentMock from '../mock/assessment.mock.json';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from '../context/assessment.consumer';
import { RequestApi } from 'lib/api/requests';
import { useSnackbar } from 'notistack';

export const LGPDPage = () => {
  const { handleNextPage, analystRequest } = useAssessment();
  const { enqueueSnackbar } = useSnackbar();

  const confirmLgpdAgreement = async () => {
    if (!analystRequest) return;
    await RequestApi.confirmLgpdAgreement({ id: analystRequest?.id })
      .then(() => {
        handleNextPage();
      })
      .catch((error: any) => {
        if (error.code === 'ERR_NETWORK') {
          return enqueueSnackbar('Servidor instável, aguarde alguns segundos e recarregue a página por favor', { variant: 'error' });
        }

        enqueueSnackbar(
          'Um erro ocorreu ao inserir o consentimento com a LGPD.',
          {
            variant: 'error',
          },
        );
        console.error(error);
      });
  };
  return (
    <DefaultPage>
      <Stack spacing={2}>
        <Typography variant="h1">
          {'LGPD – Lei Geral de Proteção de Dados'}
        </Typography>
        <Typography
          variant="body1"
          style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{
            __html: AssessmentMock.lgpd,
          }}
        />
        <ButtonMain
          onClick={confirmLgpdAgreement}
          text={'Consentir e continuar'}
        ></ButtonMain>
      </Stack>
    </DefaultPage>
  );
};
