import React from 'react';
import { OpenViewDetailsPropTypes } from './types';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import AssessmentDetailsTable from './assessment-details-table';
import dayjs from 'dayjs';

import Link from '@mui/material/Link';
import { FileApi } from 'lib/api/file';
import { FileDto, ReportType } from 'lib/api/file.protocols';
import { MessagingApi } from 'lib/api/messaging';
import { useSnackbar } from 'notistack';
import {
  ContentCopy,
  DeleteForeverOutlined,
  MailOutline,
} from '@mui/icons-material';
import { BaseBreadcrumbs } from 'modules/requests/pages/my-requests/style';
import { AssessmentApi } from 'lib/api/assessment';
import { RequestApi } from 'lib/api/requests';

interface IElements {
  id?: string;
  name: string;
}

const tableHead = ['Nome', 'Descrição', 'Nome', 'Descrição'];

function formatMilliseconds(ms: number) {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  return `${minutes}’${seconds.toString().padStart(2, '0')}’’`;
}

export function AssessmentDetailsPage({
  goToAllAssessments,
  data,
}: {
  goToAllAssessments: () => void;
  data: OpenViewDetailsPropTypes;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = React.useState<FileDto[]>([]);
  const [status, setStatus] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const SelfieModal = () => (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Sua selfie
        </Typography>
        <Box
          component="img"
          src={userInfo.selfie}
          alt="Selfie"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
            Fechar
          </Button>
          <Button sx={{ mt: 2 }} variant="contained">
            <a
              href={userInfo.selfie}
              download
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Download Selfie
            </a>
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const getReportUrl = async (
    azureFileName: string,
    reportUser: IElements | undefined,
  ) => {
    try {
      const reportType = reportUser
        ? ReportType.CONSULTANT_REPORT
        : ReportType.ASSESSMENT_REPORT;

      const url = await FileApi.getReportUrl({ azureFileName, reportType });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Erro ao gerar url do arquivo', { variant: 'error' });
    } finally {
    }
  };

  const {
    assessmentResponse,
    userInfo,
    company,
    instrument,
    region,
    costCenter,
    analyst,
    id,
    assessmentInfo,
    additionalNote,
  } = data;

  const tableBody = [
    {
      label1: 'Nome Completo',
      value1: userInfo.name,
      label2: 'Solicitante',
      value2: analyst.name,
    },
    {
      label1: 'CPF',
      value1: userInfo.cpf,
      label2: 'Email do solicitante',
      value2: analyst.email,
    },
    {
      label1: 'Email',
      value1: userInfo.email,
      label2: 'Empresa',
      value2: company.name,
    },
    {
      label1: 'Telefone',
      value1: userInfo.whatsapp,
      label2: 'Centro de custo',
      value2: costCenter,
    },
    {
      label1: 'Idade',
      value1: userInfo.age,
      label2: 'Observações',
      value2: additionalNote,
    },
    {
      label1: 'Gênero',
      value1: userInfo.gender,
      label2: 'Nota MS',
      value2: assessmentResponse?.msScore,
    },
    {
      label1: 'Região',
      value1: region,
      label2: 'Classificação',
      value2: assessmentResponse?.classification && (
        <Box
          sx={{
            backgroundColor: '#DBDBDB',
            padding: '6px 16px',
            borderRadius: '4px',
            textTransform: 'capitalize',
            width: '90%',
            textAlign: 'center',
          }}
        >
          {assessmentResponse?.classification.toLowerCase()}
        </Box>
      ),
    },
    {
      label1: 'Foto',
      value1: (
        <Typography
          style={{
            color: '#1976D2',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={handleOpenModal}
        >
          ver arquivo
        </Typography>
      ),
      label2: 'Data de conclusão',
      value2: assessmentResponse?.finishedAt
        ? dayjs(assessmentResponse?.finishedAt).format('DD/MM/YYYY')
        : '',
    },
    {
      label1: 'Status',
      value1: status && (
        <Box
          sx={{
            backgroundColor: status === 'FINALIZADO' ? '#81c163' : '#f9f158',
            padding: '6px 16px',
            borderRadius: '4px',
            textTransform: 'lowercase',
            width: '90%',
            textAlign: 'center',
          }}
        >
          {status}
        </Box>
      ),
      label2: 'Arquivo',
      value2: files?.[0]?.fileName ? (
        <a
          style={{
            color: '#1976D2',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={() => getReportUrl(files[0].azureFileName, files[0].user)}
          rel="noopener noreferrer"
        >
          Relatório
        </a>
      ) : (
        ''
      ),
    },
    {
      label1: 'Instrumento',
      value1: instrument.name,
      label2: '',
      value2: '',
    },
  ];

  const getStatus = async () => {
    try {
      const status = await RequestApi.getAnalystRequestStatus(id);
      setStatus(status);
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
          return enqueueSnackbar('Servidor instável, aguarde alguns segundos e recarregue a página por favor', { variant: 'error' });
      }
      enqueueSnackbar('Erro ao buscar status da solicitação.', {
        variant: 'error',
      });
      console.error('Erro ao buscar status da solicitação.', error);
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  const getFile = async () => {
    try {
      const files = await FileApi.searchByRequestId(id);
      setFiles(files);
    } catch (error) {
      enqueueSnackbar('Erro ao buscar arquivo.', { variant: 'error' });
      console.error('Erro ao buscar arquivo.', error);
    }
  };

  React.useEffect(() => {
    getFile();
  }, []);

  const testInfo = [
    {
      label: 'Copiar link da prova',
      value: (
        <ContentCopy
          style={{ cursor: 'pointer', width: '16px' }}
          onClick={() => {
            enqueueSnackbar('Link copiado!', {
              variant: 'info',
            });
            navigator.clipboard.writeText(assessmentInfo?.assessmentLink ?? '');
          }}
        />
      ),
    },
    {
      label: 'Nota MS',
      value: assessmentResponse?.msScore,
    },
    {
      label: 'Percentil',
      value: assessmentResponse?.percentile,
    },
    {
      label: 'Tempo',
      value: assessmentResponse?.executionTime
        ? formatMilliseconds(assessmentResponse.executionTime)
        : '',
    },
    {
      label: 'Acertos / Erros / Omissões',
      value: assessmentResponse?.hits
        ? `${assessmentResponse?.hits} / ${assessmentResponse?.errors} / ${assessmentResponse?.omissions}`
        : '0 / 0 / 0',
    },
  ];

  const handleMailSender = async () => {
    try {
      await MessagingApi.sendMail({
        analystRequestId: id,
        candidateEmail: userInfo.email,
        candidateName: userInfo.name,
        companyName: company.name,
      });
      enqueueSnackbar('Email enviado!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar('Erro ao enviar email.', { variant: 'error' });
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleResetAssessment = async () => {
    try {
      await AssessmentApi.resetAssessment(id);
      enqueueSnackbar('Prova resetada!', {
        variant: 'success',
      });
      getFile();
      getStatus();
      setOpen(false);
    } catch (err) {
      enqueueSnackbar('Erro ao resetar a prova.', { variant: 'error' });
      console.log(err);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <BaseBreadcrumbs aria-label="breadcrumb">
        <Link onClick={goToAllAssessments}>provas</Link>
        <Typography color="text.primary">detalhes da prova</Typography>
      </BaseBreadcrumbs>
      <Typography variant="h3" color="#66717f">
        Detalhes da prova
      </Typography>
      <AssessmentDetailsTable tableHead={tableHead} tableBody={tableBody} />
      <Stack
        style={{
          gap: '1rem',
        }}
      >
        <Typography>PROVA</Typography>
        <Box
          sx={{
            border: '1px solid var(--BACKOFFICE-Light-Gray, #DBDBDB)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid var(--BACKOFFICE-Light-Gray, #DBDBDB)',
              background: '#D1E8FF',
              fontSize: '16px',
              color: 'var(--BACKOFFICE-Preto, #000)',
              padding: '10px 16px',
            }}
          >
            Atenção e concentração
            <Box
              style={{
                display: 'flex',
                gap: '1.5rem',
              }}
            >
              <Box
                style={
                  status === 'FINALIZADO'
                    ? {
                        display: 'flex',
                        borderRadius: '4px',
                        border:
                          '1px solid var(--BACKOFFICE-Cor-Primria, #66717F)',
                      }
                    : {
                        display: 'flex',
                        borderRadius: '4px',
                        border:
                          '1px solid var(--BACKOFFICE-Cor-Primria, #1976D2)',
                      }
                }
              >
                <Button
                  onClick={handleMailSender}
                  style={{
                    display: 'flex',
                    gap: '0.25rem',
                  }}
                  disabled={status === 'FINALIZADO' ? true : false}
                >
                  <MailOutline
                    style={
                      status === 'FINALIZADO'
                        ? {
                            color: '#66717F',
                          }
                        : {
                            color: '#1976D2',
                          }
                    }
                  />
                  <Typography
                    style={
                      status === 'FINALIZADO'
                        ? {
                            color: '#66717F',
                            textTransform: 'none',
                          }
                        : {
                            color: '#1976D2',
                            textTransform: 'none',
                          }
                    }
                  >
                    Reenviar email
                  </Typography>
                </Button>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  borderRadius: '4px',
                  border: '1px solid var(--BACKOFFICE-Cor-Primria, #1976D2)',
                }}
              >
                <Button
                  onClick={handleOpenDialog}
                  style={{
                    display: 'flex',
                    gap: '0.25rem',
                  }}
                >
                  <DeleteForeverOutlined
                    style={{
                      color: '#1976D2',
                    }}
                  />
                  <Typography
                    style={{
                      color: '#1976D2',
                      textTransform: 'none',
                    }}
                  >
                    Resetar
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Dialog open={open} onClose={handleCloseDialog}>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja realizar o reset dessa prova? Isso
                implicará a exclusão da resposta da prova e do relatório, se
                este existir.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancelar</Button>
              <Button onClick={handleResetAssessment} autoFocus>
                Resetar Prova
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              gap: '0.5rem',
              padding: '6px 16px 10px 16px',
            }}
          >
            {testInfo.map((item, index) => {
              return (
                <Typography
                  key={index}
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    color: 'var(--BACKOFFICE-Cinza, #66717F)',
                    fontSize: '16px',
                  }}
                >
                  {item.label}
                  <Typography
                    sx={{
                      color: 'var(--BACKOFFICE-Preto, #000)',
                      fontSize: '16px',
                    }}
                  >
                    {item.value}
                  </Typography>
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Stack>
      <SelfieModal />
    </Container>
  );
}
