import React from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';

interface StyledBoxProps {
  children: React.ReactNode;
}

const StyledBox: React.FC<StyledBoxProps> = ({ children }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const boxStyle = isMobile
    ? {
        padding: '0',
        margin: '0',
      }
    : {
        width: '100%',
        maxWidth: '512px',
        alignSelf: 'center',
        border: '1px solid #238FEF',
        boxShadow: '2px 2px 0px 0px #238FEF',
        backgroundColor: '#F4F1F1',
        padding: '1.5em',
        margin: '1.5em !important',
      };

  return <Box sx={boxStyle}>{children}</Box>;
};

export default StyledBox;
