import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface LayoutProps {
  backgroundColor: string;
  padding: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

const Layout: React.FC<LayoutProps> = ({
  backgroundColor = 'defaultColor',
  padding = 'defaultPadding',
  children,
  style = {},
}) => {
  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        padding: padding,
        width: '100%',
        minHeight: '100vh',
        height: '100%',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
