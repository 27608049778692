import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface ITableBody {
  label1: string;
  value1: string | React.ReactNode;
  label2: string;
  value2: string | React.ReactNode;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCellFirstAndThird = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white, // White background for first and third column cells
  color: theme.palette.info.main, // Assuming blue is set as the 'info' color in your theme
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white, // White background for first and third column header cells
    color: theme.palette.info.main, // Blue text color
  }, // #1976D2
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCellSecondAndFourth = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#DBDBDB', // Grey background for second and fourth column cells
  color: theme.palette.common.black, // Black text color
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black, // Black text color
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: '#F4F4F4', // Grey background for second and fourth column header cells
  },
}));

export default function AssessmentDetailsTable(props: {
  tableHead: string[];
  tableBody: ITableBody[];
}) {
  return (
    <BaseTableContainer>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.tableHead.map((item, index) => (
              <StyledTableCell key={index}>{item}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableBody.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCellFirstAndThird align="left">
                  {item.label1}
                </StyledTableCellFirstAndThird>
                <StyledTableCellSecondAndFourth align="left">
                  {item.value1}
                </StyledTableCellSecondAndFourth>
                <StyledTableCellFirstAndThird align="left">
                  {item.label2}
                </StyledTableCellFirstAndThird>
                <StyledTableCellSecondAndFourth align="left">
                  {item.value2}
                </StyledTableCellSecondAndFourth>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </BaseTableContainer>
  );
}

export const BaseTableContainer = styled(TableContainer)`
  table {
    border: 1px solid #dbdbdb;
  }

  table thead tr th {
    background-color: #d1e8ff !important;
    color: #555 !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 5px;
  }

  table thead tr th:first-child {
    padding-left: 16px;
  }

  table tbody td {
    padding: 10px 5px;
  }

  table tbody td:first-child {
    padding-left: 16px;
  }

  table tbody td:last-child {
    padding-right: 16px;
  }

  table tbody td svg {
    font-size: 14pt;
    color: #7d7d7d;
  }

  table tbody td svg:hover {
    cursor: pointer;
    color: #373636;
  }

  table tbody td img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  table tbody td p {
    font-size: 11pt;
    line-height: 0.2;
  }

  table tbody tr .result p {
    line-height: 1;
    align-items: center;
  }
`;
