import { useEffect, useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { ICreateCompany, IProps } from './models';
import { FieldRow, FixedLabel } from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CompanyForm = ({
  isEdit,
  setOpen,
  handleLogo,
  logoUrl,
  setLogoUrl,
  handleSaveCompanyModal,
  companies,
  sectors,
  consultants,
  jobTitles,
  instruments,
  currentId,
}: IProps) => {
  const [selectedsSectors, setSelectedsSectors] = useState<string[]>([]);
  const [selectedsJobTitles, setSelectedsJobTitles] = useState<string[]>([]);
  const [selectedsInstruments, setSelectedsInstruments] = useState<string[]>(
    [],
  );
  const [selectedsUsers, setSelectedsUsers] = useState<string[]>([]);
  const selectedCompany = companies?.find((item) => item.id === currentId);

  const setFormValuesIfEdit = () => {
    if (selectedCompany) {
      setLogoUrl(selectedCompany.logo);

      selectedCompany.sectors.map((item) =>
        setSelectedsSectors((prev) => [...prev, item.name]),
      );

      selectedCompany.jobTitles.map((item) =>
        setSelectedsJobTitles((prev) => [...prev, item.name]),
      );

      selectedCompany.instruments.map((item) =>
        setSelectedsInstruments((prev) => [...prev, item.name]),
      );

      selectedCompany.consultants.map((item) =>
        setSelectedsUsers((prev) => [...prev, item.name]),
      );
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFormValuesIfEdit();
    }
  }, []);

  const handleChangeSelects = (
    event: SelectChangeEvent<typeof selectedsSectors>,
    fieldName: string,
  ) => {
    const {
      target: { value },
    } = event;

    switch (fieldName) {
      case 'sector':
        setSelectedsSectors(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      case 'job-title':
        setSelectedsJobTitles(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      case 'instrument':
        setSelectedsInstruments(
          typeof value === 'string' ? value.split(',') : value,
        );
        break;
      case 'user':
        setSelectedsUsers(typeof value === 'string' ? value.split(',') : value);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: isEdit ? `${selectedCompany?.name}` : '',
    },
    onSubmit: async (values) => {
      const sectorsToSend: string[] = selectedsSectors.map((sector) => {
        const findedId = sectors?.find((item) => item.name === sector);
        return findedId?.id as string;
      });

      const jobTitlesToSend: string[] = selectedsJobTitles.map((jobTitle) => {
        const findedId = jobTitles?.find((item) => item.name === jobTitle);

        return findedId?.id as string;
      });

      const consultantsToSend: string[] = selectedsUsers.map((consultant) => {
        const findedConsultant = consultants?.find(
          (item) => item.name === consultant,
        );
        return findedConsultant?.id as string;
      });

      const instrumentsToSend: string[] = selectedsInstruments.map(
        (instrument) => {
          const findedId = instruments?.find(
            (item) => item.name === instrument,
          );
          return findedId?.id as string;
        },
      );

      const dataToSend: ICreateCompany = {
        name: values.name,
        logo: logoUrl,
        sectorsIds: sectorsToSend,
        jobTitlesIds: jobTitlesToSend,
        consultantsIds: consultantsToSend,
        instrumentsIds: instrumentsToSend,
      };

      handleSaveCompanyModal(dataToSend);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isEdit ? (
        <DialogTitle id="alert-dialog-title">{'NOVA EMPRESA'}</DialogTitle>
      ) : (
        <DialogTitle id="alert-dialog-title">{'EDITAR EMPRESA'}</DialogTitle>
      )}

      <FieldRow>
        <TextField
          required
          name="name"
          label="Nome da empresa"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
      </FieldRow>

      {!isEdit ? (
        <FieldRow>
          <FixedLabel>Logo (tamanho recomendado 80x80px)</FixedLabel>
          <TextField
            type="file"
            onChange={(e: any) => {
              handleLogo(e);
            }}
          />
        </FieldRow>
      ) : (
        <FieldRow>
          <FixedLabel>Logo (tamanho recomendado 80x80px)</FixedLabel>
          <TextField
            type="file"
            onChange={(e: any) => {
              handleLogo(e);
            }}
          />
        </FieldRow>
      )}

      <FieldRow>
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
          <InputLabel id="serctors-multiple-checkbox-label">Setores</InputLabel>
          <Select
            multiple
            required
            value={selectedsSectors}
            onChange={(event) => handleChangeSelects(event, 'sector')}
            input={<OutlinedInput label="Setores" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {sectors?.map((sector) => (
              <MenuItem key={sector.id} value={sector.name}>
                <Checkbox
                  checked={selectedsSectors.indexOf(sector.name) > -1}
                />
                <ListItemText primary={sector.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>

      <FieldRow>
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
          <InputLabel id="users-multiple-checkbox-label">
            Consultores
          </InputLabel>
          <Select
            multiple
            // required
            value={selectedsUsers}
            onChange={(event) => handleChangeSelects(event, 'user')}
            input={<OutlinedInput label="Consultores" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {consultants?.map((user) => (
              <MenuItem key={user.id} value={user.name}>
                <Checkbox checked={selectedsUsers.indexOf(user.name) > -1} />
                <ListItemText primary={user.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>

      <FieldRow>
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
          <InputLabel id="job-titles-multiple-checkbox-label">
            Cargos
          </InputLabel>
          <Select
            multiple
            required
            value={selectedsJobTitles}
            onChange={(event) => handleChangeSelects(event, 'job-title')}
            input={<OutlinedInput label="Cargos" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {jobTitles?.map((job) => (
              <MenuItem key={job.id} value={job.name}>
                <Checkbox checked={selectedsJobTitles.indexOf(job.name) > -1} />
                <ListItemText primary={job.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>

      <FieldRow>
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
          <InputLabel id="instruments-multiple-checkbox-label">
            Instrumentos
          </InputLabel>
          <Select
            multiple
            required
            value={selectedsInstruments}
            onChange={(event) => handleChangeSelects(event, 'instrument')}
            input={<OutlinedInput label="Instrumentos" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {instruments?.map((instrument) => (
              <MenuItem key={instrument.id} value={instrument.name}>
                <Checkbox
                  checked={selectedsInstruments.indexOf(instrument.name) > -1}
                />
                <ListItemText primary={instrument.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FieldRow>

      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button variant="contained" color="success" autoFocus type="submit">
          Salvar
        </Button>
      </DialogActions>
    </form>
  );
};

export { CompanyForm };
