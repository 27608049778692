import React from 'react';
import { ConsultantRequestDetailsPage } from './consultant-request-details-page';
import { ConsultantRequestListPage } from './consultant-request-list-page';

export function ConsultantRequestPageRoot() {
  const [isDetailsView, setIsDetailsView] = React.useState(false);
  const [requestId, setrequestId] = React.useState('');
  const handleViewTest = (requestId: string) => {
    setrequestId(requestId);
    setIsDetailsView(true);
  };
  return isDetailsView ? (
    <ConsultantRequestDetailsPage
      id={requestId}
      goToAllRequests={() => setIsDetailsView(false)}
    />
  ) : (
    <ConsultantRequestListPage onViewDetails={handleViewTest} />
  );
}
