import React, { useState, useRef } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

interface PlaybackProps {
  audioFileUrl: string;
}

const Playback: React.FC<PlaybackProps> = ({ audioFileUrl }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div>
      <audio ref={audioRef} src={audioFileUrl}></audio>
      <PlayCircleOutlineIcon
        onClick={() => {
          toggleAudio();
        }}
      />
    </div>
  );
};

export default Playback;
