import axiosInstance from './api';
import { AssessmentTypeDTO } from './assessment.protocols';

export enum EducationEnum {
  ENSINO_FUNDAMENTAL_INCOMPLETO = 'ENSINO FUNDAMENTAL INCOMPLETO',
  ENSINO_FUNDAMENTAL_COMPLETO = 'ENSINO FUNDAMENTAL COMPLETO',
  ENSINO_MÉDIO_INCOMPLETO = 'ENSINO MÉDIO INCOMPLETO',
  ENSINO_MÉDIO_COMPLETO = 'ENSINO MÉDIO COMPLETO',
  SUPERIOR_INCOMPLETO = 'SUPERIOR INCOMPLETO',
  SUPERIOR_COMPLETO = 'SUPERIOR COMPLETO',
  PÓS_GRADUAÇÃO = 'PÓS-GRADUAÇÃO',
}

export enum GenderEnum {
  MASCULINO = 'MASCULINO',
  FEMININO = 'FEMININO',
}

export class AssessmentApi {
  private analystRequestId: string;

  constructor({ analystRequestId }: { analystRequestId: string }) {
    this.analystRequestId = analystRequestId;
  }

  getAnalystRequestId(): string {
    return this.analystRequestId;
  }

  static async getAnalystRequest(analystRequestId: string) {
    const response = await axiosInstance.get(
      `/request/assessment/${analystRequestId}/`,
    );

    return response.data;
  }

  static async getCandidate(analystRequestId: string, cpf: string) {
    const response = await axiosInstance.get(
      `/request/${analystRequestId}/candidate/${cpf}`,
    );

    return response.data;
  }

  static async patchCandidate(
    analystRequestId: string,
    body: {
      age: number;
      gender: GenderEnum;
      educationLevel: EducationEnum;
    },
  ) {
    const response = await axiosInstance.patch(
      `/request/candidate/${analystRequestId}`,
      body,
    );

    return response.data;
  }

  static async insertSelfieUrl(id: string, base64Selfie: string) {
    const response = await axiosInstance.post(
      `/file-upload/upload/candidate-selfie`,
      {
        base64Selfie,
        analystRequestId: id,
      },
    );
    return response.data;
  }

  static async getAssessmentResponseByAnalysrRequest(analystRequestId: string) {
    const response = await axiosInstance.get(
      `/assessment-response/analyst-request/${analystRequestId}`,
    );
    return response.data;
  }

  static async postAssessmentResponse(body: {
    analystRequestId: string;
    startedAt: string;
  }) {
    const response = await axiosInstance.post(`/assessment-response`, body);
    return response.data;
  }

  static async patchAssessmentResponse(body: {
    analystRequestId: string;
    hits: number;
    omissions: number;
    errors: number;
    finishedAt: string;
    executionTime: number;
    finishReason: 'USER' | 'TIMEOUT';
  }) {
    const response = await axiosInstance.patch(`/assessment-response`, body);
    return response.data;
  }

  static async getAssessmentBase(assessmentBaseId: string) {
    const response = await axiosInstance.get(
      `/assessment-base/${assessmentBaseId}`,
    );
    return response.data;
  }

  static async getAssessmentTypes(): Promise<AssessmentTypeDTO[]> {
    const response = await axiosInstance.get(`/assessment-type/all`);
    return response.data;
  }
  static async getAllAssessments() {
    const response = await axiosInstance.get(`/assessment-response/all`);
    return response.data;
  }

  static async getSearchedAssessments(query: string, page: number) {
    const response = await axiosInstance.get(
      `/request/search?${query}&page=${page}`,
    );
    return response.data;
  }

  static async postAssessmentFeedback(body: {
    analystRequestId: string;
    rating: number;
    comment?: string;
  }) {
    const response = await axiosInstance.post(`/assessment-rating/`, body);
    return response.data;
  }

  static async resetAssessment(analystRequestId: string) {
    await axiosInstance.delete(
      `/assessment-response/analyst-request/${analystRequestId}`,
    );
  }
}
