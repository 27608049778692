import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import { useParams } from 'react-router-dom';
import { useAssessment } from 'modules/assessment-execution/context/assessment.consumer';
import { AssessmentApi } from 'lib/api/assessment';
import { FirstPage } from 'modules/assessment-execution/components/FirstPage';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const InitialPage = () => {
  const { analystRequestId } = useParams();
  const { setAnalystRequest, handleNextPage, analystRequest } = useAssessment();
  const { enqueueSnackbar } = useSnackbar();
  const [assessmentIsDone, setAssessmentIsDone] = React.useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (analystRequestId) {
      AssessmentApi.getAnalystRequest(analystRequestId)
        .then(setAnalystRequest)
        .catch((error) => {
          enqueueSnackbar('Erro ao carregar a solicitação.', {
            variant: 'error',
          });
          console.error(error);
        });
    }
  }, [analystRequestId, setAnalystRequest]);

  useEffect(() => {
    checkIfAssessmentIsDone();
  });

  const checkIfAssessmentIsDone = async () => {
    try {
      if (analystRequestId) {
        const assessmentResponse =
          await AssessmentApi.getAssessmentResponseByAnalysrRequest(
            analystRequestId,
          );

        if (assessmentResponse) {
          setAssessmentIsDone(true);
        } else {
          setAssessmentIsDone(false);
        }
      }
    } catch (error) {
      enqueueSnackbar('Erro ao carregar a solicitação.', {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <FirstPage>
      {assessmentIsDone ? (
        <>
          <Typography
            variant="body1"
            style={{ whiteSpace: 'pre-line', color: '#F4F1F1' }}
          >
            Olá! Seja bem-vindo(a) à plataforma de trilhas de competências da
            MS.
            <br />
            <br />
            Nosso sistema detectou que você já realizou essa prova, por isso não
            é possível acessar a plataforma novamente.
            <br />
            <br />
            <p>
              Se precisar de ajuda, acesse nossa
              <strong> Central WhatsApp</strong>, clicando abaixo.
            </p>
          </Typography>
          <Button
            startIcon={<WhatsAppIcon />}
            onClick={() => {
              const whatsappUrl = `https://wa.me/5511910394147?text=${encodeURIComponent(
                `Olá, preciso de ajuda para fazer a prova MS.`,
              )}`;
              window.open(whatsappUrl, '_blank');
            }}
            sx={{
              fontWeight: 'bold',
              color: 'rgba(37, 211, 102, 1)',
              alignSelf: 'flex-start',
              textTransform: 'none',
              fontSize: {
                xs: '16px',
                md: '20px',
              },
              '& .MuiButton-startIcon': {
                '& > *': {
                  width: '24px',
                  height: '24px',
                },
              },
            }}
          >
            Central WhatsApp
          </Button>
        </>
      ) : assessmentIsDone === false ? (
        <>
          <Typography
            variant="body1"
            style={{ whiteSpace: 'pre-line', color: '#F4F1F1' }}
          >
            Olá! Seja bem-vindo(a) à plataforma de trilhas de competências da
            MS.
            <br />
            <br />
            Você realizará algumas provas que trarão dados sobre sua atenção,
            perfil comportamental, memória, entre outros, dependendo da
            solicitação de nosso cliente.
            <br />
            <br />
            Esta trilha foi solicitada pela{' '}
            <b>{analystRequest?.company?.name}</b>.
            <br />
            <br />
            <b>
              Nossas provas são otimizadas para serem realizadas através de um
              computador ou laptop
            </b>
            , mas caso você não possua ou não tenha onde realizar, não se
            preocupe, pois elas também foram adaptadas para serem feitas no
            celular.
          </Typography>
          <ButtonMain
            onClick={handleNextPage}
            text={!analystRequest ? 'Carregando...' : 'Continuar'}
            disabled={!analystRequest}
          ></ButtonMain>
        </>
      ) : (
        <></>
      )}
    </FirstPage>
  );
};

export default InitialPage;
