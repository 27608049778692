import { PageDto } from 'lib/common/dto/page.dto';
import axiosInstance from './api';
import { UserDTO, UserReceivedDTO, UserStatusDTO } from './user.protocols';

class UserApi {
  static async createUser(createUserDTO: UserDTO): Promise<UserDTO> {
    const response: UserDTO = await axiosInstance
      .post('/user', createUserDTO)
      .then((response) => response.data);

    return response;
  }

  static async editUser(id: string, editedUserDTO: UserDTO): Promise<UserDTO> {
    const response: UserDTO = await axiosInstance
      .put(`/user/${id}`, editedUserDTO)
      .then((response) => response.data);

    return response;
  }

  static async editUserStatus(
    id: string,
    userStatusDTO: UserStatusDTO,
  ): Promise<UserDTO> {
    const response: UserDTO = await axiosInstance
      .put(`/user/status/${id}`, userStatusDTO)
      .then((response) => response.data);

    return response;
  }

  static async deleteUser(id: string): Promise<UserDTO> {
    const response: UserDTO = await axiosInstance
      .delete(`/user/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async getUsers(): Promise<UserDTO[]> {
    const response: UserDTO[] = await axiosInstance
      .get(`/user/all`)
      .then((response) => response.data);

    return response;
  }

  static async getRoles(): Promise<string[]> {
    const response: string[] = await axiosInstance
      .get('/user/all/roles')
      .then((response) => response.data);

    return response;
  }

  static async getUser(id: string): Promise<UserReceivedDTO> {
    const response: UserReceivedDTO = await axiosInstance
      .get(`/user/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async searchUsers(searchQuery?: string): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/user/search?${searchQuery}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }
}

export { UserApi };
