import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@material-ui/core';

interface ButtonMainProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonMain: React.FC<ButtonMainProps> = ({
  onClick,
  text,
  disabled = false,
  loading = false,
}) => {
  const customStyles = {
    width: '100%',
    maxWidth: '512px',
    padding: '8px 27px',
    alignSelf: 'center',
    borderRadius: '4px',
    border: '1px solid #2C109C',
    background: '#238FEF',
    boxShadow: '2px 2px 0px 0px #2C109C',
  };

  const disabledStyles = {
    width: '100%',
    maxWidth: '512px',
    padding: '8px 27px',
    alignSelf: 'center',
    borderRadius: '4px',
    border: '1px solid #79747',
    background: '#DBD7D6',
  };

  // Conditionally apply styles based on the disabled prop
  const buttonStyle = disabled ? disabledStyles : customStyles;

  return loading ? (
    <Button>
      <CircularProgress />
    </Button>
  ) : (
    <Button
      variant="contained"
      style={buttonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default ButtonMain;
