import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from '../context/assessment.consumer';

export const PreparePage = () => {
  const { handleNextPage, handlePreviousPage } = useAssessment();

  const handleConfirmAndContinue = () => {
    handleNextPage();
  };

  return (
    <DefaultPage
      style={{
        overscrollBehavior: 'contain',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h1" component="h1">
          Prova de atenção e concentração
        </Typography>
        <Typography variant="body1" component="div">
          <p>
            Perfeito. Se você chegou até aqui é porque entendeu as instruções.
            Caso queira,{' '}
            <strong
              style={{ cursor: 'pointer', color: '#238FEF' }}
              onClick={handlePreviousPage}
            >
              volte para a tela anterior
            </strong>{' '}
            e treine novamente. Não inicie a prova se ainda estiver com dúvidas.
          </p>

          <p>
            Se precisar de ajuda, acesse nossa
            <strong> Central WhatsApp</strong>, clicando abaixo.
          </p>
        </Typography>
        <Button
          startIcon={<WhatsAppIcon />}
          onClick={() => {
            const whatsappUrl = `https://wa.me/5511910394147?text=${encodeURIComponent(
              `Olá, preciso de ajuda para fazer a prova MS.`,
            )}`;
            window.open(whatsappUrl, '_blank');
          }}
          sx={{
            fontWeight: 'bold',
            color: 'rgba(37, 211, 102, 1)',
            alignSelf: 'flex-start',
            textTransform: 'none',
            fontSize: {
              xs: '16px',
              md: '20px',
            },
            '& .MuiButton-startIcon': {
              '& > *': {
                width: '24px',
                height: '24px',
              },
            },
          }}
        >
          Central WhatsApp
        </Button>
        <Divider />
        <Typography variant="body1" component="div">
          <p>Vamos começar?</p>
          <p>
            <strong>
              Você terá 3:20 minutos (três minutos e vinte segundos) para
              finalizar a prova.
            </strong>
          </p>
          <p>
            Se você estiver fazendo sua prova de um <b>computador</b>, a prova
            aparecerá apenas em uma tela e você deverá utilizar a barra de
            rolagem ou o mouse para subir a tela e visualizar a prova até o fim.
          </p>
          <p>
            Se você estiver fazendo a prova de um <b>celular</b>, a prova
            aparecerá dividida em quatro telas. Quando chegar ao final de cada
            tela, clique na setinha azul, no lado direito, para ir para a
            próxima.
          </p>
          <p>Aja rápido, mas com muita atenção.</p>
          <p>
            Caso você finalize antes do tempo acabar, basta clicar em finalizar.
          </p>
          <p>
            Se o tempo acabar e você ainda não tiver finalizado, a prova
            encerrará automaticamente, mas não se preocupe:{' '}
            <strong>todas as suas respostas serão computadas.</strong>
          </p>
          <p>
            Você confirma que recebeu todas as instruções, leu e compreendeu o
            que precisa ser feito?
          </p>
        </Typography>
        <ButtonMain
          onClick={handleConfirmAndContinue}
          text={'Consentir e continuar'}
        ></ButtonMain>
      </Stack>
    </DefaultPage>
  );
};
