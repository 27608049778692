import { RoleType } from 'modules/auth/common/role';
import { Navigate, Outlet } from 'react-router-dom';

interface GuardProps {
  role: RoleType;
  conditions: RoleType[];
  children: React.ReactNode;
}

export const RouteGuard = ({ role, conditions, children }: GuardProps) => {
  if (!conditions.includes(role)) {
    return <Navigate to="/" replace />;
  }

  return <>{children || <Outlet />}</>;
};
