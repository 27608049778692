import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

import { BaseDeleteForeverIcon, Container } from './style';

interface IDeleteButton {
  id: string | undefined;
  callback: (id: string) => Promise<void>;
}

export default function DeleteButton({
  id,
  callback,
}: IDeleteButton): JSX.Element {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BaseDeleteForeverIcon onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Container>
          <DialogTitle id="alert-dialog-title">{'CONFIRMAÇÃO'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja excluir este item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleClose();
                callback(id!);
              }}
              autoFocus
            >
              Excluir
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
