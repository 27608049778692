import { PageDto } from 'lib/common/dto/page.dto';
import axiosInstance from './api';
import { InstrumentDTO } from './instrument.protocols';

class InstrumentApi {
  static async createInstrument(
    createInstrumentDTO: InstrumentDTO,
  ): Promise<InstrumentDTO> {
    const response: InstrumentDTO = await axiosInstance
      .post('/instrument', createInstrumentDTO)
      .then((response) => response.data);

    return response;
  }

  static async editInstrument(
    id: string,
    editedInstrumentDTO: InstrumentDTO,
  ): Promise<InstrumentDTO> {
    const response: InstrumentDTO = await axiosInstance
      .put(`/instrument/${id}`, editedInstrumentDTO)
      .then((response) => response.data);

    return response;
  }

  static async deleteInstrument(id: string): Promise<InstrumentDTO> {
    const response: InstrumentDTO = await axiosInstance
      .delete(`/instrument/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async getInstruments(): Promise<InstrumentDTO[]> {
    const response: InstrumentDTO[] = await axiosInstance
      .get('/instrument/all')
      .then((response) => response.data);

    return response;
  }

  static async searchInstruments(
    objectToSearch: string,
    page: number,
  ): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/instrument/search?name=${objectToSearch}&page=${page}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }

  static async getAssessmentInstruments(): Promise<InstrumentDTO[]> {
    const response: InstrumentDTO[] = await axiosInstance
      .get('/instrument/assessment/all')
      .then((response) => response.data);

    return response;
  }
}

export { InstrumentApi };
