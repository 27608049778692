import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CardBox from 'lib/components/CardBox';
import {
  Container,
  Box,
  StyledLink,
  CenteredParagraph,
  HorizontalBox,
  CircularLoadingBox,
} from './style';
import { useAuth } from 'modules/auth/context/auth.consumer';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { ISnackBarSettings } from 'lib/helpers/snackbar-interface';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GoogleIcon from '@mui/icons-material/Google';
import CircularProgress from '@mui/material/CircularProgress';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [isMicrosoftLoginLoading, setIsMicrosoftLoginLoading] = useState(false);
  const [isGoogleLoginLoading, setIsGoogleLoginLoading] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const { loginWithEmailAndPassword, loginWithGoogleOrMicrosoft } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const errorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Firebase: Error (auth/wrong-password).':
      case 'Firebase: Error (auth/invalid-email).':
        return 'Senha ou email incorretos.';
      case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
        return 'Usuário desabilitado temporariamente. Reset sua senha ou tente novamente mais tarde.';
      case 'Firebase: Error (auth/user-not-found).':
        return 'Usuário não encontrado.';
      case 'Firebase: Error (auth/user-disabled).':
        return 'Usuário inativo.';
      case 'User not found':
        return 'Usuário não cadastrado.';
      default:
        return 'Erro desconhecido.';
    }
  };

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 4000);
  };

  return (
    <Container>
      <div className="leftSide">
        <h1>MS AVALIAÇÃO</h1>
      </div>
      <div className="rightSide">
        <CardBox width="400px">
          <form
            onSubmit={async (e: any) => {
              e.preventDefault();
              try {
                await loginWithEmailAndPassword(email, password);
                setIsLoginLoading(true);
              } catch (e: any) {
                setIsLoginLoading(false);
                setSnackBarSettings({
                  show: true,
                  message: e.response
                    ? errorMessage(e.response.data.message)
                    : errorMessage(e.message),
                  severity: 'error',
                });
                resetSnackBar();
              }
            }}
          >
            <Box>
              <h3>Bem-vindo!</h3>
              <p>Informe os dados solicitados abaixo para acessar o sistema.</p>
              {snackBarSettings.show && (
                <CustomizedSnackbars
                  message={snackBarSettings.message}
                  severity={snackBarSettings.severity}
                />
              )}
              <TextField
                required
                onChange={(event) => setEmail(event.target.value)}
                label="E-mail"
                variant="outlined"
              />
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Senha *
                </InputLabel>
                <OutlinedInput
                  required
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </FormControl>
              <StyledLink to="/esqueci-senha">Esqueci minha senha</StyledLink>
              {isLoginLoading ? (
                <CircularLoadingBox>
                  <CircularProgress />
                </CircularLoadingBox>
              ) : (
                <Button type="submit" variant="contained">
                  ENTRAR
                </Button>
              )}
            </Box>
          </form>
          <CenteredParagraph>Ou entre com</CenteredParagraph>
          <HorizontalBox>
            {isMicrosoftLoginLoading ? (
              <CircularLoadingBox>
                <CircularProgress />
              </CircularLoadingBox>
            ) : (
              <Button
                type="submit"
                variant="outlined"
                size="small"
                onClick={async () => {
                  try {
                    setIsMicrosoftLoginLoading(true);
                    await loginWithGoogleOrMicrosoft('microsoft');
                  } catch (e: any) {
                    setIsMicrosoftLoginLoading(false);
                    setSnackBarSettings({
                      show: true,
                      message: e.response
                        ? errorMessage(e.response.data.message)
                        : errorMessage(e.message),
                      severity: 'error',
                    });
                    resetSnackBar();
                  }
                }}
              >
                <MicrosoftIcon></MicrosoftIcon>
                &nbsp;&nbsp;Microsoft
              </Button>
            )}
            {isGoogleLoginLoading ? (
              <CircularLoadingBox>
                <CircularProgress />
              </CircularLoadingBox>
            ) : (
              <Button
                type="submit"
                variant="outlined"
                size="small"
                onClick={async () => {
                  try {
                    setIsGoogleLoginLoading(true);
                    await loginWithGoogleOrMicrosoft('google');
                  } catch (e: any) {
                    setIsGoogleLoginLoading(false);
                    setSnackBarSettings({
                      show: true,
                      message: e.response
                        ? errorMessage(e.response.data.message)
                        : errorMessage(e.message),
                      severity: 'error',
                    });
                    resetSnackBar();
                  }
                }}
              >
                <GoogleIcon></GoogleIcon>
                &nbsp;&nbsp;Google
              </Button>
            )}
          </HorizontalBox>
        </CardBox>
      </div>
    </Container>
  );
}

export { LoginPage };
