import { useEffect, useState } from 'react';

import { useFormik, FormikProvider } from 'formik';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { Dayjs } from 'dayjs';
import { RequestApi } from 'lib/api/requests';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import DeleteButton from 'lib/components/DeleteButton';
import CustomizedTable from './components/Table';

import { cpfMask, phoneMask } from 'lib/helpers/masks';
import { IElements, IRequest, ISnackBarSettings } from './models';
import {
  BlockTitle,
  FieldRow,
  BaseInputLabel,
  ButtonsBox,
  BaseCardBox,
  EditButton,
  Dialog,
  ErrorMsg,
} from './style';
import { RequestEditModal } from './components/RequestEditModal';
import { validateCPF } from 'lib/helpers/cpf-validation';

export interface ITableBody {
  completeName: string;
  actions: JSX.Element;
}

interface RequestDataForm {
  requestDate: Dayjs | null;
  jobTitleId: string;
  region: string;
  instrumentId: string;
  sectorId: string;
  costCenter: string;
  additionalNote: string;
  candidate: {
    name: string;
    cpf: string;
    email: string;
    whatsapp: string;
  };
}

const RequestForm = ({ company, sectors }: any) => {
  const [regions, setRegions] = useState<string[]>();
  const [requests, setRequests] = useState<IRequest[]>([]);
  const [request, setRequest] = useState<IRequest>();
  const [isAdd, setIsAdd] = useState(false);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [requestDate, setRequestDate] = useState<Dayjs | null>(null);
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [itemToEditIndex, setItemToEditIndex] = useState<number | null>(null);

  const sortedJobTitles =
    company &&
    company.jobTitles.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  const sortedInstruments =
    company &&
    company.instruments.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  const sortedSectors =
    sectors &&
    sectors.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  const handleChangeRequestDate = (newValue: Dayjs | null) => {
    setRequestDate(newValue);
  };

  const getRegions = async () => {
    const response = await RequestApi.getRegions();
    setRegions(response);
  };

  useEffect(() => {
    if (regions === undefined) {
      getRegions();
    }
  });

  const handleDelete = async (requestToDeleteIndex: string) => {
    const result = requests.filter(
      (request, index) => index.toString() !== requestToDeleteIndex,
    );
    setRequests(result);

    setSnackBarSettings({
      show: true,
      message: 'Solicitação excluida!',
      severity: 'success',
    });
    resetSnackBar();
  };

  const handleEdit = (item: IRequest, index: number) => {
    setRequest(item);
    setItemToEditIndex(index);
    setIsEdit(true);
    setOpen(true);
  };

  const mountTableBody = () => {
    requests?.map((item, index) => {
      setTableBody((prev) => [
        ...prev,
        {
          completeName: item.candidate.name,
          actions: (
            <div key={index}>
              <EditButton onClick={() => handleEdit(item, index)} />
              <DeleteButton id={index.toString()} callback={handleDelete} />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [requests]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleSaveRequestsArray = async () => {
    setIsAdd(false);

    if (requests.length > 0) {
      try {
        await RequestApi.createRequests(requests);
        formik.resetForm();
        setRequestDate(null);
        setRequests([]);
        setSnackBarSettings({
          show: true,
          message: 'Requisição(ões) criada(s) com sucesso!',
          severity: 'success',
        });
        resetSnackBar();
      } catch (err: any) {
        if (err.code === 'ERR_NETWORK') {
          return setSnackBarSettings({
            show: true,
            message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
            severity: 'error',
          });
        }
        setSnackBarSettings({
          show: true,
          message: err.response.data.message,
          severity: 'error',
        });
        resetSnackBar();
      }
    }
  };

  const handleSaveRequestForm = async (requestData: RequestDataForm) => {
    if (isAdd === true) {
      setTableBody([]);
      mountTableBody();
      formik.resetForm();
      setRequestDate(null);
      setChecked(false);
    }

    if (isAdd === false) {
      if (requestData && requests.length === 0) {
        try {
          await RequestApi.createRequests([requestData]);
          formik.resetForm();
          setRequestDate(null);
          setChecked(false);
          setSnackBarSettings({
            show: true,
            message: 'Requisição criada com sucesso!',
            severity: 'success',
          });
          resetSnackBar();
        } catch (err: any) {
          if (err.code === 'ERR_NETWORK') {
            return setSnackBarSettings({
              show: true,
              message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
              severity: 'error',
            });
          }

          setSnackBarSettings({
            show: true,
            message: err.response.data.message,
            severity: 'error',
          });
          resetSnackBar();
        }
      } else {
        if (requests.length > 0) {
          setSnackBarSettings({
            show: true,
            message: 'Termine de adicionar as solicitações antes de enviá-las.',
            severity: 'error',
          });
          resetSnackBar();
        }
      }
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Campo obrigatório'),
    cpf: checked ? yup.string() : yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .required('Campo obrigatório')
      .email('Informe um e-mail válido'),
    whatsapp: yup.string().required('Campo obrigatório'),
    jobTitleId: yup.string().required(),
    region: yup.string().required(),
    instrumentId: yup.string().required(),
    sectorId: yup.string().required(),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      jobTitleId: '',
      region: '',
      instrumentId: '',
      sectorId: '',
      costCenter: '',
      additionalNote: '',
      name: '',
      cpf: '',
      email: '',
      whatsapp: '',
    },
    onSubmit: (values) => {
      values.cpf = checked ? '000.000.000-00' : values.cpf;

      const treatedCpf = values.cpf.replace(/\.|-|\s/g, '');

      if (treatedCpf.length > 0 && treatedCpf.length < 11) {
        snackBar('O CPF precisa conter 11 dígitos.', 'error');
        return;
      }

      let cpfIsValid = true;

      if (!checked) {
        cpfIsValid = validateCPF(values.cpf);
      }

      if (!cpfIsValid) {
        snackBar('O CPF inserido não é válido.', 'error');
        return;
      }

      const dataToSend = {
        requestDate: requestDate,
        jobTitleId: values.jobTitleId,
        region: values.region,
        instrumentId: values.instrumentId,
        sectorId: values.sectorId,
        costCenter: values.costCenter,
        additionalNote: values.additionalNote,
        candidate: {
          name: values.name,
          cpf: values.cpf,
          email: values.email,
          whatsapp: values.whatsapp,
        },
      };

      const compare = requests.find(
        (item) => item.candidate.name === dataToSend.candidate.name,
      );

      if (requests.length > 0) {
        if (compare === undefined) {
          requests.push(dataToSend);
          setSnackBarSettings({
            show: true,
            message: 'Solicitação inclusa com sucesso!',
            severity: 'success',
          });
          resetSnackBar();
        }
      } else {
        if (isAdd === true) {
          requests.push(dataToSend);
          setSnackBarSettings({
            show: true,
            message: 'Solicitação inclusa com sucesso!',
            severity: 'success',
          });
          resetSnackBar();
        }
      }

      handleSaveRequestForm(dataToSend);
    },
  });

  const handleCloseModalForm = () => {
    setOpen(false);
  };

  const snackBar = (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info' | undefined,
  ) => {
    setSnackBarSettings({
      show: true,
      message: message,
      severity: severity,
    });
    resetSnackBar();
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <BaseCardBox>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <h3>Solicitar avaliação</h3>

          <BlockTitle>Dados pessoais</BlockTitle>

          <FieldRow>
            <TextField
              fullWidth
              name="name"
              label="Nome completo *"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              fullWidth
              name="cpf"
              label="CPF *"
              type="text"
              value={checked ? '000.000.000-00' : cpfMask(formik.values.cpf)}
              onChange={formik.handleChange}
              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
              helperText={formik.touched.cpf && formik.errors.cpf}
              inputProps={{ maxLength: 14 }}
              disabled={checked}
            />
          </FieldRow>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <FormControlLabel
              style={{ fontFamily: 'Roboto', color: ' #66717f' }}
              label="Candidato(a) entrangeiro(a)."
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => handleCheckBoxChange(event)}
                />
              }
            />
          </div>

          <FieldRow>
            <TextField
              fullWidth
              name="email"
              label="E-mail *"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              fullWidth
              name="whatsapp"
              label="Whatsapp *"
              type="text"
              value={
                formik.values.whatsapp
                  ? phoneMask(formik.values.whatsapp, 'celular')
                  : ''
              }
              onChange={formik.handleChange}
              error={formik.touched.whatsapp && Boolean(formik.errors.whatsapp)}
              helperText={formik.touched.whatsapp && formik.errors.whatsapp}
              inputProps={{ maxLength: 15 }}
            />
          </FieldRow>

          <BlockTitle>Dados da solicitação</BlockTitle>

          <FieldRow>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ width: '100%' }}>
                <DesktopDatePicker
                  label="Data da solicitação *"
                  format="DD/MM/YYYY"
                  disablePast
                  value={requestDate}
                  onChange={handleChangeRequestDate}
                  onError={(newError) =>
                    setInvalidDate(newError ? true : false)
                  }
                />
                {invalidDate && (
                  <ErrorMsg>Essa data não pode ser retroativa</ErrorMsg>
                )}
              </div>
            </LocalizationProvider>

            <FormControl style={{ width: '100%' }}>
              <BaseInputLabel id="demo-simple-select-label">
                Cargo *
              </BaseInputLabel>
              <Select
                name="jobTitleId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.jobTitleId}
                translate={'no'}
                onChange={formik.handleChange}
                error={
                  formik.touched.jobTitleId && Boolean(formik.errors.jobTitleId)
                }
              >
                {sortedJobTitles &&
                  sortedJobTitles.map((item: any) => (
                    <MenuItem value={item.id}
                              translate={'no'}
                              key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FieldRow>

          <FieldRow>
            <FormControl style={{ width: '100%' }}>
              <BaseInputLabel id="demo-simple-select-label">
                Região *
              </BaseInputLabel>
              <Select
                fullWidth
                name="region"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.region}
                onChange={formik.handleChange}
                error={formik.touched.region && Boolean(formik.errors.region)}
              >
                {regions?.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: '100%' }}>
              <BaseInputLabel id="demo-simple-select-label">
                Instrumento a ser enviado *
              </BaseInputLabel>
              <Select
                fullWidth
                name="instrumentId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.instrumentId}
                onChange={formik.handleChange}
                error={
                  formik.touched.instrumentId &&
                  Boolean(formik.errors.instrumentId)
                }
              >
                {sortedInstruments &&
                  sortedInstruments.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FieldRow>

          <FieldRow>
            <FormControl style={{ width: '100%' }}>
              <BaseInputLabel id="demo-simple-select-label">
                Setor *
              </BaseInputLabel>
              <Select
                fullWidth
                name="sectorId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sectorId}
                onChange={formik.handleChange}
                error={
                  formik.touched.sectorId && Boolean(formik.errors.sectorId)
                }
              >
                {sortedSectors &&
                  sortedSectors.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              name="costCenter"
              label="Centro de custo"
              type="text"
              value={formik.values.costCenter}
              onChange={formik.handleChange}
            />
          </FieldRow>

          <FieldRow>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="additionalNote"
              label="Observação"
              type="textarea"
              value={formik.values.additionalNote}
              onChange={formik.handleChange}
              error={
                formik.touched.additionalNote &&
                Boolean(formik.errors.additionalNote)
              }
              helperText={
                formik.touched.additionalNote && formik.errors.additionalNote
              }
            />
          </FieldRow>

          <ButtonsBox>
            <Button
              variant="outlined"
              type="submit"
              onClick={() => setIsAdd(true)}
            >
              Salvar e adicionar nova solicitação
            </Button>

            {requests.length > 0 && <CustomizedTable tableBody={tableBody} />}

            {formik.values.name !== '' ? (
              <Button
                variant="contained"
                type="submit"
                onClick={() => setIsAdd(false)}
              >
                Finalizar e enviar solicitações
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  handleSaveRequestsArray();
                }}
              >
                Finalizar e enviar solicitações
              </Button>
            )}
          </ButtonsBox>
        </form>

        {isEdit && request && (
          <Dialog
            open={open}
            onClose={handleCloseModalForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <RequestEditModal
              request={request}
              regions={regions}
              company={company}
              requests={requests}
              setRequests={setRequests}
              snackBar={snackBar}
              setIsEdit={setIsEdit}
              setOpen={setOpen}
              itemToEditIndex={itemToEditIndex}
            />
          </Dialog>
        )}

        {snackBarSettings.show && (
          <CustomizedSnackbars
            message={snackBarSettings.message}
            severity={snackBarSettings.severity}
          />
        )}
      </FormikProvider>
    </BaseCardBox>
  );
};

export { RequestForm };
