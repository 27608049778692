import { InputLabel } from '@mui/material';
import styled from 'styled-components';

export const ErrorMsg = styled.p`
  color: #fb5454 !important;
  font-size: 10pt;
  margin: 5px 0 0 5px;
`;

export const FieldRow = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  gap: 20px;

  #checkbox-group {
    font-size: 12pt;
    color: #333;
  }

  .sectorsSelects {
    display: flex;
    flex-direction: column;
    font-size: 12pt;
    color: #777;
    input {
      width: 20px;
    }

    label span {
      padding: 0;
      margin-left: 10px;
    }
  }
`;

export const BaseInputLabel = styled(InputLabel)`
  background-color: #fff;
  padding: 0 8px !important;
`;

export const FileInput = styled.input`
  width: calc(100% - 30px);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
`;
