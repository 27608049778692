import {
  RequestDTO,
  IRequestResponseDTO,
  IFilesLinksDTO,
  RequestManagementDTO,
} from './requests.protocols';
import axiosInstance from './api';

class RequestApi {
  static async createRequests(data: RequestDTO[]): Promise<RequestDTO[]> {
    const response: RequestDTO[] = await axiosInstance
      .post(`/request`, data)
      .then((response) => response.data);

    return response;
  }

  static async editRequest(data: RequestDTO, id: string): Promise<RequestDTO> {
    const response: RequestDTO = await axiosInstance
      .put(`/request/${id}`, data)
      .then((response) => response.data);

    return response;
  }

  static async editRequestManagement(
    requestMenagementData: RequestManagementDTO,
    id: string,
  ): Promise<RequestManagementDTO> {
    const response: RequestManagementDTO = await axiosInstance
      .put(`/request/requestManagement/${id}`, requestMenagementData)
      .then((response) => response.data);

    return response;
  }

  static async retrieveFilesLinks(data: any): Promise<any> {
    const response: any = await axiosInstance
      .post(`/file-upload/upload/files`, data)
      .then((response) => response.data);

    return response;
  }

  static async saveFiles(data: IFilesLinksDTO[]): Promise<IFilesLinksDTO[]> {
    const response: IFilesLinksDTO[] = await axiosInstance
      .post(`/file-upload/upload/request-report`, data)
      .then((response) => response.data);

    return response;
  }

  static async getRequests(): Promise<IRequestResponseDTO[]> {
    const response: IRequestResponseDTO[] = await axiosInstance
      .get(`/request/all`)
      .then((response) => response.data);

    return response;
  }

  static async getRequest(id: string): Promise<IRequestResponseDTO> {
    const response: IRequestResponseDTO = await axiosInstance
      .get(`/request/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async searchRequests(query: string, page: number) {
    const response = await axiosInstance.get(
      `/request/search?${query}&page=${page}`,
    );

    return response.data;
  }
  static async searchRequestsWithoutAssessment(query: string, page: number) {
    const response = await axiosInstance.get(
      `/request/search-without-assessment?${query}&page=${page}`,
    );

    return response.data;
  }

  static async getRegions(): Promise<string[]> {
    const response: string[] = await axiosInstance
      .get(`/request/all/regions`)
      .then((response) => response.data);

    return response;
  }

  static async confirmLgpdAgreement(body: { id: string }) {
    await axiosInstance.patch(`/request/assessment/lgpd-agreement/`, body);
  }

  static async getAnalystRequestStatus(id: string) {
    const response = await axiosInstance.get(`/request/status/${id}`);
    return response.data;
  }
}

export { RequestApi };
