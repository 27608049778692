import { Box, ButtonBase } from '@mui/material';
import Estimulo8 from '../assets/estimulos/Estímulo-08.svg';

interface StimulusProps {
  src?: string;
  alt?: string;
  onClick?: (src: string) => void;
  selected?: boolean;
  isTarget?: boolean; // New prop to indicate if the stimulus is the target
  status?: 'correct' | 'wrong' | 'missed' | 'none'; // Updated to include 'none'
  isSubmitted?: boolean; // New prop to indicate if the test is submitted
}

export const Stimulus: React.FC<StimulusProps> = ({
  src = Estimulo8,
  alt = 'brain stimulus',
  onClick,
  selected = false,
  isTarget = false,
  status = 'none', // Default to 'none'
  isSubmitted = false,
}) => {
  const getStatusOverlayStyle = (
    status: 'correct' | 'wrong' | 'missed' | 'none',
  ) => {
    if (!isSubmitted) return {}; // No overlay if not submitted
    switch (status) {
      case 'correct':
        return {
          opacity: 0.25,
          backgroundColor: '#57884B',
          boxShadow: '0px 0px 0px 1px #57884B',
          border: '1px solid #57884B',
        }; // Green with opacity
      case 'wrong':
        return {
          opacity: 0.25,
          backgroundColor: '#CE4444',
          border: '1px solid #CE4444',
          boxShadow: '0px 0px 0px 1px #CE4444',
        }; // Red with opacity
      case 'missed':
        return {
          opacity: 0.25,
          backgroundColor: '#ECB54A',
          border: '1px solid #ECB54A',
          boxShadow: '0px 0px 0px 1px #ECB54A',
        }; // Orange with opacity
      default:
        return {};
    }
  };

  const selectableStyles =
    isTarget && !isSubmitted
      ? {}
      : {
          '&:hover': {
            borderColor: 'blue',
            border: selected ? '2px solid blue' : 'none',
          },
        };

  const borderStyle = (status: 'correct' | 'wrong' | 'missed' | 'none') => {
    if ((isTarget || selected) && !isSubmitted) {
      return '2px solid blue';
    }
    if (isSubmitted) {
      switch (status) {
        case 'correct':
          return '2px solid #57884B';
        case 'wrong':
          return '2px solid #CE4444';
        case 'missed':
          return '2px solid #ECB54A';
      }
    }
    return 'none';
  };

  return (
    <ButtonBase onClick={() => onClick && onClick(src)} disabled={isTarget}>
      <Box
        sx={{
          position: 'relative',
          maxWidth: '20%',
          minWidth: '50px',
          m: { xs: 0.25, md: 1 },
          borderRadius: '0px',
          border: borderStyle(status),
          ...selectableStyles,
          '&:hover': {
            borderColor:
              isTarget && !isSubmitted ? 'blue' : borderStyle(status),
            border:
              isTarget && !isSubmitted && selected
                ? '2px solid blue'
                : borderStyle(status),
          },
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ width: '100%', height: 'auto', display: 'block' }}
        />
        {isSubmitted && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '4px',
              ...getStatusOverlayStyle(status),
            }}
          ></Box>
        )}
      </Box>
    </ButtonBase>
  );
};
