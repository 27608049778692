import { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import Estimulo1 from '../assets/estimulos/Estímulo-01.svg';
import Estimulo2 from '../assets/estimulos/Estímulo-02.svg';
import Estimulo3 from '../assets/estimulos/Estímulo-03.svg';
import Estimulo4 from '../assets/estimulos/Estímulo-04.svg';
import Estimulo5 from '../assets/estimulos/Estímulo-05.svg';
import Estimulo6 from '../assets/estimulos/Estímulo-06.svg';
import Estimulo7 from '../assets/estimulos/Estímulo-07.svg';
import Estimulo8 from '../assets/estimulos/Estímulo-08.svg';
import { AssessmentApi } from 'lib/api/assessment';
import { useAssessment } from '../context/assessment.consumer';

type StimulusItem = {
  key: number;
  src: string;
  status?: 'correct' | 'wrong' | 'missed';
};

type TestResults = {
  hits: number;
  omissions: number;
  errors: number;
};

const stimulusArray = [
  Estimulo1,
  Estimulo2,
  Estimulo3,
  Estimulo4,
  Estimulo5,
  Estimulo6,
  Estimulo7,
  Estimulo8,
];

type UseStimulusProps =
  | {
      kind: 'tutorial';
      count: number;
    }
  | {
      kind: 'test';
      expiryTimestamp: Date;
      onExpire: () => void;
    };

export const useStimulus = (props: UseStimulusProps) => {
  let stimulusTotal = props.kind === 'tutorial' ? props.count : 0;
  let correctStimulusPercentage = 30;
  const { analystRequest } = useAssessment();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedStimuli, setSelectedStimuli] = useState<number[]>([]);
  const [stimulusItems, setStimulusItems] = useState<StimulusItem[]>([]);
  const [singleStimulusSrc, setSingleStimulusSrc] = useState<string>('');
  const [testResults, setTestResults] = useState<TestResults>({
    hits: 0,
    omissions: 0,
    errors: 0,
  });
  const { pause, totalSeconds } = useTimer({
    expiryTimestamp: props.kind === 'test' ? props.expiryTimestamp : new Date(),
    onExpire: () => (props.kind === 'test' ? props.onExpire() : null),
    autoStart: props.kind === 'test' ? true : false,
  });

  useEffect(() => {
    setIsLoading(true);
    initializeStimulusItems();
    setIsLoading(false);
  }, []);

  const getAssessmentBase = async () => {
    if (!analystRequest?.assessmentBaseId) return;
    const assessmentBase = await AssessmentApi.getAssessmentBase(
      analystRequest?.assessmentBaseId,
    );
    return assessmentBase;
  };

  const initializeStimulusItems = async () => {
    let assessmentBase = null;
    if (props.kind === 'test') {
      assessmentBase = await getAssessmentBase();
      stimulusTotal = assessmentBase.stimulusTotal;
      correctStimulusPercentage = assessmentBase.correctStimulusPercentage;
    }

    const matchingSrc = getRandomStimulus(); // Get a random stimulus for matching
    setSingleStimulusSrc(matchingSrc);

    const shuffledItems = shuffleArray(
      matchingSrc,
      stimulusTotal,
      correctStimulusPercentage,
    ) as StimulusItem[];

    setStimulusItems(shuffledItems);
  };

  const getRandomStimulus = (excludeSrc = '') => {
    let randomSrc;
    do {
      randomSrc =
        stimulusArray[Math.floor(Math.random() * stimulusArray.length)];
    } while (randomSrc === excludeSrc);
    return randomSrc;
  };

  const shuffleArray = (
    matchingSrc: string,
    stimulusTotal = 200, // Total items
    correctStimulusPercentage = 40, // Percentage for matching items
  ) => {
    // Calculate the number of matching items
    const matchingItemsCount = Math.floor(
      stimulusTotal * (correctStimulusPercentage / 100),
    );

    // Initialize the array with non-matching items
    const distribution: StimulusItem[] = [];
    for (let i = 0; i < stimulusTotal; i++) {
      const nonMatchingSrc = getRandomStimulus(matchingSrc);
      distribution.push({ key: i, src: nonMatchingSrc });
    }

    // Phase 1: Randomly distribute matching items
    let placedCount = 0;
    while (placedCount < matchingItemsCount) {
      const position = Math.floor(Math.random() * stimulusTotal);
      if (!isAdjacentMatching(distribution, position, matchingSrc)) {
        distribution[position].src = matchingSrc;
        placedCount++;
      }
    }
    // Phase 2: Insert any remaining matching items
    let remainingMatchingItems = matchingItemsCount - placedCount;

    if (remainingMatchingItems > 0) {
      for (
        let i = 0;
        i < distribution.length && remainingMatchingItems > 0;
        i++
      ) {
        if (
          distribution[i].src !== matchingSrc &&
          !isAdjacentMatching(distribution, i, matchingSrc)
        ) {
          distribution[i].src = matchingSrc;
          remainingMatchingItems--;
        }
      }
    }

    return distribution;
  };

  // Helper function to check if adjacent items are matching
  function isAdjacentMatching(
    array: StimulusItem[],
    index: number,
    matchingSrc: string,
  ) {
    if (index > 0 && array[index - 1].src === matchingSrc) {
      return true;
    }
    if (index < array.length - 1 && array[index + 1].src === matchingSrc) {
      return true;
    }
    if (array[index].src === matchingSrc) {
      return true;
    }
    return false;
  }

  const handleStimulusSelect = (key: number) => {
    if (!isSubmitted) {
      setSelectedStimuli((prevSelected) =>
        prevSelected.includes(key)
          ? prevSelected.filter((selectedKey) => selectedKey !== key)
          : [...prevSelected, key],
      );
    }
  };

  const updateItemStatus = (
    key: number,
    status: 'correct' | 'wrong' | 'missed',
  ) => {
    setStimulusItems((currentItems) =>
      currentItems.map((item) =>
        item.key === key ? { ...item, status } : item,
      ),
    );
  };

  const handleTestSubmit = () => {
    props.kind === 'test' ? pause() : null;
    const correctStimuliKeys = stimulusItems
      .filter((item) => item.src === singleStimulusSrc)
      .map((item) => item.key);

    let hits = 0,
      errors = 0,
      omissions = correctStimuliKeys.length;
    selectedStimuli.forEach((key) => {
      if (correctStimuliKeys.includes(key)) {
        updateItemStatus(key, 'correct');
        hits++;
        omissions--;
      } else {
        updateItemStatus(key, 'wrong');
        errors++;
      }
      const nonSelectedItems = stimulusItems.filter(
        (item) =>
          item.src === singleStimulusSrc && !selectedStimuli.includes(item.key),
      );
      nonSelectedItems.forEach((item) => {
        updateItemStatus(item.key, 'missed');
      });
    });

    setTestResults({ hits, omissions, errors });
    setIsSubmitted(true);
    return {
      hits,
      omissions,
      errors,
      totalSeconds: props.kind === 'test' ? 200 - totalSeconds : 0,
    };
  };

  const resetTest = () => {
    setIsSubmitted(false);
    setSelectedStimuli([]);
    setTestResults({ hits: 0, omissions: 0, errors: 0 });
    initializeStimulusItems();
  };

  return {
    stimulusItems,
    selectedStimuli,
    handleStimulusSelect,
    singleStimulusSrc,
    handleTestSubmit,
    testResults,
    isSubmitted,
    resetTest,
    isLoading,
    isDisabled: selectedStimuli.length === 0,
  };
};
