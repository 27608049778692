import { InputLabel } from '@mui/material';
import styled from 'styled-components';
import CardBox from 'lib/components/CardBox';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog as BaseDialog } from '@mui/material';

export const ErrorMsg = styled.p`
  color: #d32f2f !important;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
`;

export const Dialog = styled(BaseDialog)`
  .MuiDialog-container .MuiPaper-root {
    padding: 50px !important;
  }

  h3 {
    color: #66717f;
    margin-top: 0;
  }
`;

export const EditButton = styled(EditIcon)`
  color: #999;
  margin-right: 15px;

  :hover {
    cursor: pointer;
    color: #555;
  }
`;

export const FieldRow = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  gap: 20px;

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const BlockTitle = styled.h3`
  font-weight: 500;
  font-size: 16pt;
  line-height: 0;
  margin-top: 35px;
  color: #66717f;
`;

export const BaseInputLabel = styled(InputLabel)`
  background-color: #fff;
  padding: 0 8px !important;
`;

export const FixedLabel = styled.div`
  color: #1976d2;
  font-weight: 400;
  font-size: 10pt;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 3px 5px;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 10px;
  background-color: #fff;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
`;

export const BaseCardBox = styled(CardBox)`
  margin: 50px 0;
  padding-top: 1px;

  h3 {
    color: #66717f;
  }
`;
