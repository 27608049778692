import Stack from '@mui/material/Stack';
import { Stimulus } from 'modules/assessment-execution/components/Stimulus';
import { StimulusContainer } from 'modules/assessment-execution/components/StimulusContainer';
import { useSnackbar } from 'notistack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import { TestPage } from 'modules/assessment-execution/components/TestPage';
import { useAssessment } from '../context/assessment.consumer';
import { useStimulus } from './useStimulus';
import { AssessmentApi } from 'lib/api/assessment';
import React from 'react';
import { Arrow } from '../assets/icons/arrow';
import { Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';

export const ExecutePage = () => {
  const isMobile = window.innerWidth < 620;
  const { enqueueSnackbar } = useSnackbar();
  const { handleNextPage, analystRequest } = useAssessment();

  const time = new Date();
  // 3.20 minutes
  time.setSeconds(time.getSeconds() + 200);
  const {
    handleStimulusSelect,
    handleTestSubmit,
    selectedStimuli,
    singleStimulusSrc,
    stimulusItems,
    isLoading,
    isDisabled,
  } = useStimulus({
    kind: 'test',
    expiryTimestamp: time,
    onExpire: () => {
      enqueueSnackbar('Tempo esgotado.', {
        variant: 'warning',
      });
      handleFinalize({
        finishReason: 'TIMEOUT',
      });
    },
  });

  const [stimuliPerPage, setStimuliPerPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [buttonIsLoading, setButtonIsLoading] = React.useState(false);

  const calculateStimuliPerPage = () => {
    const containerHeight = window.innerHeight * 0.8; // 90% of the screen height
    const stimulusHeight = 60; // Adjust this based on your styling

    const stimuliPerRow = 6;
    const stimuliPerColumn = Math.floor(containerHeight / stimulusHeight);

    const calculatedStimuliPerPage = stimuliPerRow * stimuliPerColumn;

    return Math.max(1, calculatedStimuliPerPage);
  };

  const getCurrentStimuli = () => {
    const startIndex = (currentPage - 1) * stimuliPerPage;
    const endIndex = startIndex + stimuliPerPage;
    return stimulusItems.slice(startIndex, endIndex);
  };

  React.useEffect(() => {
    setStimuliPerPage(calculateStimuliPerPage());
  }, []);

  React.useEffect(() => {
    setTotalPages(Math.ceil(stimulusItems.length / stimuliPerPage));
  }, [stimulusItems, stimuliPerPage]);

  React.useEffect(() => {
    assessmentStarted();
  }, []);

  const assessmentStarted = () => {
    if (!analystRequest) return;
    AssessmentApi.postAssessmentResponse({
      analystRequestId: analystRequest?.id,
      startedAt: new Date().toISOString(),
    });
  };

  // Function to handle click on 'Finalizar'
  const handleFinalize = ({
    finishReason = 'USER',
  }: {
    finishReason: 'USER' | 'TIMEOUT';
  }) => {
    setButtonIsLoading(true);
    const { hits, omissions, errors, totalSeconds } = handleTestSubmit();
    if (!analystRequest) return;
    AssessmentApi.patchAssessmentResponse({
      analystRequestId: analystRequest.id,
      errors,
      hits,
      omissions,
      executionTime: totalSeconds * 1000,
      finishedAt: new Date().toISOString(),
      finishReason,
    })
      .then(() => {
        setButtonIsLoading(false);
        handleNextPage();
        enqueueSnackbar('Teste submetido com sucesso!', {
          variant: 'success',
        });
      })
      .catch((error) => {
        setButtonIsLoading(false);
        enqueueSnackbar('Erro ao submeter a prova.', {
          variant: 'error',
        });
        console.error(error);
      });
  };

  const stimulus = isMobile ? getCurrentStimuli() : stimulusItems;

  return isLoading || stimulusItems.length === 0 ? (
    <Box
      display="flex"
      alignContent="center"
      width="100%"
      justifyContent="center"
      height="100vh"
      m="auto"
      gap={2}
      alignItems="center"
    >
      <LoopIcon
        sx={{
          animation: 'spin 2s linear infinite',
          '@keyframes spin': {
            '0%': {
              transform: 'rotate(360deg)',
            },
            '100%': {
              transform: 'rotate(0deg)',
            },
          },
        }}
      />
      <span>Carregando Teste...</span>
    </Box>
  ) : (
    <TestPage>
      <Stack spacing={8}>
        <Stack
          display="flex"
          alignContent="center"
          width="100%"
          justifyContent="center"
          position="sticky"
          top="0"
          zIndex={1}
          style={{
            background: '#F4F1F1',
          }}
          py={2}
        >
          <Stimulus src={singleStimulusSrc} alt="Estímulo SRC" />
        </Stack>
        <Stack spacing={2}>
          <StimulusContainer backgroundColor="invisible" width="90vw">
            {stimulus.map((item) => (
              <Stimulus
                key={item.key}
                src={item.src}
                alt={`Estímulo ${item.key}`}
                onClick={() => handleStimulusSelect(item.key)}
                selected={selectedStimuli.includes(item.key)} // pass selected prop
              />
            ))}
          </StimulusContainer>
          {isMobile ? (
            <>
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                alignItems="center"
                justifyContent="center"
                position="relative"
                py="10px"
              >
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (_, index) => (
                    <div
                      style={{
                        borderRadius: '50%',
                        width: '12px',
                        height: '12px',
                        backgroundColor:
                          currentPage === index + 1 ? '#231FDE' : '#DBD7D6',
                      }}
                    />
                  ),
                )}
                {totalPages !== currentPage && (
                  <div
                    style={{
                      position: 'absolute',
                      right: '0px',
                    }}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <Arrow />
                  </div>
                )}
              </Box>
              {totalPages === currentPage && (
                <ButtonMain
                  disabled={isDisabled}
                  onClick={() => handleFinalize({ finishReason: 'USER' })}
                  text={'Finalizar Prova'}
                />
              )}
            </>
          ) : (
            <ButtonMain
              loading={buttonIsLoading}
              disabled={isDisabled}
              onClick={() => handleFinalize({ finishReason: 'USER' })}
              text={'Finalizar Prova'}
            />
          )}
        </Stack>
      </Stack>
    </TestPage>
  );
};
