import React, { useEffect } from 'react';
import { ICompany, OpenViewDetailsPropTypes, IElements } from '../../types';
import { MyRequestDetailsPage } from './my-requests-details-page';
import { RequestListPage } from './my-requests-list-page';
import { AnalystReports } from 'modules/analyst-reports';
import { RequestForm } from 'modules/requests/components/RequestForm';
import { Box, Tab, Tabs } from '@mui/material';
import { UserApi } from 'lib/api/user';
import { useAuth } from 'modules/auth/context/auth.consumer';
import { CompanyApi } from 'lib/api/company';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function MyRequestsPageRoot() {
  const [isDetailsView, setIsDetailsView] = React.useState(false);
  const [detailsData, setDetailsData] = React.useState(
    {} as OpenViewDetailsPropTypes,
  );
  const [value, setValue] = React.useState(0);
  const { user } = useAuth();
  const [userSectors, setUserSectors] = React.useState<IElements[]>();
  const [company, setCompany] = React.useState<ICompany>();

  const handleViewTest = (data: OpenViewDetailsPropTypes) => {
    setDetailsData(data);
    setIsDetailsView(true);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fecthData = async () => {
    if (user) {
      const completeUser = await UserApi.getUser(user.id);

      const company = await CompanyApi.getCompany(completeUser.company.id);

      setUserSectors(completeUser.sectors);
      setCompany(company);
    }
  };

  useEffect(() => {
    fecthData();
  }, [user]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Solicitar Avaliações" {...a11yProps(0)} />
          <Tab label="Minhas Solicitações" {...a11yProps(1)} />

          <Tab label="Relatórios" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <RequestForm company={company} sectors={userSectors} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isDetailsView ? (
          <MyRequestDetailsPage
            data={detailsData}
            goToAllRequests={() => setIsDetailsView(false)}
          />
        ) : (
          <RequestListPage onViewDetails={handleViewTest} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AnalystReports />
      </TabPanel>
    </Box>
  );
}
