import React, { useEffect, useState } from 'react';
import { AuthContext } from './auth.context';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  confirmPasswordReset,
  OAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import useBoolean from 'lib/hooks/use-boolean';
import { UserAuthApi } from 'lib/api/user-auth';
import axiosInstance from 'lib/api/api';

export interface AuthProviderInterface {
  loginWithEmailAndPassword(email: string, password: string): Promise<void>;
  loginWithGoogleOrMicrosoft(providerType: string): Promise<void>;
  sendPasswordRecoveryEmail(email: string): Promise<void>;
  confirmPasswordRecovery(code: string, newPassword: string): Promise<void>;
  logout(): Promise<void>;
  user: User | null;
  isInitialLoading: boolean;
}

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  token: string;
  profilePicture?: string;
}

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialLoading, setIsInitialLoading] = useBoolean(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async () => {
      let userFromDb;
      try {
        userFromDb = await UserAuthApi.fetchUserFromDatabase(auth);
      } catch (e: any) {
        if (e.response.data.message === 'User not found') {
          logout();
        }
      }

      if (userFromDb && auth.currentUser) {
        await UserAuthApi.setUserCustomClaims(auth);
        setUser({
          id: userFromDb.id,
          name: userFromDb.name,
          email: userFromDb.email,
          token: await auth.currentUser?.getIdToken(true),
          role: userFromDb.role,
          profilePicture: userFromDb.profilePicture
            ? userFromDb.profilePicture
            : '',
        });
      } else {
        setUser(null);
      }

      setIsInitialLoading.off();
    });

    return () => unsubscribe();
  }, []);

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      await UserAuthApi.fetchUserFromDatabase(auth);
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    const auth = getAuth();
    signOut(auth);
  };

  const sendPasswordRecoveryEmail = async (email: string) => {
    try {
      await axiosInstance.post('auth/reset-password', {
        email,
      });
    } catch (e) {
      throw e;
    }
  };

  const confirmPasswordRecovery = async (code: string, newPassword: string) => {
    const auth = getAuth();
    await confirmPasswordReset(auth, code, newPassword);
  };

  const loginWithGoogleOrMicrosoft = async (providerType: string) => {
    const provider =
      providerType === 'google'
        ? new GoogleAuthProvider()
        : new OAuthProvider('microsoft.com');
    provider.addScope('email');
    const auth = getAuth();

    let userCredential;

    try {
      userCredential = await signInWithPopup(auth, provider);

      await UserAuthApi.fetchUserFromDatabase(auth);
      await UserAuthApi.insertProviderInfo(auth);
    } catch (e: any) {
      if (e.response && e.response.data.message === 'User not found') {
        if (userCredential) {
          const token = await userCredential.user.getIdToken();
          await UserAuthApi.removeUserFromFirebase(
            userCredential.user.uid,
            token,
          );
        }
      }

      throw e;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loginWithEmailAndPassword,
        loginWithGoogleOrMicrosoft,
        user,
        logout,
        sendPasswordRecoveryEmail,
        confirmPasswordRecovery,
        isInitialLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
