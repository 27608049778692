import React from 'react';
import Stack from '@mui/material/Stack';

interface StimulusContainerProps {
  backgroundColor: string;
  width?: string;
}

export const StimulusContainer: React.FC<StimulusContainerProps> = ({
  children,
  backgroundColor,
  width,
}) => {
  return (
    <Stack
      component="div"
      direction="row"
      sx={{
        width: width,
        alignSelf: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: backgroundColor,
      }}
      flexWrap={'wrap'}
      justifyContent={'center'}
    >
      {children}
    </Stack>
  );
};
