import React, { useEffect, useState } from 'react';
import { CompanyApi } from 'lib/api/company';
import {Button, CircularProgress, Pagination, Stack} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomizedTable from 'modules/companies/components/Table';
import EditButton from 'lib/components/EditButton';
import DeleteButton from 'lib/components/DeleteButton';
import Container from '@mui/material/Container';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { CompanyForm } from 'modules/companies/components/CompanyForm';
import { SectorApi } from 'lib/api/sector';
import { JobTitleApi } from 'lib/api/job-title';
import { UserApi } from 'lib/api/user';
import { InstrumentApi } from 'lib/api/instrument';

import { ICompany, ITableBody, ISnackBarSettings, IElements } from './models';
import { BaseCardBox, Header, BaseTextField, BaseDialog } from './style';
import { ICreateCompany } from 'modules/companies/components/CompanyForm/models';
import axiosInstance from 'lib/api/api';
import { EditCompanyDTO } from 'lib/api/company.protocols';
import {useSnackbar} from "notistack";
import {useUser} from "../../../auth/context/auth.consumer";
import {FileApi} from "../../../../lib/api/file";
import {RoleType} from "../../../auth/common/role";
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import Tooltip from "@mui/material/Tooltip";
import IosShareIcon from "@mui/icons-material/IosShare";
const CompaniesPage = () => {
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [companies, setCompanies] = useState<ICompany[]>();
  const [sectors, setSectors] = useState<IElements[]>();
  const [consultants, setConsultants] = useState<IElements[]>();
  const [jobTitles, setJobTitles] = useState<IElements[]>();
  const [instruments, setInstruments] = useState<IElements[]>();
  const [search, setSearch] = useState<string>('');
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [open, setOpen] = useState(false);
  const [currentId, setcurrentId] = useState<string>('');
  const [isEdit, setIsEdit] = useState(false);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);

  const tableHead = ['Nome da Empresa', 'Logotipo', 'Consultores', 'Ações'];

  const [excelLoading, setExcelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();

  const getCompaniesPaginated = async () => {
    setTableBody([]);
    const response = await CompanyApi.searchCompanies(search, page);
    setCompanies(response.entities);
    setPageCount(response.meta.pageCount);
  };

  const getSectors = async () => {
    const response = await SectorApi.getSectors();
    setSectors(response);
  };

  const getJobTitles = async () => {
    const response = await JobTitleApi.getJobTitles();
    setJobTitles(response);
  };

  const getConsultants = async () => {
    const response = await UserApi.getUsers();
    const onlyConsultants = response.filter(
      (user) => user.role === 'CONSULTOR',
    );
    setConsultants(onlyConsultants);
  };

  const getInstruments = async () => {
    const response = await InstrumentApi.getInstruments();
    setInstruments(response);
  };

  useEffect(() => {
    getSectors();
    getJobTitles();
    getConsultants();
    getInstruments();
  }, []);

  useEffect(() => {
    getCompaniesPaginated();
  }, [search, page]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleSaveCompanyModal = (dataToSend: ICreateCompany) => {
    handleCloseModalForm();
    if (isEdit) {
      handleEdit(dataToSend);
      return;
    } else {
      handleCreate(dataToSend);
    }
  };

  const handleCreate = async (dataToSend: ICreateCompany) => {
    try {
      await CompanyApi.createCompany(dataToSend);
      setSnackBarSettings({
        show: true,
        message: 'Empresa criada com sucesso!',
        severity: 'success',
      });
      setLogoUrl('');
      getCompaniesPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await CompanyApi.deleteCompany(id);
      setSnackBarSettings({
        show: true,
        message: 'Empresa excluida com sucesso!',
        severity: 'success',
      });
      getCompaniesPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.response.data.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleEdit = async (dataToSend: EditCompanyDTO) => {
    try {
      await CompanyApi.editCompany(currentId, dataToSend);
      setSnackBarSettings({
        show: true,
        message: 'Empresa editada com sucesso!',
        severity: 'success',
      });
      setLogoUrl('');
      getCompaniesPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  function editCallback(isOpen: boolean) {
    setOpen(isOpen);
    setIsEdit(true);
  }

  const handleLogo = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      const formData = new FormData();

      formData.append('file', e.target.files[0]);

      try {
        axiosInstance
          .post('/file-upload/upload/image', formData)
          .then((response) => setLogoUrl(response.data.link));
      } catch (err: any) {
        setSnackBarSettings({
          show: true,
          message: err.message,
          severity: 'error',
        });
        resetSnackBar();
      }
    }
  };

  const mountTableBody = () => {
    companies?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          name: item.name,
          logo: item.logo,
          consultants: item.consultants,
          actions: (
            <div>
              <EditButton
                item={item}
                currentItem={setcurrentId}
                callback={editCallback}
              />
              <DeleteButton id={item.id} callback={handleDelete} />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [companies]);

  const handleCloseModalForm = () => {
    setOpen(false);
  };

  const getExportExcelCompanies = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportCompanies();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar as empresas', { variant: 'error', autoHideDuration: 6000 });
    } finally {
      setExcelLoading(false);
    }
  };

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Cadastro de Empresas</h3>
          <Button
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
            variant="contained"
            startIcon={<AddBoxIcon />}
          >
            Novo Cadastro
          </Button>
        </Header>

        <BaseTextField
          onChange={(event) => {
            page !== 1 && setPage(1);
            setSearch(event.target.value);
          }}
          label="Realizar busca"
          variant="outlined"
        />
      </BaseCardBox>

      {
          user && user.role === RoleType.ADMIN && (
              <BaseExportExcelContainer>
                <div>
                  <Tooltip title="Exportar em excel todos os registros de empresas">
                    <Button
                        variant="contained"
                        startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                        }
                        onClick={() => getExportExcelCompanies()}
                        disabled={excelLoading}
                    >
                      Exportar em excel
                    </Button>
                  </Tooltip>
                </div>
              </BaseExportExcelContainer>
          )
      }

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />

      <BaseDialog
        open={open}
        onClose={handleCloseModalForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CompanyForm
          isEdit={isEdit}
          setOpen={setOpen}
          handleLogo={handleLogo}
          logoUrl={logoUrl}
          setLogoUrl={setLogoUrl}
          handleSaveCompanyModal={handleSaveCompanyModal}
          companies={companies}
          sectors={sectors ? sectors : []}
          consultants={consultants ? consultants : []}
          jobTitles={jobTitles ? jobTitles : []}
          instruments={instruments ? instruments : []}
          currentId={currentId}
        />
      </BaseDialog>

      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}

      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { CompaniesPage };
