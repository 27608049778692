import styled from 'styled-components';
import img from 'lib/assets/images/login-background.jpg';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;

  h1 {
    color: #10409c;
    margin: 50px 0 0 100px;
  }

  .leftSide {
    width: 55%;
    height: 100vh;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .rightSide {
    width: 45%;
    height: 100vh;
    background-color: #f4f9fe;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 1px #555;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  gap: 30px;

  h3 {
    margin: 0;
    line-height: 0;
    color: #10409c;
  }

  p {
    margin: 0;
    color: #238fef;
  }

  button {
    width: 100%;
  }
`;

export const ErrorMsg = styled.p`
  color: #fb5454 !important;
  font-size: 12pt;
`;

export const StyledLink = styled(Link)`
  color: #10409c;
  text-decoration: none;
  cursor: pointer;
  align-self: end;
  &:hover {
    color: #238fef;
  }
`;

export const CenteredParagraph = styled.p`
  text-align: center;
  color: #238fef;
  padding-top: 10px;
`;

export const HorizontalBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  padding-top: 10px;
`;

export const CircularLoadingBox = styled.div`
  display: flex;
  justify-content: center;
`;
