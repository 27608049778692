import styled from 'styled-components';

export const Container = styled.section<{ height?: string; width?: string }>`
  background: #ffffff;
  box-shadow: 2px 2px 8px 2px rgba(16, 64, 156, 0.25);
  border-radius: 10px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 40px 30px;
`;
