import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from 'modules/auth/context/auth.provider';
import { Router } from 'router/router';
import { initializeApp } from 'firebase/app';
import { AppContainer, SystemMenu, Page } from 'lib/components/AppContainer';
import { AuthRouter } from 'modules/auth/auth.router';
import { RoleType } from 'modules/auth/common/role';
import { firebaseConfig } from 'services/firebaseConfig';
import { ThemeProvider } from '@mui/material/styles';
import CurrentTheme from './themes';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import AssessmentExecutionRoutes from 'modules/assessment-execution/router';

initializeApp(firebaseConfig);

const pages: Page[] = [
  {
    label: 'Cadastro de Setores',
    route: '/setores',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cadastro de Instrumentos',
    route: '/instrumentos',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cadastro de Cargos',
    route: '/cargos',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cadastro de Empresas',
    route: '/empresas',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cadastro de Usuários',
    route: '/usuarios',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'Cadastro de Arquivos',
    route: '/arquivos',
    roles: [RoleType.ADMIN],
  },
];

const menus: SystemMenu[] = [
  {
    label: 'GERENCIAMENTO E CADASTROS',
    roles: [RoleType.ADMIN],
  },
  {
    label: 'AVALIAÇÕES',
    roles: [RoleType.ADMIN, RoleType.CONSULTOR, RoleType.ANALISTA],
  },
  {
    label: 'ARQUIVOS',
    roles: [RoleType.CONSULTOR],
  },
  {
    label: 'PROVAS',
    roles: [RoleType.CONSULTOR, RoleType.ADMIN],
  },
];

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={CurrentTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AuthProvider>
            <AuthRouter>
              <Routes>
                <Route
                  path="/assessment-execution/*"
                  element={<AssessmentExecutionRoutes />}
                />
                <Route
                  path="*"
                  element={
                    <>
                      <AppContainer pages={pages} menus={menus}>
                        <Router />
                      </AppContainer>
                    </>
                  }
                />
              </Routes>
            </AuthRouter>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
