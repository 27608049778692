import { createContext } from 'react';
import { AssessmentContextProps } from './assessment.provider';

export const AssessmentContext = createContext<AssessmentContextProps>({
  analystRequest: null,
  setAnalystRequest: () => {
    return;
  },
  candidate: null,
  setCandidate: () => {
    return;
  },
  pageIndex: 0,
  handleNextPage: () => {
    return;
  },
  handlePreviousPage: () => {
    return;
  },
});
