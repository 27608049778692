import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { useAssessment } from 'modules/assessment-execution/context/assessment.consumer';
import { AssessmentApi } from 'lib/api/assessment';
import { useSnackbar } from 'notistack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import StyledBox from '../components/StyledBox'; // import the new StyledBox component

export const IdentificationPage = () => {
  const [partialCpf, setPartialCpf] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { analystRequest, setCandidate, handleNextPage } = useAssessment();
  const [loading, setLoading] = React.useState(false);

  async function getUserFromCPF() {
    if (!analystRequest) {
      enqueueSnackbar('Erro ao carregar a solicitação.', { variant: 'error' });
      return;
    }
    setLoading(true);

    const onlyNumbersString = /^\d+$/.test(partialCpf);
    if (!onlyNumbersString) {
      enqueueSnackbar(
        'Você deve digitar apenas os 5 primeiros números, sem pontos e espaços.',
        { variant: 'error' },
      );
      setLoading(false);
    }

    if (partialCpf.length === 5 && onlyNumbersString) {
      AssessmentApi.getCandidate(analystRequest.id, partialCpf)
        .then((candidate) => {
          setCandidate(candidate);
          handleNextPage();
        })
        .catch((error) => {
          enqueueSnackbar('CPF não encontrado', { variant: 'error' });
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      enqueueSnackbar('CPF inválido', { variant: 'error' });
      setLoading(false);
    }
  }

  function handler() {
    getUserFromCPF();
  }

  return (
    <DefaultPage>
      <Typography variant="h1">{'Identifique-se'}</Typography>
      <StyledBox>
        <Typography variant="body1">
          Digite os 5 primeiros números do seu CPF (apenas os números):
        </Typography>
        <TextField
          fullWidth
          id="outlined-basic"
          placeholder={'xxxxx'}
          variant="outlined"
          value={partialCpf}
          onChange={(event) => setPartialCpf(event.target.value)}
          sx={{ backgroundColor: 'white', marginBottom: '20px' }}
          inputProps={{ maxLength: 5 }}
        />
      </StyledBox>
      <ButtonMain
        onClick={handler}
        text={loading ? 'Carregando...' : 'Continuar'}
        disabled={loading}
      ></ButtonMain>
    </DefaultPage>
  );
};
