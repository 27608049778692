import styled from 'styled-components';

export const FieldRow = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
`;

export const FixedLabel = styled.div`
  color: #1976d2;
  font-weight: 400;
  font-size: 10pt;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 3px 5px;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 10px;
  background-color: #fff;
`;
