import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { LoginPage } from './pages/login';
import { ForgotPasswordPage } from './pages/forgot-password';
import { NewPasswordPage } from './pages/forgot-password/new-password.page';
import { useAuth } from './context/auth.consumer';
import { Typography } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { AssessmentExecutionRoutes } from 'modules/assessment-execution/router';

//Styled components
const pulse = keyframes`
0% {
  background: #104d89;
}
50% {
  background: #1976d2;
}
100% {
  background: #104d89;
}
`;

const Centralizer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: #000000;
  animation: ${pulse} 2s linear infinite;
`;

export const AuthRouter: React.FC = ({ children }) => {
  const { user, isInitialLoading } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  if (isInitialLoading) {
    return (
      <Centralizer>
        <Typography
          color="white"
          align="center"
          gutterBottom
          paragraph
          variant="h2"
        >
          MS
        </Typography>
      </Centralizer>
    );
  }

  if (!user) {
    return (
      <Routes>
        <Route
          path="/assessment-execution/*"
          element={<AssessmentExecutionRoutes />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/esqueci-senha" element={<ForgotPasswordPage />} />
        <Route path="/nova-senha" element={<NewPasswordPage />} />
        <Route
          path="*"
          element={<Navigate to={'/login'} state={{ from: location }} />}
        />
      </Routes>
    );
  }

  return (
    <>
      {children}{' '}
      {location.state?.from &&
        location.state?.from !== '/' &&
        navigate(location.state.from)}{' '}
    </>
  );
};
