import styled from 'styled-components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const BaseDeleteForeverIcon = styled(DeleteForeverIcon)`
  color: #999;
  margin-right: 15px;

  :hover {
    cursor: pointer;
    color: #555;
  }
`;

export const Container = styled.div`
  padding: 30px 20px 30px 0;
`;
