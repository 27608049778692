import styled from 'styled-components';
import guberlogo from 'lib/assets/images/logo-gubertech.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1976d2;
  padding: 15px 0;
`;

export const Box = styled.div`
  width: 815px;
  display: flex;
  align-items: center;

  div:first-child {
    padding-right: 50px;
    margin-right: 50px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }

  span {
    color: #efefef;
    font-size: 10pt;
    margin-left: 45px;
  }

  p {
    color: #efefef;
    font-size: 12pt;
  }
`;

export const GuberLogo = styled.div`
  width: 200px;
  height: 35px;
  background-image: url(${guberlogo});
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 5px;
`;
