export function Arrow() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3062 15.75H0V12.25H21.3062L11.5063 2.45L14 0L28 14L14 28L11.5063 25.55L21.3062 15.75Z"
        fill="#231FDE"
      />
    </svg>
  );
}
