import {
  CompanyDTO,
  CreateCompanyDTO,
  EditCompanyDTO,
} from './company.protocols';
import axiosInstance from './api';
import { PageDto } from 'lib/common/dto/page.dto';

class CompanyApi {
  static async createCompany(
    createCompanyDTO: CreateCompanyDTO,
  ): Promise<CompanyDTO> {
    const response: CompanyDTO = await axiosInstance
      .post('/company', createCompanyDTO)
      .then((response: any) => response.data);

    return response;
  }

  static async editCompany(
    id: string,
    editedCompanyDTO: EditCompanyDTO,
  ): Promise<CompanyDTO> {
    const response: CompanyDTO = await axiosInstance
      .put(`/company/${id}`, editedCompanyDTO)
      .then((response) => response.data);

    return response;
  }

  static async deleteCompany(id: string): Promise<CompanyDTO> {
    const response: CompanyDTO = await axiosInstance
      .delete(`/company/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async getCompanies(): Promise<CompanyDTO[]> {
    const response: CompanyDTO[] = await axiosInstance
      .get('/company/all')
      .then((response) => response.data);
    return response;
  }

  static async getCompany(id: string | undefined): Promise<CompanyDTO> {
    const response: CompanyDTO = await axiosInstance
      .get(`/company/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async searchCompanies(name: string, page?: number): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/company/search?name=${name}&page=${page}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }
}

export { CompanyApi };
