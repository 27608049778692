import { PageDto } from 'lib/common/dto/page.dto';
import axiosInstance from './api';
import { JobTitleDTO } from './job-title.protocols';

class JobTitleApi {
  static async createJobTitle(
    createJobTitleDTO: JobTitleDTO,
  ): Promise<JobTitleDTO> {
    const response: JobTitleDTO = await axiosInstance
      .post('/job-title', createJobTitleDTO)
      .then((response) => response.data);

    return response;
  }

  static async editJobTitle(
    id: string,
    editedJobTitleDTO: JobTitleDTO,
  ): Promise<JobTitleDTO> {
    const response: JobTitleDTO = await axiosInstance
      .put(`/job-title/${id}`, editedJobTitleDTO)
      .then((response) => response.data);

    return response;
  }

  static async deleteJobTitle(id: string): Promise<JobTitleDTO> {
    const response: JobTitleDTO = await axiosInstance
      .delete(`/job-title/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async getJobTitles(): Promise<JobTitleDTO[]> {
    const response: JobTitleDTO[] = await axiosInstance
      .get('/job-title/all')
      .then((response) => response.data);

    return response;
  }

  static async searchJobTitles(
    objectToSearch: string,
    page: number,
  ): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/job-title/search?name=${objectToSearch}&page=${page}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }
}

export { JobTitleApi };
