import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';

export const BaseTableContainer = styled(TableContainer)`
  margin-bottom: 50px;

  table {
    border: 1px solid #dbdbdb;
  }

  table thead tr th {
    background-color: #d1e8ff !important;
    color: #555 !important;
    text-transform: uppercase;
    font-weight: 600;
  }

  table thead tr th:last-child {
    text-align: right;
  }

  table tbody td {
    padding: 10px 16px;
  }

  table tbody td svg {
    font-size: 14pt;
  }

  table tbody td img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  table tbody td p {
    font-size: 11pt;
    line-height: 0.2;
  }
`;
