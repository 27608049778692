import { useState } from 'react';
import Rating from '@mui/material/Rating';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import Box from '@mui/material/Box';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useAssessment } from '../context/assessment.consumer';
import { AssessmentApi } from 'lib/api/assessment';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';

export const AssessmentFeedback = ({}) => {
  const [rating, setRating] = useState<number | null>(0);
  const [comment, setComment] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const { analystRequest } = useAssessment();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    if (!analystRequest) {
      enqueueSnackbar('Erro ao carregar a solicitação.', { variant: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      await AssessmentApi.postAssessmentFeedback({
        analystRequestId: analystRequest.id,
        rating: Number(rating),
        comment,
      });
      enqueueSnackbar('Feedback submetido com sucesso!', {
        variant: 'success',
      });
      setFeedbackSubmitted(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao submeter o formuário. Tente novamente.', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return !feedbackSubmitted ? (
    <CardContent sx={{ padding: 0 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 2,
        }}
      >
        <Rating
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          sx={{
            gap: '0.8em',
            color: 'rgba(35, 143, 239, 1)',
            fontSize: '2rem',
          }}
          emptyIcon={
            <StarBorderIcon
              style={{ color: 'rgba(35, 143, 239, 1)', fontSize: '2rem' }}
            />
          }
        />
      </Box>
      <Stack spacing={2}>
        <TextField
          variant="outlined"
          placeholder="Escreva um comentário (opcional)"
          multiline
          rows={4}
          sx={{
            borderRadius: '4px',
            background: '#F4F9FE',
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0px',
            },
            '& .MuiOutlinedInput-root': {
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                borderBottom: '1px solid #797472',
                boxShadow: '0px 1px 1px #797472',
              },
            },
          }}
          onChange={(event) => setComment(event.target.value)}
        />
        <ButtonMain
          text={'Enviar'}
          onClick={handleSubmit}
          disabled={isLoading}
        ></ButtonMain>
      </Stack>
    </CardContent>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 2,
      }}
    >
      <Typography sx={{ color: '#231FDE' }}>Feedback Enviado!</Typography>
      <Typography sx={{ color: '#231FDE' }}>
        Agradecemos a sua colaboração.
      </Typography>
    </Box>
  );
};
