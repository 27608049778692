import React from 'react';
import { TopBar } from '../TopBar';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import { RoleType, userRole } from 'modules/auth/common/role';
import { useUser } from 'modules/auth/context/auth.consumer';
import { User } from 'modules/auth/context/auth.provider';
import { Footer } from '../Footer';

interface AppContainer {
  children: React.ReactNode;
}

interface AppContainer {
  pages: Page[];
  menus: SystemMenu[];
}

export interface Page {
  label: string;
  route: string;
  roles: RoleType[];
}

export interface SystemMenu {
  label: string;
  roles: RoleType[];
}

export const AppContainer: React.FC<AppContainer> = ({
  children,
  pages,
  menus,
}) => {
  const user = useUser();
  const role = userRole(user as User);

  const availablePages = pages.filter((page) => page.roles.includes(role));

  const availableMenus = menus.filter((menu: SystemMenu) =>
    menu.roles.includes(role),
  );

  return (
    <React.Fragment>
      <TopBar pages={availablePages} menus={availableMenus} />
      <Container maxWidth="md" sx={{ my: 2 }}>
        {children}
      </Container>
      <Outlet />
      <Footer />
    </React.Fragment>
  );
};
