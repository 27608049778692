import React from 'react';

import { Container } from './style';

function Presentation() {
  return (
    <React.Fragment>
      <Container></Container>
    </React.Fragment>
  );
}

export { Presentation };
