import { Container, Box, GuberLogo } from './style';

const Footer = () => {
  return (
    <Container>
      <Box>
        <div>
          <span>developed by:</span>
          <GuberLogo />
        </div>
        <p>SisWEB MS 4.0</p>
      </Box>
    </Container>
  );
};

export { Footer };
