import React, { useEffect, useState } from 'react';

import { SectorApi } from 'lib/api/sector';
import {Button, CircularProgress, Pagination, Stack, TextField} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CustomizedTable from 'modules/sectors/components/Table';
import EditButton from 'lib/components/EditButton';
import DeleteButton from 'lib/components/DeleteButton';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Container from '@mui/material/Container';
import CustomizedSnackbars from 'lib/components/SnackBar';
import Tooltip from '@mui/material/Tooltip';

import {
  BaseCardBox,
  Header,
  BaseTextField,
  BaseDialog,
  BaseGetAppIcon, BaseExportExcelContainer,
} from './style';
import axiosInstance from 'lib/api/api';
import IosShareIcon from "@mui/icons-material/IosShare";
import {FileApi} from "../../../../lib/api/file";
import {useSnackbar} from "notistack";
import {RoleType, userRole} from "../../../auth/common/role";
import {useUser} from "../../../auth/context/auth.consumer";

interface ISector {
  id?: string;
  name: string;
}

interface ITableBody {
  name: string;
  actions: JSX.Element;
}

interface ISnackBarSettings {
  show: boolean;
  message?: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
}

const SectorsPage = () => {
  const [sectors, setSectors] = useState<ISector[]>();
  const [search, setSearch] = useState<string>('');
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [open, setOpen] = useState(false);
  const [currentId, setcurrentId] = useState<string>('');
  const [sector, setSector] = useState<ISector>();
  const [isEdit, setIsEdit] = useState(false);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);

  const [excelLoading, setExcelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();

  const tableHead = ['Setor', 'Ações'];

  const getSectorsPaginated = async () => {
    const response = await SectorApi.searchSectors(search, page);
    setSectors(response.entities);
    setPageCount(response.meta.pageCount);
  };

  useEffect(() => {
    getSectorsPaginated();
  }, [page, search]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleSaveSectorModal = (e: React.FormEvent) => {
    e.preventDefault();
    handleCloseModalForm();
    if (isEdit) {
      handleEdit();
      getSectorsPaginated();
      return;
    } else {
      handleCreate();
      getSectorsPaginated();
    }
  };

  const handleCreate = async () => {
    try {
      if (sector) {
        await SectorApi.createSector(sector);
        setSnackBarSettings({
          show: true,
          message: 'Setor criado com sucesso!',
          severity: 'success',
        });
        getSectorsPaginated();
        resetSnackBar();
      }
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await SectorApi.deleteSector(id);
      setSnackBarSettings({
        show: true,
        message: 'Setor excluido com sucesso!',
        severity: 'success',
      });
      getSectorsPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.response.data.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleEdit = async () => {
    try {
      if (sector) {
        await SectorApi.editSector(currentId, sector);
        setSnackBarSettings({
          show: true,
          message: 'Setor editado com sucesso!',
          severity: 'success',
        });
        getSectorsPaginated();
        resetSnackBar();
      }
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  function editCallback(isOpen: boolean, sectorName: string) {
    setSector({ name: sectorName });
    setOpen(isOpen);
    setIsEdit(true);
  }

  const handleFile = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      const formData = new FormData();

      formData.append('file', e.target.files[0]);

      try {
        axiosInstance.post(
          '/file-upload/upload/entity-spreadsheet?sheetEntity=SECTOR',
          formData,
        );
        setTimeout(() => {
          getSectorsPaginated();
        }, 500);
        setSnackBarSettings({
          show: true,
          message: 'Arquivo importado com sucesso!',
          severity: 'success',
        });
        resetSnackBar();
      } catch (err: any) {
        setSnackBarSettings({
          show: true,
          message: err.message,
          severity: 'error',
        });
        resetSnackBar();
      }
    }
  };

  const mountTableBody = () => {
    sectors?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          name: item.name,
          actions: (
            <div>
              <EditButton
                item={item}
                currentItem={setcurrentId}
                callback={editCallback}
              />
              <DeleteButton id={item.id} callback={handleDelete} />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [sectors]);

  const handleCloseModalForm = () => {
    setOpen(false);
  };

  const getExportExcelSectors = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportSectors();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar os setores', { variant: 'error', autoHideDuration: 6000 });
    } finally {
      setExcelLoading(false);
    }
  };

  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Cadastro de Setores</h3>
          <div>
            <Tooltip title="Baixar planilha de importação">
              <a href="https://msbackend.blob.core.windows.net/planilha-entidade/setores.xlsx">
                <BaseGetAppIcon />
              </a>
            </Tooltip>

            <input id="upload" type="file" onChange={(e) => handleFile(e)} />
            <label htmlFor="upload">
              <PostAddIcon />
              Importar
            </label>

            <Button
              onClick={() => {
                setSector({ name: '' });
                setIsEdit(false);
                setOpen(true);
              }}
              variant="contained"
              startIcon={<AddBoxIcon />}
            >
              Novo Cadastro
            </Button>
          </div>
        </Header>

        <BaseTextField
          onChange={(event) => {
            page !== 1 && setPage(1);
            setSearch(event.target.value);
          }}
          label="Realizar busca"
          variant="outlined"
        />
      </BaseCardBox>

      {
        user && user.role === RoleType.ADMIN && (
              <BaseExportExcelContainer>
                <div>
                  <Tooltip title="Exportar em excel todos os registros de setores">
                    <Button
                        variant="contained"
                        startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                        }
                        onClick={() => getExportExcelSectors()}
                        disabled={excelLoading}
                    >
                      Exportar em excel
                    </Button>
                  </Tooltip>
                </div>
              </BaseExportExcelContainer>
          )
      }

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />

      <BaseDialog
        open={open}
        onClose={handleCloseModalForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={(e) => handleSaveSectorModal(e)}>
          {!isEdit ? (
            <DialogTitle id="alert-dialog-title">{'NOVO SETOR'}</DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-title">{'EDITAR SETOR'}</DialogTitle>
          )}

          <TextField
            required
            onChange={(event) =>
              setSector((prev) => ({ ...prev, name: event.target.value }))
            }
            label="Nome do setor"
            variant="outlined"
            value={sector?.name}
          />
          <DialogActions>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCloseModalForm}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="success" type="submit" autoFocus>
              Salvar
            </Button>
          </DialogActions>
        </form>
      </BaseDialog>

      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { SectorsPage };
