import { BaseEditIcon } from './style';

interface IEditButton {
  item: any;
  currentItem: any;
  callback: (isOpen: boolean, sectorName: string) => void;
  getUser?: (id: string | undefined) => Promise<void>;
}

export default function EditButton({
  item,
  currentItem,
  callback,
  getUser,
}: IEditButton): JSX.Element {
  return (
    <BaseEditIcon
      onClick={async () => {
        if (getUser) {
          await getUser(item?.id);
        }
        currentItem(item?.id);
        callback(true, item?.name);
      }}
    />
  );
}
