import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { Logo, BaseAppBar } from './style';
import { useAuth } from 'modules/auth/context/auth.consumer';
import { SystemMenu, Page } from '../AppContainer';

interface TopbarProps {
  pages: Page[];
  menus: SystemMenu[];
}

function TopBar({ pages, menus }: TopbarProps) {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <BaseAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <Logo></Logo>
          </Link>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              gap: '30px',
              paddingLeft: '100px',
            }}
          >
            {menus.map((menu, index) => {
              return (
                <React.Fragment key={index}>
                  {menu.label === 'GERENCIAMENTO E CADASTROS' && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        {...bindTrigger(popupState)}
                        sx={{ my: 2, fontSize: '12pt' }}
                        key={menu.label}
                      >
                        {menu.label}
                      </Button>
                      <Menu className="topBar_menu" {...bindMenu(popupState)}>
                        {pages.map((page) => {
                          return (
                            <MenuItem
                              key={page.label}
                              component={Link}
                              to={page.route}
                              onClick={popupState.close}
                            >
                              {page.label}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}

                  {menu.label === 'AVALIAÇÕES' && (
                    <Button
                      key={menu.label}
                      className="filesButton"
                      onClick={() => navigate('/solicitacoes')}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        fontSize: '12pt',
                      }}
                    >
                      {menu.label}
                    </Button>
                  )}

                  {menu.label === 'ARQUIVOS' && (
                    <Button
                      key={menu.label}
                      className="filesButton"
                      onClick={() => navigate('/arquivos')}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        fontSize: '12pt',
                      }}
                    >
                      {menu.label}
                    </Button>
                  )}
                  {menu.label === 'PROVAS' && (
                    <Button
                      key={menu.label}
                      className="filesButton"
                      onClick={() => navigate('/provas')}
                      sx={{
                        my: 2,
                        color: 'white',
                        display: 'block',
                        fontSize: '12pt',
                      }}
                    >
                      {menu.label}
                    </Button>
                  )}
                </React.Fragment>
              );
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <div className="userTopInformations">
              <Tooltip title="Perfil">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={`${user?.name}`}
                    src={`${user?.profilePicture}`}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key="1" onClick={handleCloseUserMenu}>
                  <Typography textAlign="center" onClick={logout}>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
              <p>
                acesso: <strong>{user?.role.toLowerCase()}</strong>
                <br />
                Olá, <strong>{user?.name}</strong>!
              </p>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </BaseAppBar>
  );
}

export { TopBar };
