import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Button, CircularProgress, Pagination, Stack } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ISnackBarSettings } from 'lib/helpers/snackbar-interface';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { FilesForm } from 'modules/admin-files/components/FilesForm';
import DownloadIcon from '@mui/icons-material/Download';
import CustomizedTable from 'modules/admin-files/components/Table';
import { FileApi } from 'lib/api/file';
import EditButton from 'lib/components/EditButton';
import DeleteButton from 'lib/components/DeleteButton';
import {useAuth, useUser} from 'modules/auth/context/auth.consumer';
import { BaseCardBox, Header, BaseTextField, BaseDialog } from './style';
import { FileDto, ReportType } from 'lib/api/file.protocols';
import { CompanyApi } from 'lib/api/company';
import { CompanyDTO } from 'lib/api/company.protocols';
import { useSnackbar } from 'notistack';
import {RoleType} from "../../../auth/common/role";
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import Tooltip from "@mui/material/Tooltip";
import IosShareIcon from "@mui/icons-material/IosShare";

interface IFiles {
  fileUrl: string;
  fileName: string;
  azureFileName: string;
}

interface IElements {
  id?: string;
  name: string;
}

interface ITableBody {
  name: string;
  company: string;
  sectors: IElements[];
  user?: string;
  actions: JSX.Element;
}

function AdmFilesPage() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [file, setFile] = useState<FileDto>();
  const [files, setFiles] = useState<IFiles[]>([]);
  const [currentFiles, setCurrentFiles] = useState<FileDto[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [companies, setCompanies] = useState<CompanyDTO[]>([]);
  const [currentFileId, setCurrentFileId] = useState('');
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const tableHead = ['Arquivo', 'Empresa', 'Setores', 'Usuário', 'Ações'];

  const [excelLoading, setExcelLoading] = useState(false);
  const user = useUser();

  const getCompanies = async () => {
    const response = await CompanyApi.getCompanies();
    setCompanies(response);
  };

  const getFilesPaginated = async () => {
    const response = await FileApi.searchFiles(page, search);
    setCurrentFiles(response.entities);
    setPageCount(response.meta.pageCount);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getFilesPaginated();
  }, [search, page]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleCloseModalForm = () => {
    setOpen(false);
    setSectors([]);
    setIsEdit(false);
  };

  const handleDeleteFile = async (fileId: string) => {
    try {
      await FileApi.deleteFile(fileId);

      getFilesPaginated();
      setSnackBarSettings({
        show: true,
        message: 'Arquivo excluído com sucesso!',
        severity: 'success',
      });
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  function editCallback(isOpen: boolean) {
    setOpen(isOpen);
    setIsEdit(true);
  }

  const getFile = async (id: string | undefined) => {
    if (id) {
      const response = await FileApi.getFile(id);
      setFile(response);
    }
  };

  const getReportUrl = async (
    azureFileName: string,
    reportUser: IElements | undefined,
  ) => {
    try {
      const reportType = reportUser
        ? ReportType.CONSULTANT_REPORT
        : ReportType.ASSESSMENT_REPORT;

      const url = await FileApi.getReportUrl({ azureFileName, reportType });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Erro ao gerar url do arquivo', { variant: 'error' });
    } finally {
    }
  };

  const mountTableBody = () => {
    currentFiles?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          name: item.fileName,
          company: item.company.name,
          sectors: item.sectors,
          user: item.user?.name ?? 'Sistema MS',
          actions:
            user?.role === 'ADMIN' ? (
              <div className="actions">
                <div
                  className="actions"
                  onClick={() => getReportUrl(item.azureFileName, item.user)}
                >
                  <DownloadIcon />
                </div>
                <EditButton
                  item={item}
                  currentItem={setCurrentFileId}
                  callback={editCallback}
                  getUser={getFile}
                />
                <DeleteButton id={item.id} callback={handleDeleteFile} />
              </div>
            ) : (
              <div
                className="actions"
                onClick={() => getReportUrl(item.azureFileName, item.user)}
              >
                <DownloadIcon />
              </div>
            ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [currentFiles]);

  const getExportExcelFiles = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportFilesList();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar os arquivos resumidos', { variant: 'error', autoHideDuration: 6000 });
    } finally {
      setExcelLoading(false);
    }
  };
  
  return (
    <Container>
      <BaseCardBox>
        <Header>
          {user?.role === 'ADMIN' ? (
            <h3>Cadastro de Arquivos</h3>
          ) : (
            <h3>Listagem de Arquivos</h3>
          )}
          <div>
            {user?.role === 'ADMIN' && (
              <Button
                onClick={() => {
                  setIsEdit(false);
                  setOpen(true);
                }}
                variant="contained"
                startIcon={<AddBoxIcon />}
              >
                Novo Cadastro
              </Button>
            )}
          </div>
        </Header>

        <BaseTextField
          onChange={(event) => {
            page !== 1 && setPage(1);
            setSearch(event.target.value);
          }}
          label="Realizar busca"
          variant="outlined"
        />
      </BaseCardBox>

      <BaseDialog
        open={open}
        onClose={handleCloseModalForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FilesForm
          files={files}
          setFiles={setFiles}
          setSnackBarSettings={setSnackBarSettings}
          resetSnackBar={resetSnackBar}
          isEdit={isEdit}
          handleCloseModalForm={handleCloseModalForm}
          sectors={sectors}
          setSectors={setSectors}
          getFilesPaginated={getFilesPaginated}
          file={file}
          companies={companies}
        />
      </BaseDialog>

      {
          user && user.role === RoleType.ADMIN && (
              <BaseExportExcelContainer>
                <div>
                  <Tooltip title="Exportar em excel todos os registros de arquivos, apenas resumido. Para detalhes, baixe pela listagem na tabela.">
                    <Button
                        variant="contained"
                        startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                        }
                        onClick={() => getExportExcelFiles()}
                        disabled={excelLoading}
                    >
                      Exportar em excel
                    </Button>
                  </Tooltip>
                </div>
              </BaseExportExcelContainer>
          )
      }
      
      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />

      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}

      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
}

export { AdmFilesPage };
