import React from 'react';
import {
  Container,
  Stack,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import logo from '../assets/imagens/logo-azul.png';
import logoMobile from '../assets/imagens/logo-branca.png';
import Layout from 'lib/components/Layout/Layout';

interface DefaultPageProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const DefaultPage = ({ children, style }: DefaultPageProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const backgroundColor = isMobile ? '#F4F1F1' : '#E8E6E6';
  const padding = isMobile ? '0' : '1em 4em';

  return (
    <Layout backgroundColor={backgroundColor} padding={padding} style={style}>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            padding: '7px 21px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#007FFF',
          }}
        >
          <img src={logoMobile} alt="Logo" />
        </Box>
      )}
      <Container>
        <Grid container>
          {!isMobile && (
            <Grid item xs={2}>
              <Box sx={{ padding: '3em 0em' }}>
                <img src={logo} alt="Logo" />
              </Box>
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 10}>
            <Stack spacing={3} sx={{ maxWidth: '800px', paddingY: '3em' }}>
              {children}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default DefaultPage;
