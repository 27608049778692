import React from 'react';
import { AssessmentListPage } from './assessment-list';
import { AssessmentListType, OpenViewDetailsPropTypes } from './types';
import { AssessmentDetailsPage } from './assessment-details-page';

export function AssesmentPageRoot() {
  const [isDetailsView, setIsDetailsView] = React.useState(false);
  const [detailsData, setDetailsData] = React.useState(
    {} as OpenViewDetailsPropTypes,
  );
  const handleViewTest = (data: OpenViewDetailsPropTypes) => {
    setDetailsData(data);
    setIsDetailsView(true);
  };
  return isDetailsView ? (
    <AssessmentDetailsPage
      data={detailsData}
      goToAllAssessments={() => setIsDetailsView(false)}
    />
  ) : (
    <AssessmentListPage onViewTest={handleViewTest} />
  );
}
