import {
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  DialogActions,
  Button,
  TextField,
  Select,
} from '@mui/material';

import { AssessmentTypeDTO } from 'lib/api/assessment.protocols';
import { FieldRow } from 'modules/analyst-reports/style';
import { BaseDialog } from 'modules/companies/pages/list/style';
import { IInstrument } from 'modules/instruments/pages/list';
import React, { useEffect, useState } from 'react';

interface FormProps {
  open: boolean;
  handleCloseModalForm: () => void;
  isEdit: boolean;
  assessmentTypes: AssessmentTypeDTO[];
  currentInstrument: IInstrument | undefined;
  handleCreate: (instrumentName: string, assessmentTypeId: string) => void;
  handleEdit: (instrumentName: string, assessmentTypeId: string) => void;
}

export const InstrumentForm = ({
  open,
  handleCloseModalForm,
  handleCreate,
  handleEdit,
  isEdit,
  assessmentTypes,
  currentInstrument,
}: FormProps) => {
  const [instrumentName, setInstrumentName] = useState<string>('');
  const [assessmentTypeId, setAssessmentTypeId] = useState<string>('');

  useEffect(() => {
    if (isEdit) {
      if (currentInstrument) {
        setInstrumentName(currentInstrument?.name);
        currentInstrument.assessmentTypeId &&
          setAssessmentTypeId(currentInstrument?.assessmentTypeId);
      }
    } else {
      setInstrumentName('');
      setAssessmentTypeId('');
    }
  }, [isEdit]);

  const handleSaveInstrumentModal = (e: React.FormEvent) => {
    e.preventDefault();
    cleanForm();
    if (isEdit) {
      handleEdit(instrumentName, assessmentTypeId);
      return;
    } else {
      handleCreate(instrumentName, assessmentTypeId);
    }
  };

  const cleanForm = () => {
    handleCloseModalForm();
    setInstrumentName('');
    setAssessmentTypeId('');
  };

  return (
    <BaseDialog
      open={open}
      onClose={cleanForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={(e) => handleSaveInstrumentModal(e)}>
        {!isEdit ? (
          <DialogTitle id="alert-dialog-title">
            {'NOVO INSTRUMENTO'}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">
            {'EDITAR INSTRUMENTO'}
          </DialogTitle>
        )}

        <TextField
          required
          onChange={(event) => setInstrumentName(event.target.value)}
          label="Nome do instrumento"
          variant="outlined"
          value={instrumentName}
        />

        <FieldRow>
          <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
            <InputLabel id="demo-simple-select-label">Tipo de prova</InputLabel>
            <Select
              fullWidth
              name="assessmentType"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput label="Tipo de prova" />}
              value={assessmentTypeId}
              onChange={(event) =>
                setAssessmentTypeId(event.target.value as string)
              }
            >
              {assessmentTypes?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
              <MenuItem value={''}>Não se aplica</MenuItem>
            </Select>
          </FormControl>
        </FieldRow>

        <DialogActions>
          <Button variant="outlined" color="error" onClick={cleanForm}>
            Cancelar
          </Button>
          <Button variant="contained" color="success" type="submit" autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </BaseDialog>
  );
};
