import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import ButtonMain from 'modules/assessment-execution/components/ButtonMain';
import Typography from '@mui/material/Typography';
import AssessmentMock from '../mock/assessment.mock.json';
import { DefaultPage } from 'modules/assessment-execution/components/DefaultPage';
import { AssessmentApi } from 'lib/api/assessment';
import { useSnackbar } from 'notistack';
import { useAssessment } from 'modules/assessment-execution/context/assessment.provider';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

export const SelfiePage = () => {
  const webcamRef = useRef<Webcam>(null);
  const { analystRequest, handleNextPage } = useAssessment();
  const { enqueueSnackbar } = useSnackbar();
  const [webCamLoaded, setWebCamLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const capture = React.useCallback(() => {
    setLoading(true);
    if (!webcamRef.current) {
      setLoading(false);
      enqueueSnackbar('Falha ao capturar sua imagem. Tente novamente.', {
        variant: 'error',
      });
      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    if (!analystRequest || !imageSrc) return;
    AssessmentApi.insertSelfieUrl(analystRequest.id, imageSrc)
      .then(() => handleNextPage())
      .catch((error) => {
        enqueueSnackbar('Erro ao salvar a selfie.', { variant: 'error' });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [webcamRef]);

  return (
    <DefaultPage>
      <Typography variant="h1">Autorização necessária</Typography>
      <Typography
        variant="body1"
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: AssessmentMock.selfie,
        }}
      />
      <Webcam
        audio={false}
        ref={webcamRef}
        onUserMedia={() => setWebCamLoaded(true)}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <ButtonMain
        onClick={capture}
        text={loading ? 'Carregando...' : 'Consentir e continuar'}
        disabled={!webCamLoaded || loading}
      ></ButtonMain>
    </DefaultPage>
  );
};

export default SelfiePage;
