import styled from 'styled-components';
import TableContainer from '@mui/material/TableContainer';

export const BaseTableContainer = styled(TableContainer)`
  table {
    border: 1px solid #dbdbdb;
  }

  table thead tr th {
    background-color: #d1e8ff !important;
    color: #555 !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 5px;
  }

  table thead tr th:first-child {
    padding-left: 16px;
  }

  table tbody td {
    padding: 10px 5px;
  }

  table tbody td:first-child {
    padding-left: 16px;
  }

  table tbody td:last-child {
    padding-top: 15px;
  }

  table tbody td svg {
    font-size: 14pt;
    color: #7d7d7d;
  }

  table tbody td svg:hover {
    cursor: pointer;
    color: #373636;
  }

  table tbody td img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }

  table tbody td p {
    font-size: 11pt;
    line-height: 0.2;
  }
`;
