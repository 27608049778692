import { User } from '../context/auth.provider';

export enum RoleType {
  ADMIN = 'ADMIN',
  ANALISTA = 'ANALISTA',
  CONSULTOR = 'CONSULTOR',
}

export const userRole = (user: User) => {
  switch (user.role) {
    case 'ADMIN':
      return RoleType.ADMIN;
    case 'ANALISTA':
      return RoleType.ANALISTA;
    case 'CONSULTOR':
      return RoleType.CONSULTOR;
    default:
      throw new Error('Invalid role type');
  }
};
