import { createTheme } from '@mui/material/styles';
import '@fontsource/work-sans';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans',
    h1: {
      fontWeight: 600,
      fontSize: '31.5px',
      lineHeight: 'normal',
      color: 'rgba(16, 0, 0, 1)',
    },
    h2: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '27px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '27px',
      color: 'rgba(16, 0, 0, 1)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#FFF',
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: '#f4f4f4',
        },
        '::-webkit-scrollbar': {
          width: '10px',
          background: '#f4f4f4',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#dad7d7',
          borderRadius: '10px',
        },
        '.MuiContainer-root': {
          minHeight: '700px',
        },
        '.MuiPaper-root .MuiContainer-root': {
          minHeight: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default theme;
