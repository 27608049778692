import { Auth } from 'firebase/auth';
import axiosInstance, {getBaseURL} from './api';
import { UserReceivedDTO } from './user.protocols';
import axios from 'axios';

export class UserAuthApi {
  static async fetchUserFromDatabase(
    auth: Auth,
  ): Promise<UserReceivedDTO | undefined> {
    if (auth.currentUser) {
      const email = auth.currentUser.providerData[0].email
        ? encodeURIComponent(auth.currentUser.providerData[0].email)
        : auth.currentUser.providerData[0].email;

      const user = await axiosInstance.get(`/user/auth/find?email=${email}`);

      return user.data;
    }
  }

  static async setUserCustomClaims(auth: Auth): Promise<void> {
    if (
      auth.currentUser?.providerData[0].email &&
      (auth.currentUser?.providerData[0].providerId === 'microsoft.com' ||
        auth.currentUser?.providerData[0].providerId === 'google.com')
    ) {
      const email = encodeURIComponent(auth.currentUser.providerData[0].email);
      await axiosInstance.put(
        `/user/auth/customClaims?email=${email}&uid=${auth.currentUser.uid}`,
      );
    }
  }

  static async insertProviderInfo(auth: Auth): Promise<void> {
    if (auth.currentUser) {
      await axiosInstance.put('/user/auth/firebaseInfo', {
        email: auth.currentUser.providerData[0].email,
        uid: auth.currentUser.uid,
        providerId: auth.currentUser.providerData[0].providerId,
      });
    }
  }

  static async removeUserFromFirebase(
    uid: string,
    token: string,
  ): Promise<void> {
    //TODO refactor to use axios instance with diferent Authorization headers from global axios instance config.
    const baseUrl = getBaseURL();

    await axios.delete(`${baseUrl}/user/auth/firebaseUser?firebaseUid=${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
