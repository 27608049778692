import { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CompanyDTO } from 'lib/api/company.protocols';
import { ISnackBarSettings } from 'lib/helpers/snackbar-interface';
import { RequestApi } from 'lib/api/requests';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { FieldRow, BaseInputLabel, FileInput, ErrorMsg } from './style';
import { FileApi } from 'lib/api/file';
import React from 'react';
import { CreateFileDto, IFiles, UpdateFileDto } from 'lib/api/file.protocols';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export interface IElements {
  id?: string;
  name: string;
}

interface IProps {
  files: IFiles[];
  setFiles: React.Dispatch<React.SetStateAction<IFiles[]>>;
  setSnackBarSettings: React.Dispatch<React.SetStateAction<ISnackBarSettings>>;
  resetSnackBar: () => void;
  isEdit: boolean;
  handleCloseModalForm: () => void;
  sectors: string[];
  setSectors: React.Dispatch<React.SetStateAction<string[]>>;
  getFilesPaginated: () => Promise<void>;
  file: any;
  companies: CompanyDTO[];
}

const FilesForm = ({
  files,
  setFiles,
  setSnackBarSettings,
  resetSnackBar,
  isEdit,
  handleCloseModalForm,
  sectors,
  setSectors,
  getFilesPaginated,
  file,
  companies,
}: IProps) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyDTO>();
  const [fileName, setFileName] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [fileFail, setFileFail] = useState(false);

  const setValuesToEdit = () => {
    const findedCompany = companies.find(
      (company) => company.id === file.company.id,
    );
    setSelectedCompany(findedCompany);

    file.sectors.map((sector: any) => {
      sectors.push(sector.id);
    });

    setFileName(file.fileName);
  };

  useEffect(() => {
    if (isEdit) {
      setValuesToEdit();
    }
  }, [isEdit]);

  const selectCompany = (companyId: string) => {
    const result = companies.find((company) => company.id === companyId);
    setSelectedCompany(result);
  };

  const sortedSectors =
    selectedCompany &&
    selectedCompany.sectors.sort((a: IElements, b: IElements) => {
      const treateAName = a.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      const treateBName = b.name
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()
        .trim();
      return treateAName > treateBName ? 1 : -1;
    });

  const handleSaveModalForm = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!isEdit) {
        if (selectedCompany) {
          const dataToSend: CreateFileDto = {
            companyId: selectedCompany.id,
            sectorsIds: sectors,
            files: files,
          };
          await FileApi.createFile(dataToSend);
        }
      } else {
        // eslint-disable-next-line prefer-const
        let sectorsExistInCompany: string[] = [];

        selectedCompany?.sectors.map((item) => {
          sectors.map((el) => {
            if (el === item.id) {
              sectorsExistInCompany.push(el);
            }
          });
        });

        const dataToSend: UpdateFileDto = {
          companyId: selectedCompany?.id,
          sectorsIds: sectorsExistInCompany,
          fileName: fileName,
        };

        await FileApi.editFile(file.id, dataToSend);
      }

      getFilesPaginated();
      handleCloseModalForm();
      setSnackBarSettings({
        show: true,
        message: 'Arquivo(s) salvo(s) com sucesso!',
        severity: 'success',
      });
      setSectors([]);
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.response.data.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleFiles = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let count;
      const formData = new FormData();

      for (count = e.target.files.length; count > 0; count--) {
        formData.append('files', e.target.files[count - 1]);
      }

      try {
        setShowSaveButton(false);
        setFileFail(false);
        const response = await RequestApi.retrieveFilesLinks(formData);
        setFiles(response);
        setShowSaveButton(true);
      } catch (err: any) {
        setShowSaveButton(false);
        setFileFail(true);

        if (err.code === 'ERR_NETWORK') {
          return setSnackBarSettings({
            show: true,
            message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
            severity: 'error',
          });
        }

        if (err.message === 'Network Error') {
          setSnackBarSettings({
            show: true,
            message: 'O arquivo deve ter no máximo 3mb',
            severity: 'error',
          });
        } else {
          setSnackBarSettings({
            show: true,
            message: err.message,
            severity: 'error',
          });
        }
        resetSnackBar();
      }
    }
  };

  const handleSectors = (e: any) => {
    if (e.target.checked) {
      sectors.push(e.target.value);
    } else {
      const result = sectors.filter((sector) => sector !== e.target.value);
      setSectors(result);
    }
  };

  return (
    <form onSubmit={(e) => handleSaveModalForm(e)}>
      {!isEdit ? (
        <DialogTitle id="alert-dialog-title">{'NOVO ARQUIVO'}</DialogTitle>
      ) : (
        <DialogTitle id="alert-dialog-title">{'EDITAR ARQUIVO'}</DialogTitle>
      )}

      <FieldRow>
        <FormControl style={{ width: '430px' }}>
          <BaseInputLabel id="demo-simple-select-label">Empresa</BaseInputLabel>
          {!isEdit ? (
            <Select
              required
              fullWidth
              name="company"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(e: any) => selectCompany(e.target.value)}
            >
              {companies.map((company) => (
                <MenuItem value={company.id}>{company.name}</MenuItem>
              ))}
            </Select>
          ) : (
            selectedCompany && (
              <Select
                fullWidth
                name="company"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCompany?.id}
                onChange={(e: any) => selectCompany(e.target.value)}
              >
                {companies.map((company) => (
                  <MenuItem value={company.id}>{company.name}</MenuItem>
                ))}
              </Select>
            )
          )}
        </FormControl>
      </FieldRow>

      {isEdit && (
        <FieldRow>
          <TextField
            name="fileName"
            label="Nome do arquivo"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </FieldRow>
      )}

      {selectedCompany && (
        <FieldRow
          style={{
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '5px',
          }}
        >
          <div id="checkbox-group">Setores</div>
          <div
            className="sectorsSelects"
            role="group"
            aria-labelledby="checkbox-group"
          >
            {sortedSectors &&
              sortedSectors.map((item) => {
                let exist;

                if (isEdit) {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  exist = sectors.find((sector) => sector === item.id);
                }

                return !isEdit ? (
                  <label>
                    <input
                      type="checkbox"
                      name="sectorsIds"
                      value={item.id}
                      onChange={(e: any) => handleSectors(e)}
                    />
                    {item.name}
                  </label>
                ) : (
                  <label>
                    <FormControlLabel
                      onChange={(e: any) => handleSectors(e)}
                      control={
                        exist ? <Checkbox defaultChecked /> : <Checkbox />
                      }
                      value={item.id}
                      label={item.name}
                    />
                  </label>
                );
              })}
          </div>
        </FieldRow>
      )}

      {!isEdit && (
        <FieldRow>
          <FormControl fullWidth>
            <FileInput
              required
              onChange={(e: any) => {
                handleFiles(e);
              }}
              type="file"
              name="file"
              multiple
            />
            {!showSaveButton && !fileFail && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            )}
            {fileFail && (
              <ErrorMsg>
                Arquivo(s) inválido(s), favor selecionar outro(s) arquivo(s).
              </ErrorMsg>
            )}
          </FormControl>
        </FieldRow>
      )}

      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleCloseModalForm}>
          Cancelar
        </Button>
        {showSaveButton && !fileFail && (
          <Button variant="contained" color="success" type="submit" autoFocus>
            Salvar
          </Button>
        )}
        {!showSaveButton && fileFail && (
          <Button variant="contained" disabled>
            Salvar
          </Button>
        )}
        {!showSaveButton && !fileFail && (
          <Button variant="contained" disabled>
            Salvar
          </Button>
        )}
      </DialogActions>
    </form>
  );
};

export { FilesForm };
