import { Container } from './style';

interface Props {
  children: React.ReactNode;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
}

export default function CardBox({ children, height, width, ...props }: Props) {
  return (
    <Container height={height} width={width} {...props}>
      {children}
    </Container>
  );
}
