import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import { Button as BaseButton } from '@mui/material';

export const Button = styled(BaseButton)`
  .MuiButton-startIcon,
  .MuiCircularProgress-root {
    width: 15px !important;
    height: 15px !important;
  }
`;

export const FieldRow = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  gap: 20px;

  #checkbox-group {
    font-size: 12pt;
    color: #333;
  }

  #userCompany {
    input {
      width: 330px;
    }
  }

  .sectorsSelects {
    display: flex;
    flex-direction: column;
    font-size: 12pt;
    color: #777;

    input {
      width: 20px;
    }
  }
`;

export const FixedLabel = styled.div`
  color: #1976d2;
  font-weight: 400;
  font-size: 10pt;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 3px 5px;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 10px;
  background-color: #fff;
`;

export const BlockTitle = styled.h3`
  font-weight: 500;
  font-size: 16pt;
  line-height: 0;
  margin-top: 35px;
`;

export const BaseInputLabel = styled(InputLabel)`
  background-color: #fff;
  padding: 0 8px !important;
`;
