import { PageDto } from 'lib/common/dto/page.dto';
import axiosInstance from './api';
import { SectorDTO } from './sector.protocols';

class SectorApi {
  static async createSector(createSectorDTO: SectorDTO): Promise<SectorDTO> {
    const response: SectorDTO = await axiosInstance
      .post('/sector', createSectorDTO)
      .then((response) => response.data);

    return response;
  }

  static async editSector(
    id: string,
    editedSectorDTO: SectorDTO,
  ): Promise<SectorDTO> {
    const response: SectorDTO = await axiosInstance
      .put(`/sector/${id}`, editedSectorDTO)
      .then((response) => response.data);

    return response;
  }

  static async deleteSector(id: string): Promise<SectorDTO> {
    const response: SectorDTO = await axiosInstance
      .delete(`/sector/${id}`)
      .then((response) => response.data);

    return response;
  }

  static async getSectors(): Promise<SectorDTO[]> {
    const response: SectorDTO[] = await axiosInstance
      .get('/sector/all')
      .then((response) => response.data);

    return response;
  }

  static async searchSectors(
    objectToSearch: string,
    page: number,
  ): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/sector/search?name=${objectToSearch}&page=${page}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }
}

export { SectorApi };
