import React from 'react';
import { Container, Box, Stack } from '@mui/material';
import Logo from '../assets/imagens/logo-branca.png';
import BackgroundImage from '../assets/imagens/initial-page-bg.jpg';

export const FirstPage = ({ children }: { children: React.ReactNode }) => {
  const backgroundStyle = {
    backgroundImage: `linear-gradient(180deg, rgba(35, 143, 239, 0.00) 0%, rgba(44, 16, 156, 0.50) 35.42%, #2C109C 100%), url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundBlendMode: 'multiply',
    backgroundColor: 'rgba(16, 0, 0, 0.01)',
    minHeight: '100vh',
  };

  return (
    <body style={{ ...backgroundStyle }}>
      <Box
        sx={{ backdropFilter: 'blur(5px)', width: '100vw', minHeight: '100vh' }}
      >
        <Container>
          <Box
            sx={{
              paddingY: '3em',
              textAlign: 'center',
            }}
          >
            <img src={Logo} alt="Logo" />
          </Box>
          <Stack
            spacing={3}
            sx={{
              maxWidth: '800px',
              paddingY: '3em',
              margin: '0 auto',
            }}
          >
            {children}
          </Stack>
        </Container>
      </Box>
    </body>
  );
};

export default FirstPage;
