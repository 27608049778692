const firebaseConfigProd = {
  apiKey: 'AIzaSyBVgIFJXbRSRYYXVNGKjdKH7cJeRz1zZ4Y',
  authDomain: 'projeto-ms-a5060.firebaseapp.com',
  projectId: 'projeto-ms-a5060',
  storageBucket: 'projeto-ms-a5060.appspot.com',
  messagingSenderId: '117563773660',
  appId: '1:117563773660:web:fd98bdfa5a6ee6589f1a19',
  measurementId: 'G-1ZBBQLV9SY',
};

const firebaseConfigHml = {
  apiKey: 'AIzaSyA18uaIebpCEAd-qS6knMiFUZn12-a4apo',
  authDomain: 'projeto-ms-hml-302be.firebaseapp.com',
  projectId: 'projeto-ms-hml-302be',
  storageBucket: 'projeto-ms-hml-302be.appspot.com',
  messagingSenderId: '37024772219',
  appId: '1:37024772219:web:bfca5707bf26a883dfb2de',
};

const getConfig = () => {
  // const stage = process.env.REACT_APP_STAGE;
  const url = window.location.href;
  if (url.includes('localhost:'))
    return firebaseConfigHml;
  else if (url.includes('https://ms-frontend-dev.areadoclientems.com.br'))
    return firebaseConfigHml;
  else
    return firebaseConfigProd;
  // if (stage === 'prod') {
  //   return firebaseConfigProd;
  // } else {
  //   return firebaseConfigHml;
  // }
};

export const firebaseConfig = getConfig();
