import { useContext } from 'react';
import { AuthContext } from './auth.context';

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error('You should only use auth object inside auth provider.');
  }

  return auth;
};

export const useUser = () => {
  const { user } = useAuth();

  return user;
};
