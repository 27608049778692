import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CustomizedTable, {
  ITableBody,
} from './consultant-request-details-table';
import { RequestApi } from 'lib/api/requests';
import CustomizedSnackbars from 'lib/components/SnackBar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  BaseBreadcrumbs,
  BaseCardBox,
  FieldRow,
  Button,
  FileInput,
  ErrorMsg,
  FakeButton,
} from './style';
import { FileDto, ReportType } from 'lib/api/file.protocols';
import { FileApi } from 'lib/api/file';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { IRequestResponseDTO } from 'lib/api/requests.protocols';
import { useSnackbar } from 'notistack';

export interface ISnackBarSettings {
  show: boolean;
  message?: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
}

interface IElements {
  id?: string;
  name: string;
}

const tableHead = ['Nome', 'Descrição', 'Nome', 'Descrição'];

const ConsultantRequestDetailsPage = ({
  goToAllRequests,
  id,
}: {
  goToAllRequests: () => void;
  id: string;
}) => {
  const [requestData, setRequestData] = useState<IRequestResponseDTO>();

  const [files, setFiles] = useState([]);
  const [reportFiles, setReportFiles] = useState<FileDto[] | null>(null);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });

  const [requestChanges, setRequestChanges] = useState({
    testSubmissionDate: null,
    resultSentToClientDate: null,
    result: '',
    status: '',
    consultantNote: '',
  });

  const [showSaveButton, setShowSaveButton] = useState(true);
  const [inLoading, setInLoading] = useState(false);
  const [fileFail, setFileFail] = useState(false);
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const getReportFiles = async () => {
    try {
      const reportFiles = await FileApi.searchByRequestId(id);
      setReportFiles(reportFiles);
    } catch (e) {
      console.error('Erro ao buscar arquivo', e);
    }
  };

  useEffect(() => {
    getReportFiles();
  }, [requestData]);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const fetchRequest = async (id: string) => {
    try {
      const request = await RequestApi.getRequest(id);

      setRequestData(request);
      setRequestChanges((prev: any) => ({
        ...prev,
        testSubmissionDate: request.requestManagement.testSubmissionDate
          ? request.requestManagement.testSubmissionDate
          : null,
        resultSentToClientDate: request.requestManagement.resultSentToClientDate
          ? request.requestManagement.resultSentToClientDate
          : null,
        status: request.status ? request.status : '',
        result: request.requestManagement.result
          ? request.requestManagement.result
          : '',
        consultantNote: request.requestManagement.consultantNote
          ? request.requestManagement.consultantNote
          : '',
      }));
    } catch (error: any) {
      if (error.code === 'ERR_NETWORK') {
        return setSnackBarSettings({
          show: true,
          message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
          severity: 'error',
        });
      }
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRequest(id);
  }, []);

  const fakeButtonBgColor = (variant: string | undefined) => {
    switch (variant) {
      case 'em andamento':
        return '#f9f158';
      case 'cancelado':
        return '#ed4c40';
      case 'correção':
        return '#f1a262';
      case 'congelado':
        return '#cecece';
      case 'finalizado':
        return '#81c163';
      case 'reanálise':
        return '#6495ED';
    }
  };

  const getReportUrl = async (
    azureFileName: string,
    reportUser: IElements | undefined,
  ) => {
    try {
      const reportType = reportUser
        ? ReportType.CONSULTANT_REPORT
        : ReportType.ASSESSMENT_REPORT;

      const url = await FileApi.getReportUrl({ azureFileName, reportType });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Erro ao gerar url do arquivo', { variant: 'error' });
    } finally {
    }
  };

  const mountTableBody = () => {
    const body = [
      {
        label1: 'Solicitante:',
        value1: requestData?.analyst.name,
        label2: 'Data solicitação:',
        value2: dayjs(requestData?.requestDate).format('DD/MM/YYYY'),
      },
      {
        label1: 'Empresa:',
        value1: requestData?.company.name,
        label2: 'Data de envio dos links:',
        value2: requestData?.requestManagement.linkSentDate
          ? dayjs(requestData?.requestManagement.linkSentDate).format(
              'DD/MM/YYYY',
            )
          : '',
      },
      {
        label1: 'E-mail do solicitante:',
        value1: requestData?.analyst.email ? requestData?.analyst.email : '',
        label2: 'Data de envio ao cliente:',
        value2: requestData?.requestManagement.resultSentToClientDate
          ? dayjs(requestData?.requestManagement.resultSentToClientDate).format(
              'DD/MM/YYYY',
            )
          : '',
      },
      {
        label1: 'Instrumento a ser enviado:',
        value1: requestData?.instrument.name,
        label2: 'Centro de custo:',
        value2: requestData?.costCenter,
      },
      {
        label1: 'Nome completo:',
        value1: requestData?.candidate.name,
        label2: 'Unidade organizacional:',
        value2: requestData?.sector.name,
      },
      {
        label1: 'CPF:',
        value1: requestData?.candidate.cpf,
        label2: 'Observações:',
        value2: requestData?.additionalNote,
      },
      {
        label1: 'E-mail do avaliado:',
        value1: requestData?.candidate.email,
        label2: 'Status:',
        value2: (
          <FakeButton
            variant={fakeButtonBgColor(requestData && requestData.status && requestData?.status.toLowerCase())}
          >
            {requestData && requestData.status}
          </FakeButton>
        ),
      },
      {
        label1: 'Whatsapp:',
        value1: requestData?.candidate.whatsapp,
        label2: 'Arquivos:',
        value2: reportFiles ? (
          <React.Fragment>
            {reportFiles &&
              reportFiles.map((file, index) => {
                return (
                  <a
                    key={index}
                    style={{
                      textDecoration: 'none',
                      color: '#1976D2',
                      cursor: 'pointer',
                    }}
                    onClick={() => getReportUrl(file.azureFileName, file.user)}
                  >{`arquivo ${index + 1}`}</a>
                );
              })}
          </React.Fragment>
        ) : (
          ''
        ),
      },
      {
        label1: 'Cargo:',
        value1: requestData?.jobTitle.name,
        label2: 'Resultado:',
        value2: requestData?.requestManagement.result
          ? requestData?.requestManagement.result.toLowerCase()
          : '',
      },
      {
        label1: 'Origem da solicitação:',
        value1: requestData?.gupyApplicationId ? 'Sistema Gupy' : 'Sistema MS',
        label2: 'Observação do(a) consultor(a) MS:',
        value2: requestData?.requestManagement.consultantNote,
      },
    ];
    setTableBody(body);
  };

  useEffect(() => {
    setTableBody([]);
    if (requestData && Object.keys(requestData).length) {
      mountTableBody();
    }
  }, [reportFiles]);

  const handleFiles = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let count;
      const formData = new FormData();

      for (count = e.target.files.length; count > 0; count--) {
        formData.append('files', e.target.files[count - 1]);
      }

      try {
        setShowSaveButton(false);
        setFileFail(false);
        const response = await RequestApi.retrieveFilesLinks(formData);
        setFiles(response);
        setShowSaveButton(true);
      } catch (err: any) {
        setShowSaveButton(false);
        setFileFail(true);
        if (err.message === 'Network Error') {
          setSnackBarSettings({
            show: true,
            message: 'Cada arquivo deve ter no máximo 3mb',
            severity: 'error',
          });
        } else {
          setSnackBarSettings({
            show: true,
            message: err.message,
            severity: 'error',
          });
        }
        resetSnackBar();
      }
    }
  };

  const handleSaveChanges = async () => {
    if (
      (requestChanges?.resultSentToClientDate !== null ||
        requestChanges?.status?.toUpperCase() === 'FINALIZADO') &&
      files.length === 0 &&
      reportFiles?.length === 0
    ) {
      setSnackBarSettings({
        show: true,
        message: 'Favor escolher ao menos um arquivo',
        severity: 'error',
      });
      resetSnackBar();

      return;
    }

    setInLoading(true);

    try {
      if (
        requestChanges.status &&
        requestChanges.status !== requestData?.status
      ) {
        const newStatus = {
          status: requestChanges.status,
        };
        await RequestApi.editRequest(newStatus, id);
      }

      const requestManagementData = {
        testSubmissionDate: requestChanges.testSubmissionDate,
        resultSentToClientDate: requestChanges.resultSentToClientDate,
        result: requestChanges.result,
        consultantNote: requestChanges.consultantNote,
      };

      await RequestApi.editRequestManagement(requestManagementData, id);

      if (files.length) {
        await FileApi.createFile({
          files,
          companyId: requestData?.company?.id as string,
          sectorsIds: [requestData?.sector?.id as string],
          requestId: id,
        }).then(() => getReportFiles());

        setFiles([]);
      }

      fetchRequest(id);
      setSnackBarSettings({
        show: true,
        message: 'Requisição editada com sucesso!',
        severity: 'success',
      });
      resetSnackBar();
      setInLoading(false);
    } catch (err: any) {
      setInLoading(false);
      if (err.code === 'ERR_NETWORK') {
        return setSnackBarSettings({
          show: true,
          message: 'Servidor instável, aguarde alguns segundos e recarregue a página por favor',
          severity: 'error',
        });
      }

      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const loadingButton = () => {
    if (inLoading) {
      return (
        <Button
          variant="contained"
          startIcon={<CircularProgress color="inherit" />}
          style={{ marginTop: '25px' }}
          disabled
        >
          Salvar solicitação
        </Button>
      );
    } else {
      return (
        <Button
          style={{ marginTop: '25px' }}
          onClick={() => handleSaveChanges()}
          variant="contained"
        >
          Salvar solicitação
        </Button>
      );
    }
  };

  return (
    <React.Fragment>
      <BaseBreadcrumbs aria-label="breadcrumb">
        <Link onClick={goToAllRequests}>minhas solicitações</Link>
        <Typography color="text.primary">detalhes da solicitação</Typography>
      </BaseBreadcrumbs>

      <BaseCardBox>
        <h3>Detalhes da solicitação</h3>

        <FieldRow style={{ marginTop: '25px' }}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data de recebimento do avaliado"
                format="DD/MM/YYYY"
                value={
                  requestChanges.testSubmissionDate &&
                  dayjs(requestChanges.testSubmissionDate)
                }
                onChange={(event) => {
                  setRequestChanges((prev: any) => ({
                    ...prev,
                    testSubmissionDate: event,
                  }));
                }}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Data do envio ao cliente"
                format="DD/MM/YYYY"
                value={
                  requestChanges.resultSentToClientDate &&
                  dayjs(requestChanges.resultSentToClientDate)
                }
                onChange={(event: any) =>
                  setRequestChanges((prev: any) => ({
                    ...prev,
                    resultSentToClientDate: event,
                  }))
                }
              />
            </LocalizationProvider>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              onChange={(event) =>
                setRequestChanges((prev: any) => ({
                  ...prev,
                  status: event.target.value,
                }))
              }
              value={requestChanges.status?.toLowerCase() ?? ''}
            >
              <MenuItem value="">
                <i>Válido</i>
              </MenuItem>
              <MenuItem value="em andamento">Em Andamento</MenuItem>
              <MenuItem value="correção">Correção</MenuItem>
              <MenuItem value="cancelado">Cancelado</MenuItem>
              <MenuItem value="congelado">Congelado</MenuItem>
              <MenuItem value="finalizado">Finalizado</MenuItem>
              <MenuItem value="reanálise">Reanálise</MenuItem>
            </Select>
          </FormControl>

          {requestData?.status !== 'CONGELADO' &&
            requestData?.status !== 'CANCELADO' && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Resultado</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Result"
                  onChange={(event) =>
                    setRequestChanges((prev: any) => ({
                      ...prev,
                      result: event.target.value,
                    }))
                  }
                  value={requestChanges.result ?? ''}
                >
                  <MenuItem value="">Sem resultado</MenuItem>
                  <MenuItem value="INAPTO">Inapto</MenuItem>
                  <MenuItem value="APTO">Apto</MenuItem>
                  <MenuItem value="APTO COM RESTRIÇÃO">
                    Apto com restrição
                  </MenuItem>
                  <MenuItem value="EXCEDE">Excede</MenuItem>
                </Select>
              </FormControl>
            )}
        </FieldRow>

        <FieldRow>
          <FormControl fullWidth>
            <TextField
              fullWidth
              multiline
              inputProps={{ maxLength: 500 }}
              name="consultantNote"
              label="Observação do(a) consultor(a)"
              type="textarea"
              value={requestChanges.consultantNote}
              onChange={(event: any) =>
                setRequestChanges((prev: any) => ({
                  ...prev,
                  consultantNote: event.target.value,
                }))
              }
            />
          </FormControl>
        </FieldRow>

        {reportFiles?.length === 0 &&
          requestChanges?.resultSentToClientDate !== null &&
          requestChanges?.status?.toUpperCase() === 'FINALIZADO' && (
            <FieldRow>
              <FormControl fullWidth>
                <FileInput
                  onChange={(e: any) => {
                    handleFiles(e);
                  }}
                  type="file"
                  name="file"
                  multiple
                />
                {!showSaveButton && !fileFail && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                )}
                {fileFail && (
                  <ErrorMsg>
                    Arquivo(s) inválido(s), favor selecionar outro(s)
                    arquivo(s).
                  </ErrorMsg>
                )}
              </FormControl>
            </FieldRow>
          )}

        {showSaveButton && !fileFail && loadingButton()}

        {!showSaveButton && fileFail && (
          <Button variant="contained" style={{ marginTop: '25px' }} disabled>
            Salvar solicitação
          </Button>
        )}
        {!showSaveButton && !fileFail && (
          <Button variant="contained" style={{ marginTop: '25px' }} disabled>
            Salvar solicitação
          </Button>
        )}
      </BaseCardBox>

      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />

      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}
    </React.Fragment>
  );
};

export { ConsultantRequestDetailsPage };
