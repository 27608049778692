import styled from 'styled-components';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CardBox from 'lib/components/CardBox';
import {TextField, Button as BaseButton} from '@mui/material';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const ErrorMsg = styled.p`
  color: #fb5454 !important;
  font-size: 10pt;
  margin: 5px 0 0 5px;
`;

export const BaseTextField = styled(TextField)`
  width: 100%;
`;

export const BaseTextFieldCPF = styled(TextField)`
  width: 48.6%;
`;

export const FileInput = styled.input`
  width: calc(100% - 30px);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 25px;

  input[type='file'] {
    display: none;
  }

  label {
    color: #fff;
    background-color: #1976d2;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 6px 16px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }

  label:hover {
    cursor: pointer;
    background-color: #1976d2;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;
export const FieldRow = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px;
  gap: 20px;
`;

export const FixedLabel = styled.div`
  color: #1976d2;
  font-weight: 400;
  font-size: 10pt;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 3px 5px;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  z-index: 1;
  margin-top: -12px;
  margin-left: 10px;
  background-color: #fff;
`;

export const Button = styled(BaseButton)`
  .MuiButton-startIcon,
  .MuiCircularProgress-root {
    width: 15px !important;
    height: 15px !important;
  }
`;

export const BaseBreadcrumbs = styled(Breadcrumbs)`
  a {
    color: #555;
    text-decoration: none;
  }

  a:hover {
    color: #333;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const BaseCardBox = styled(CardBox)`
  margin: 25px 0 50px;

  h3 {
    color: #66717f;
    margin: 0;
    font-size: 16pt;
  }
`;

export const FakeButton = styled.div<{ variant: string | undefined }>`
  background-color: ${({ variant }) => variant};
  text-align: center;
  padding: 5px 0px;
  width: 110px;
  text-transform: lowercase;
  border-radius: 4px;
`;
