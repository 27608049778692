import { PageDto } from 'lib/common/dto/page.dto';
import axiosInstance from './api';
import {
  CreateFileDto,
  FileDto,
  GetReportUrl,
  UpdateFileDto,
} from './file.protocols';

class FileApi {
  static async createFile(file: CreateFileDto): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .post(`/file`, file)
      .then((response) => response.data);

    return response;
  }

  static async exportUsers(): Promise<string> {
    const response: string = await axiosInstance
      .get(`/file-upload/download/spreadsheet/USERS`)
      .then((response) => response.data);

    return response;
  }

  static async exportSectors(): Promise<string> {
    const response: string = await axiosInstance
        .get(`/file-upload/download/spreadsheet/SECTORS`)
        .then((response) => response.data);

    return response;
  }

  static async exportInstruments(): Promise<string> {
    const response: string = await axiosInstance
        .get(`/file-upload/download/spreadsheet/INSTRUMENTS`)
        .then((response) => response.data);

    return response;
  }

  static async exportCompanies(): Promise<string> {
    const response: string = await axiosInstance
        .get(`/file-upload/download/spreadsheet/COMPANIES`)
        .then((response) => response.data);

    return response;
  }

  static async exportJobTitles(): Promise<string> {
    const response: string = await axiosInstance
        .get(`/file-upload/download/spreadsheet/JOB_TITLES`)
        .then((response) => response.data);

    return response;
  }

  static async exportFilesList(): Promise<string> {
    const response: string = await axiosInstance
        .get(`/file-upload/download/spreadsheet/FILES_LIST`)
        .then((response) => response.data);

    return response;
  }

  static async exportRequests(query: string): Promise<string> {
    const response: string = await axiosInstance
      .get(`/file-upload/download/spreadsheet/REQUESTS?${query}`)
      .then((response) => response.data);

    return response;
  }

  static async exportAnalystRequests(id: string | undefined): Promise<string> {
    const response: string = await axiosInstance
      .get(`/file-upload/download/spreadsheet/ANALYST_REQUESTS?analystId=${id}`)
      .then((response) => response.data);

    return response;
  }

  static async exportAssessments(): Promise<string> {
    const response: string = await axiosInstance
      .get(`/file-upload/download/spreadsheet/REQUESTS_WITH_ASSESSMENT`)
      .then((response) => response.data);

    return response;
  }

  static async editFile(fileId: string, file: UpdateFileDto): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .put(`/file/${fileId}`, file)
      .then((response) => response.data);

    return response;
  }

  static async getFiles(): Promise<FileDto[]> {
    const response: FileDto[] = await axiosInstance
      .get(`/file/all`)
      .then((response) => response.data);

    return response;
  }

  static async getFile(fileId: string): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .get(`/file/${fileId}`)
      .then((response) => response.data);

    return response;
  }

  static async searchFiles(page: number, search: string): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/file/search?page=${page}&filter=${search}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }

  static async searchByRequestId(requestId: string): Promise<FileDto[]> {
    const response: FileDto[] = await axiosInstance
      .get(`/file/search/${requestId}`)
      .then((response) => response.data);

    return response;
  }

  static async searchByCompanyAndSectors(
    page: number,
    filter: string,
  ): Promise<PageDto> {
    const response: PageDto = await axiosInstance
      .get(`/file/search/company-sectors?page=${page}&filter=${filter}`)
      .then((response) => response.data);

    const { entities, meta } = response;

    return { entities, meta };
  }

  static async deleteFile(fileId: string): Promise<FileDto> {
    const response: FileDto = await axiosInstance
      .delete(`/file/${fileId}`)
      .then((response) => response.data);

    return response;
  }

  static async getReportUrl(query: GetReportUrl): Promise<string> {
    const response = await axiosInstance.get(
      `/file/report-url?azureFileName=${query.azureFileName}&reportType=${query.reportType}`,
    );

    return response.data;
  }
}

export { FileApi };
