/* eslint-disable prefer-const */
import { useState } from 'react';

import { useFormik, FormikProvider } from 'formik';
import { Button, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as yup from 'yup';
import { Dayjs } from 'dayjs';

import { cpfMask, phoneMask } from 'lib/helpers/masks';
import { BlockTitle, FieldRow, BaseInputLabel, ErrorMsg } from '../../style';
import { IRequest } from '../../models';
import { validateCPF } from 'lib/helpers/cpf-validation';

interface IProps {
  request: IRequest | undefined;
  regions: string[] | undefined;
  company: any;
  requests: IRequest[];
  setRequests: React.Dispatch<React.SetStateAction<IRequest[]>>;
  snackBar: (
    message: string,
    severity: 'success' | 'error' | 'warning' | 'info' | undefined,
  ) => void;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemToEditIndex: number | null;
}

const RequestEditModal = ({
  request,
  regions,
  company,
  requests,
  setRequests,
  snackBar,
  setOpen,
  setIsEdit,
  itemToEditIndex,
}: IProps) => {
  const [requestDate, setRequestDate] = useState(request?.requestDate);
  const [invalidDate, setInvalidDate] = useState(false);
  const validationSchema = yup.object({
    name: yup.string().required('Campo obrigatório'),
    cpf: yup.string(),
    email: yup
      .string()
      .required('Campo obrigatório')
      .email('Informe um e-mail válido'),
    whatsapp: yup.string().required('Campo obrigatório'),
    jobTitleId: yup.string().required(),
    region: yup.string().required(),
    instrumentId: yup.string().required(),
    sectorId: yup.string().required(),
  });

  const handleChangeRequestDate = (newValue: Dayjs | null) => {
    setRequestDate(newValue);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      jobTitleId: request?.jobTitleId,
      region: request?.region,
      instrumentId: request?.instrumentId,
      sectorId: request?.sectorId,
      costCenter: request?.costCenter,
      additionalNote: request?.additionalNote,
      name: request?.candidate.name,
      cpf: request?.candidate.cpf,
      email: request?.candidate.email,
      whatsapp: request?.candidate.whatsapp,
    },
    onSubmit: (values) => {
      if (values.cpf && values.cpf !== '000.000.000-00') {
        const cpfIsValid = validateCPF(values.cpf);

        if (!cpfIsValid) {
          snackBar('O CPF inserido não é válido.', 'error');
          return;
        }
      }

      const dataToSend = {
        requestDate: requestDate ? requestDate : null,
        jobTitleId: values.jobTitleId ? values.jobTitleId : '',
        region: values.region ? values.region : '',
        instrumentId: values.instrumentId ? values.instrumentId : '',
        sectorId: values.sectorId ? values.sectorId : '',
        costCenter: values.costCenter ? values.costCenter : '',
        additionalNote: values.additionalNote ? values.additionalNote : '',
        candidate: {
          name: values.name ? values.name : '',
          cpf: values.cpf ? values.cpf : '',
          email: values.email ? values.email : '',
          whatsapp: values.whatsapp ? values.whatsapp : '',
        },
      };

      let result;

      if (itemToEditIndex !== null) {
        result = requests.filter((item, index) => index !== itemToEditIndex);
      }

      result && result.push(dataToSend);

      result && setRequests(result);

      snackBar('Solicitação editada com sucesso', 'success');
      setOpen(false);
      setIsEdit(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <h3>Editar solicitação</h3>

        <BlockTitle>Dados pessoais</BlockTitle>

        <FieldRow>
          <TextField
            fullWidth
            name="name"
            label="Nome completo"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            fullWidth
            name="cpf"
            label="CPF"
            type="text"
            value={cpfMask(formik.values.cpf)}
            onChange={formik.handleChange}
            error={formik.touched.cpf && Boolean(formik.errors.cpf)}
            helperText={formik.touched.cpf && formik.errors.cpf}
          />
        </FieldRow>

        <FieldRow>
          <TextField
            fullWidth
            name="email"
            label="E-mail"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            name="whatsapp"
            label="Whatsapp"
            type="text"
            value={
              formik.values.whatsapp
                ? phoneMask(formik.values.whatsapp, 'celular')
                : ''
            }
            onChange={formik.handleChange}
            error={formik.touched.whatsapp && Boolean(formik.errors.whatsapp)}
            helperText={formik.touched.whatsapp && formik.errors.whatsapp}
          />
        </FieldRow>

        <BlockTitle style={{ marginTop: '20px' }}>
          Dados da solicitação
        </BlockTitle>

        <FieldRow>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ width: '100%' }}>
              <DesktopDatePicker
                label="Data da solicitação *"
                format="DD/MM/YYYY"
                disablePast
                value={requestDate}
                onChange={handleChangeRequestDate}
                onError={(newError) => setInvalidDate(newError ? true : false)}
              />
              {invalidDate && (
                <ErrorMsg>Essa data não pode ser retroativa</ErrorMsg>
              )}
            </div>
          </LocalizationProvider>

          <FormControl style={{ width: '100%' }}>
            <BaseInputLabel id="demo-simple-select-label">Cargo</BaseInputLabel>
            <Select
              name="jobTitleId"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.jobTitleId}
              onChange={formik.handleChange}
              error={
                formik.touched.jobTitleId && Boolean(formik.errors.jobTitleId)
              }
            >
              {company?.jobTitles?.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <FormControl style={{ width: '100%' }}>
            <BaseInputLabel id="demo-simple-select-label">
              Região
            </BaseInputLabel>
            <Select
              fullWidth
              name="region"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.region}
              onChange={formik.handleChange}
              error={formik.touched.region && Boolean(formik.errors.region)}
            >
              {regions?.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: '100%' }}>
            <BaseInputLabel id="demo-simple-select-label">
              Instrumento a ser enviado
            </BaseInputLabel>
            <Select
              fullWidth
              name="instrumentId"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.instrumentId}
              onChange={formik.handleChange}
              error={
                formik.touched.instrumentId &&
                Boolean(formik.errors.instrumentId)
              }
            >
              {company?.instruments?.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FieldRow>

        <FieldRow>
          <FormControl style={{ width: '100%' }}>
            <BaseInputLabel id="demo-simple-select-label">Setor</BaseInputLabel>
            <Select
              fullWidth
              name="sectorId"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formik.values.sectorId}
              onChange={formik.handleChange}
              error={formik.touched.sectorId && Boolean(formik.errors.sectorId)}
            >
              {company?.sectors?.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            name="costCenter"
            label="Centro de custo"
            type="text"
            value={formik.values.costCenter}
            onChange={formik.handleChange}
          />
        </FieldRow>

        <FieldRow>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="additionalNote"
            label="Observação"
            type="textarea"
            value={formik.values.additionalNote}
            onChange={formik.handleChange}
            error={
              formik.touched.additionalNote &&
              Boolean(formik.errors.additionalNote)
            }
            helperText={
              formik.touched.additionalNote && formik.errors.additionalNote
            }
          />
        </FieldRow>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '15px',
            marginTop: '20px',
          }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setOpen(false);
              setIsEdit(false);
            }}
          >
            Cancelar
          </Button>
          <Button variant="contained" type="submit">
            Finalizar edição
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

export { RequestEditModal };
