export const validateCPF = (cpf: string) => {
  const treatedCpf = cpf.replace(/[^\d]+/g, '');

  const digitsOnly = /^\d{11}$/.test(treatedCpf);

  if (!digitsOnly) {
    return false;
  }

  const match = cpf.toString().match(/\d/g);

  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 11) return false;

  const items = [...new Set(numbers)];

  if (items.length === 1) return false;

  let sum = 0;
  let reminder;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(treatedCpf.substring(i - 1, i)) * (11 - i);
    reminder = (sum * 10) % 11;
  }

  if (reminder === 10 || reminder === 11) {
    reminder = 0;
  }

  if (reminder != parseInt(treatedCpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(treatedCpf.substring(i - 1, i)) * (12 - i);
    reminder = (sum * 10) % 11;
  }

  if (reminder === 10 || reminder === 11) {
    reminder = 0;
  }

  if (reminder != parseInt(treatedCpf.substring(10, 11))) {
    return false;
  }

  return true;
};
