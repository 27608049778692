import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAssessment } from 'modules/assessment-execution/context/assessment.consumer';
import { AssessmentApi } from 'lib/api/assessment';
import { useSnackbar } from 'notistack';
import { AssessmentProvider } from '../context/assessment.provider';

import {
  InitialPage,
  SelfiePage,
  IdentificationPage,
  PreamblePage,
  DetailedIdentificationPage,
  LGPDPage,
  TutorialPage,
  PreparePage,
  ExecutePage,
  FinishPage,
} from 'modules/assessment-execution';

const WrappedIndexPage = () => {
  const { pageIndex } = useAssessment();
  const { analystRequestId } = useParams();
  const { setAnalystRequest } = useAssessment();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (analystRequestId) {
      AssessmentApi.getAnalystRequest(analystRequestId)
        .then(setAnalystRequest)
        .catch((error) => {
          enqueueSnackbar('Erro ao carregar a solicitação.', {
            variant: 'error',
          });
          console.error(error);
        });
    }
  }, [analystRequestId, setAnalystRequest]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageIndex]);

  const pages = [
    InitialPage,
    SelfiePage,
    IdentificationPage,
    PreamblePage,
    DetailedIdentificationPage,
    LGPDPage,
    TutorialPage,
    PreparePage,
    ExecutePage,
    FinishPage,
  ];

  const CurrentPage = pages[pageIndex];

  return <CurrentPage />;
};

export const IndexPage = () => {
  return (
    <AssessmentProvider>
      <WrappedIndexPage />
    </AssessmentProvider>
  );
};

export default IndexPage;
